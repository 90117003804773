import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BonusBuck from "../../models/BonusBuck";
import BonusBuckModal from "./BonusBuckModal";
import { Box } from "@material-ui/core";
import sortBy from "lodash/sortBy";
import { store, getModels } from "../../constants/globals";
import {
  andJoin,
  isPresent,
  centsToDollarString,
  getDateStr,
  spliceFromArray,
} from "../../util/util";
import ChangeSeasonSelect from "../util/ChangeSeasonSelect";
import IconButton from "@material-ui/core/IconButton";
import PendingOutlinedIcon from "@material-ui/icons/PendingOutlined";
import PendingIcon from "@material-ui/icons/Pending";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import CameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import groupBy from "lodash/groupBy";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const { setState } = store.getScopedHelpers("bonus_buck_modal");

const clickAdd = (): void => {
  const state = BonusBuck.getModalState(null, store.getNonNullState("currentUser"));

  setState(state);
};

export const clickBBRow = (bb: BonusBuck): void => {
  const currentUser = store.getNonNullState("currentUser");

  if (currentUser.admin || currentUser.id === bb.creator_id) {
    const state = BonusBuck.getModalState(bb, null);

    setState(state);
  }
};

export const renderConfirmed = (confirmed: boolean): JSX.Element | null => {
  return confirmed ? null : <span className="bold italic mr-2">PENDING:</span>;
};

export const renderPhotoIcon = (image_file_name: string | null): JSX.Element | null => {
  return image_file_name == null ? null : <CameraIcon />;
};

const clickPending = (): void => {
  const next = store.state.showPending !== true;

  store.setState("showPending", next);
};

const clickPhotos = (): void => {
  const next = store.state.showPhotos !== true;

  store.setState("showPhotos", next);
};

const renderBB = (bb: BonusBuck): JSX.Element => {
  const users = andJoin(bb.getWorkers().map((u) => u.username));
  const reason = isPresent(bb.reason) ? bb.reason : "reasons";
  const each_cents = bb.individualDollars() * 100;

  return (
    <Box
      key={`bb_${bb.id}`}
      className="job-row pl-1"
      style={{ minHeight: 50 }}
      display="flex"
      alignItems="center"
      onClick={(): void => clickBBRow(bb)}
    >
      {renderPhotoIcon(bb.image_file_name)}
      {renderConfirmed(bb.confirmed)}
      {users} received ${bb.dollars} for {reason} ({centsToDollarString(each_cents)} each)
    </Box>
  );
};

const clickDate = (d: string, collapsed: boolean): void => {
  if (collapsed) {
    spliceFromArray(store.state.collapsed_dates, d);
  } else {
    store.state.collapsed_dates.push(d);
  }

  store.forceUpdate("collapsed_dates");
};

const renderBBsByDate = (d: string, loads: BonusBuck[]): JSX.Element => {
  const [year, month, day] = d.split("-");
  const str = `${month}/${day}/${year}`;
  const collapsed = store.state.collapsed_dates.includes(d);

  return (
    <div key={str}>
      <div
        className="job-row d-flex justify-content-between align-items-center"
        onClick={(): void => clickDate(d, collapsed)}
        style={{ minHeight: 50 }}
      >
        <div style={{ width: 24 }}></div>
        {str}

        {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>

      {collapsed ? null : loads.map(renderBB)}
    </div>
  );
};

const BonusBucks = (): JSX.Element => {
  const showPending = store.useState("showPending") === true;
  const showPhotos = store.useState("showPhotos") === true;
  store.useState("collapsed_dates");

  let bonus_bucks = sortBy(getModels("bonus_bucks"), (bb) => -bb.performed_at);

  const currentUser = store.getNonNullState("currentUser");

  if (!currentUser.admin) {
    bonus_bucks = bonus_bucks.filter(
      (bb) => bb.creator_id === currentUser.id || bb.worker_ids.includes(currentUser.id),
    );
  }

  if (showPending) {
    bonus_bucks = bonus_bucks.filter((bb) => !bb.confirmed);
  }

  if (showPhotos) {
    bonus_bucks = bonus_bucks.filter((bb) => bb.image_file_name != null);
  }

  const grouped = groupBy(bonus_bucks, (tl) => getDateStr(tl.performed_at));
  const dates = Object.keys(grouped).sort().reverse();

  return (
    <div>
      <ChangeSeasonSelect />
      <Typography
        variant="h6"
        gutterBottom
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex">
          <IconButton size="medium" onClick={clickPending}>
            {showPending ? <PendingIcon /> : <PendingOutlinedIcon />}
          </IconButton>

          <IconButton size="medium" onClick={clickPhotos}>
            {showPhotos ? <CameraIcon /> : <CameraOutlinedIcon />}
          </IconButton>
        </div>

        <div>BBs ({bonus_bucks.length})</div>

        <Button onClick={clickAdd} variant="outlined" className="my-2">
          Add BB
        </Button>
      </Typography>

      {dates.map((d) => renderBBsByDate(d, grouped[d]))}
      {/* {bonus_bucks.map(renderBB)} */}

      <BonusBuckModal />
    </div>
  );
};

export default BonusBucks;

import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import sortBy from "lodash/sortBy";
import User from "../../models/User";
import { getActiveUsers } from "../../util/app_util";

export const renderUser = (u: User, label: string): JSX.Element => {
  const key = `user-${u.id}-option-${label}`;

  return (
    <option key={key} value={u.id}>
      {u.selectName()}
    </option>
  );
};

type UserSelectProps = {
  user_id: number | null;
  onChange: (user_id: number | null) => void;
  nullLabel?: string;
  label: string;
};

export default function UserIdSelectNull(props: UserSelectProps): JSX.Element {
  const { user_id, onChange, nullLabel, label } = props;

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const val = e.target.value as string;
    const user_id = val === "null" ? null : parseInt(val);

    onChange(user_id);
  };

  const users = sortBy(getActiveUsers(), (u) => u.username);

  const val = user_id || null;

  if (!val && !nullLabel) {
    throw new Error("value is null, but not nullLabel was provided");
  }

  return (
    <div>
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel>{label}</InputLabel>
        <Select
          native
          value={user_id || "null"}
          onChange={handleChange}
          inputProps={{
            name: "user_id",
          }}
        >
          {!!nullLabel && <option>{nullLabel}</option>}

          {users.map((w) => renderUser(w, label))}
        </Select>
      </FormControl>
    </div>
  );
}

import React from "react";
import Room from "../models/Room";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import NoteIcon from "@material-ui/icons/Note";
import WarningIcon from "@material-ui/icons/Warning";
import MoneyIcon from "@material-ui/icons/Money";

import PersonIcon from "@material-ui/icons/Person";
import { lightGreen, amber, grey } from "@material-ui/core/colors";
import Reservation from "../models/Reservation";

export const getLockedIcon = (room: Room): JSX.Element | null => {
  switch (room.locked) {
    case true:
      return <LockIcon style={{ color: amber[500] }} />;
    case false:
      return <LockOpenIcon style={{ color: lightGreen[500] }} />;
    case null:
      return null;
  }
};

export const getOccupiedIcon = (room: Room): JSX.Element | null => {
  return room.occupied ? <PersonIcon style={{ color: grey[600] }} /> : null;
};

export const moneyColor = lightGreen[500];
export const getHasPaymentPendingIcon = (room: Room): JSX.Element | null => {
  return room.hasPaymentPending() ? <MoneyIcon style={{ color: moneyColor }} /> : null;
};

export const getReservationHasPaymentPendingIcon = (r: Reservation): JSX.Element | null => {
  return r.has_unpaid_invoice ? <MoneyIcon style={{ color: moneyColor }} /> : null;
};

export const getTicketIcon = (room: Room): JSX.Element | null => {
  return room.unresolved_tickets_count > 0 ? <WarningIcon style={{ color: "#ff5722" }} /> : null;
};

export const getArrivingIcon = (room: Room): JSX.Element | null => {
  return room.hasArrivalDate() ? <DateRangeIcon style={{ width: 15 }} /> : null;
};

export const getSpecialInstructionsIcon = (room: Room): JSX.Element | null => {
  return room.hasSpecialInstructions() ? (
    <AnnouncementIcon className="ml-1" style={{ width: 20 }} />
  ) : null;
};

export const getFieldNoteIcon = (room: Room): JSX.Element | null => {
  return room.field_notes_count > 0 ? <NoteIcon /> : null;
};

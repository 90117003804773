import React, { useState } from "react";
import { Formik } from "formik";
import { TextField, Button, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { router, store } from "../../constants/globals";
import { UserJSONResponse, isErrorResponse } from "../../types/json_types";
import User from "../../models/User";
import { ApiRequest } from "../../libs/ApiRequest";
import CloseableAlert from "../util/CloseableAlert";
import CenterAll from "../util/CenterAll";
import GoogleOauthButton from "./GoogleOauthButton";

const clickGoLogin = (): void => {
  router.go("/login");
};

const SignupForm = (): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);

  const closeAlert = (): void => {
    setErrors([]);
  };

  return (
    <Formik
      initialValues={{ email: "", password_confirmation: "", password: "" }}
      onSubmit={(values, { setSubmitting }): void => {
        ApiRequest.perform({
          url: "/users.json",
          method: "POST",
          data: { user: values },
          onSuccess: (json: unknown) => {
            const { currentUser } = json as UserJSONResponse;
            const user = new User(currentUser);
            store.setState("currentUser", user);
          },
          onError: (json: unknown) => {
            if (isErrorResponse(json)) {
              setErrors(json.errors);
            } else {
              window.alert("an error occurred");
            }

            setSubmitting(false);
          },
        });
      }}
    >
      {(props): JSX.Element => {
        const { values, isSubmitting, handleSubmit, handleChange } = props;

        return (
          <div className="p-2">
            {errors.length ? (
              <CloseableAlert
                open={!!errors.length}
                closeAlert={closeAlert}
                variant="outlined"
                severity="error"
              >
                <AlertTitle>The following errors occurred:</AlertTitle>
                <ul>
                  {errors.map((e, index) => (
                    <li key={`signup_err_idx_${index}`}>{e}</li>
                  ))}
                </ul>
              </CloseableAlert>
            ) : null}

            <Typography variant="h4">Sign Up</Typography>

            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className="mt-2"
                />

                <TextField
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  className="mt-2"
                />

                <TextField
                  value={values.password_confirmation}
                  onChange={handleChange}
                  name="password_confirmation"
                  label="Password Confirmation"
                  type="password"
                  variant="outlined"
                  className="mt-2"
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className="mt-2"
                  disabled={isSubmitting}
                  disableElevation
                >
                  sign up
                </Button>
              </Box>
            </form>

            <Box display="flex" flexDirection="column">
              <Box my="2">
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  OR
                </Typography>
              </Box>

              <CenterAll>
                <GoogleOauthButton />
              </CenterAll>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography className="mt-2" variant="h6" style={{ textAlign: "center" }}>
                OR
              </Typography>

              <Button
                onClick={clickGoLogin}
                variant="outlined"
                color="secondary"
                size="large"
                className="mt-2"
              >
                Go to login page
              </Button>
            </Box>
          </div>
        );
      }}
    </Formik>
  );
};

export default SignupForm;

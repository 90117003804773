import { StripeChargeJSON } from "../types/json_types";

class StripeCharge {
  reservation_id: number;
  created_at: Date;

  constructor(json: StripeChargeJSON) {
    this.reservation_id = json.reservation_id;
    this.created_at = new Date(json.created_at + "Z");
  }
}

export default StripeCharge;

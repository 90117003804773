import React from "react";
import { install_status_colors } from "../constants/colors";
import Install, { InstallStatus } from "../models/Install";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { store } from "../constants/globals";

export const getColor = (status: InstallStatus): string => {
  const color = install_status_colors[status];

  if (color) {
    return color;
  } else {
    throw new Error(`couldnt determine short color for status ${status}`);
  }
};

export const renderShort = (install: Install): JSX.Element => {
  const color = getColor(install.status);
  const style: CSSProperties = {
    color: store.getState("colormode") === "dark" ? "black" : "white",
    backgroundColor: color,
    border: "1px solid color",
    borderRadius: 5,
  };

  const short = install.product.id === 21 ? "LBX_purchase" : install.getShort();

  return (
    <span style={style} key={`short_${install.id}`} className="px-1 short mx-1">
      {short}
    </span>
  );
};

import React from "react";
import { Typography } from "@material-ui/core";
import { install_status_colors } from "../constants/colors";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import MoneyIcon from "@material-ui/icons/Money";
import WarningIcon from "@material-ui/icons/Warning";
import { moneyColor } from "../helpers/icon_helpers";

export const CheatSheet = (): JSX.Element => {
  return (
    <div className="p-1" style={{ fontSize: 16 }}>
      <Typography variant="h6">Product status colors:</Typography>

      <div style={{ color: install_status_colors.pending_delivery }}>
        no action has been taken on the product. it needs to be delivered to the
        room &amp; installed.
      </div>
      <div style={{ color: install_status_colors.delivered }}>
        a product that has been delivered to a room and is pending installation
      </div>
      <div style={{ color: install_status_colors.installed }}>
        an installed product
      </div>
      <div style={{ color: install_status_colors.pending_removal }}>
        the item needs to be removed ... uninstall &amp; get it out of the room!
      </div>
      <div style={{ color: install_status_colors.uninstalled }}>
        product has been uninstalled and needs to be carried to pickup zone
      </div>
      <div style={{ color: install_status_colors.removed }}>
        product has been removed and carried to pickup zone
      </div>

      <div style={{ color: install_status_colors.missing }}>
        product should be here but is missing{" "}
        <span role="img" aria-label="shrug">
          🤷
        </span>
      </div>

      <Typography variant="h6" className="mt-2">
        Room Icons:
      </Typography>

      <div className="d-flex align-items-center">
        <AnnouncementIcon className="mr-2" /> has special instructions
      </div>

      <div>
        <MoneyIcon className="mr-2" style={{ color: moneyColor }} /> unpaid
        invoice. customer needs to pony up{" "}
        <span role="img" aria-label="monay">
          💰💰
        </span>
      </div>

      <div className="d-flex align-items-center">
        <WarningIcon className="mr-2" style={{ color: "#ff5722" }} /> has needs
        attention ticket
      </div>

      <Typography variant="h6" className="mt-2">
        Product abbrevs:
      </Typography>
      <div>
        <b>BL:</b> Bedloft
      </div>

      <div className="mt-2">
        <b>SR:</b> Safety Rail
      </div>
      <div className="ml-2">
        <b>SR_mesh:</b> Safety Rail w/ mesh cover
      </div>

      <div className="mt-2">
        <b>PM:</b> Premium Mattress
      </div>
      <div className="ml-2">
        <b>PM_new:</b> New Mattress
      </div>
      <div className="ml-2">
        <b>PM_wide:</b> Wide Twin XL Mattress
      </div>
      <div className="ml-2">
        <b>PM_full:</b> Full XL Mattress
      </div>

      <div className="mt-2">
        <b>SR:</b> Safety Rail
      </div>
      <div className="ml-2">
        <b>BS:</b> bedshelf
      </div>

      <div className="mt-2">
        <b>MF:</b> Microfridge
      </div>
      <div className="ml-2">
        <b>MF_new:</b> NEW Microfridge
      </div>

      <div className="mt-2">
        <b>FFC:</b> Fridge/freezer combo
      </div>
      <div className="ml-2">
        <b>FFC_new:</b> NEW fridge/freezer
      </div>

      <div className="mt-2">
        <b>FT:</b> Standard Futon
      </div>

      <div>
        <b>DFT:</b> Deluxe Futon
      </div>

      <div className="mt-2">
        <b>TV32:</b> 32&quot; Flatscreen TV
      </div>

      <div>
        <b>TV40:</b> 40&quot; Flatscreen TV
      </div>

      <div className="mt-2">
        <b>LBX:</b> Lock box / Electronic Safe
      </div>

      <div className="mt-2">
        <b>HB:</b> Headboard. Handled by Andre. Don&apos;t worry about these
      </div>
    </div>
  );
};

import React from "react";
import Link from "@material-ui/core/Link";
import { store, getModels } from "../constants/globals";
import { filterModelsByAttr } from "../util/app_util";
import { getStatsForBuilding } from "../helpers/stats_helpers";
import StatsTable from "./stats/StatsTable";
import { Tabs, Tab, Typography } from "@material-ui/core";
import Building from "../models/Building";
import { getFilteredRooms } from "../helpers/filter_helpers";
import { install_status_colors } from "../constants/colors";

const { useNonNullState, setPartialState } = store.getScopedHelpers("building_detail");

const renderStats = (building: Building, tab: number): JSX.Element => {
  if (tab === 0) {
    const filter = window.App.filter;

    if (filter) {
      const rooms = getFilteredRooms(filter);

      const stats = getStatsForBuilding(building, rooms);

      return StatsTable(stats, building.shown_name);
    } else {
      return <Typography>No filter set</Typography>;
    }
  } else {
    const rooms = filterModelsByAttr(getModels("rooms"), "building_id", building.id);

    const stats = getStatsForBuilding(building, rooms);

    return StatsTable(stats, building.shown_name);
  }
};

const BuildingDetail = (): JSX.Element => {
  const { building, tab } = useNonNullState();

  return (
    <div className="p-1">
      <p>{building.notes}</p>
      {building.gmaps_link?.trim() && (
        <Link target="_blank" rel="noopener noreferrer" href={building.gmaps_link}>
          {building.gmaps_link}
        </Link>
      )}

      <Tabs
        className="mt-2"
        value={tab}
        onChange={(_, newTab): void => {
          setPartialState({ tab: newTab });
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Filtered stats" />
        <Tab label="All stats" />
      </Tabs>
      {renderStats(building, tab)}

      <div className="d-flex flex-wrap">
        <span style={{ color: install_status_colors.pending_delivery }} className="ml-1">
          pending delivery
        </span>
        <span style={{ color: install_status_colors.delivered }} className="ml-1">
          delivered
        </span>
        <span style={{ color: install_status_colors.installed }} className="ml-1">
          installed
        </span>
        <span style={{ color: install_status_colors.pending_removal }} className="ml-1">
          pending removal
        </span>
        <span style={{ color: install_status_colors.uninstalled }} className="ml-1">
          uninstalled
        </span>
        <span style={{ color: install_status_colors.removed }} className="ml-1">
          removed
        </span>
        <span style={{ color: install_status_colors.missing }} className="ml-1">
          missing
        </span>
      </div>
    </div>
  );
};

export default BuildingDetail;

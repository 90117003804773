import Product from "../models/Product";
import sortBy from "lodash/sortBy";
import isUndefined from "lodash/isUndefined";
import intersection from "lodash/intersection";
import { PlainObject } from "set-state-is-great/src/store";

export const doesIntersect = function (arr1: Array<unknown>, arr2: Array<unknown>): boolean {
  return !!intersection(arr1, arr2).length;
};

export const nullableCentsToDollarString = function (
  cents: number | null,
  nullTxt?: string,
): string {
  if (cents === null) {
    return nullTxt ? nullTxt : "$?.??";
  } else {
    const dollars = cents / 100;
    return dollars.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
};

export const then = <A, B>(a: A, cb: (a: A) => B): B => {
  return cb(a);
};

export const centsToDollarString = function (cents: number): string {
  const dollars = cents / 100;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const sortProductsByShorts = (products: Product[], shorts: string[]): Product[] => {
  return sortBy(products, (p) => shorts.indexOf(p.short));
};

export const spliceFromArray = <T>(arr: Array<T>, item: T): void => {
  const index = arr.indexOf(item);

  if (index > -1) {
    arr.splice(index, 1);
  } else {
    throw new Error(`item wasnt in array`);
  }
};

export const spliceFromArrayIfPresent = <T>(arr: Array<T>, item: T): void => {
  const index = arr.indexOf(item);

  if (index > -1) {
    arr.splice(index, 1);
  }
};

export const isDefined = function (val: unknown): boolean {
  return !isUndefined(val);
};

export const isBlank = function (string?: string): boolean {
  return !isPresent(string);
};

export function isString(x: unknown): x is string {
  return typeof x === "string";
}

export const isPresent = function (str?: string | null): boolean {
  if (!str) {
    return false;
  }

  return typeof str === "string" && str.trim() !== "";
};

export const arraysMatch = <T>(arr1: T[], arr2: T[]): boolean => {
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false;

  // Check if all items exist and are in the same order
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  // Otherwise, return true
  return true;
};

export const zeroPad = (n: number): string => {
  const zero = n < 10 ? "0" : "";
  return `${zero}${n}`;
};

export const getDayMonth = (d: Date): string => {
  const month = d.getMonth() + 1;
  const day = d.getDate();

  return `${month}/${day}`;
};

export const attemptParseInt = function (num: string): number | null {
  const blah = parseInt(num);

  return blah ? blah : null;
};

export const mutatingConcat = <T>(arr1: T[], arr2: T[]): void => {
  arr1.push(...arr2);
};

export const handleUTCDate = (str: string): Date => {
  if (str.endsWith("Z")) {
    return new Date(str);
  } else {
    return new Date(str + "Z");
  }
};

export const handleUTCDateNull = (str: string | null): Date | null => {
  if (str) {
    return handleUTCDate(str);
  } else {
    return null;
  }
};

// andJoin(['a', 'b', 'c'])  => 'a, b & c'
export const andJoin = function (arr: string[], delimiter = "&", w_html = false): string {
  const len = arr.length;

  if (len === 0) return "";
  if (len === 1) return arr[0];
  if (len === 2) return `${arr[0]} ${delimiter} ${arr[1]}`;

  delimiter = w_html ? `<span class='and-join-delim'>${delimiter}</span>` : delimiter;

  return arr
    .map((a, i) => {
      return i === len - 2 ? `${a} ${delimiter} ` : i < len - 1 ? `${a}, ` : a;
    })
    .join("");
};

export const myRound = (num: number): number => {
  return Math.round(num * 100) / 100;
};

export const parseBoolStr = (str: string): boolean | null => {
  return str === "true" ? true : str === "false" ? false : null;
};

export const parse4StateBoolStr = (str: string): boolean | null | undefined => {
  switch (str) {
    case "true":
      return true;
    case "false":
      return false;
    case "null":
      return null;
    case "undefined":
      return undefined;
    default:
      return undefined;
  }
};

export const areSameDay = function (date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth()
  );
};

/**
 * shallow equal comparison
 *
 * @doctest
 * ```js
 * t.true(shallowEqual({a: 1}, {a: 1}))
 * t.false(shallowEqual({a: 2}, {a: 1}))
 * t.false(shallowEqual({a: 1, b: 2}, {a: 1}))
 * ```
 */
export function shallowEqual(a: PlainObject, b: PlainObject): boolean {
  const keys1 = Object.keys(a);
  const keys2 = Object.keys(b);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

/**
 * get YYYY-MM-DD formatted date string
 *
 * @doctest
 * ```js
 * const d = new Date();
 * d.setFullYear(2018);
 * // 1 === feb in JS world
 * d.setMonth(1);
 * d.setDate(21);
 * t.is(getDateStr(d), '2018-02-21');
 * ```
 */
export const getDateStr = (d: Date): string => {
  const year = d.getFullYear();
  const month = zeroPad(d.getMonth() + 1);
  const day = zeroPad(d.getDate());

  return `${year}-${month}-${day}`;
};

/**
 * get date from YYYY-MM-DD formatted date string
 *
 * @doctest
 * ```js
 * const d = getDateFromDateStr("2020-02-19");
 * t.is(d.getFullYear(), 2020);
 * t.is(d.getMonth(), 1);
 * t.is(d.getDate(), 19);
 * ```
 */
export const getDateFromDateStr = (str: string): Date => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_year, month, day] = str.split("-").map((n) => parseInt(n));
  const date = new Date();
  date.setFullYear(2021);
  date.setMonth(month! - 1);
  date.setDate(day!);
  date.setHours(12);

  return date;
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography, Container, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { store, router } from "../../constants/globals";
import AppBarRight from "./AppBarRight";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    textAlign: "center",
    flexGrow: 1,
  },
}));

const openDrawer = (): void => {
  store.setState("drawerOpen", true);
};

const clickBack = (href: string) => (): void => {
  const search = store.getState("search");
  if (href === "/jobs" && search && search.trim()) {
    router.go(`/jobs?search=${encodeURIComponent(search)}`);
  } else {
    router.go(href);
  }
};

export default function AppBar(): JSX.Element {
  const classes = useStyles();
  const title = store.useState("title") || "DD4";
  const subtitle = store.useState("subtitle");
  const subsubtitle = store.useState("subsubtitle");
  const drill_href = store.useState("drill_href");

  return (
    <MuiAppBar position="static" elevation={0}>
      <Container style={{ paddingRight: 0 }}>
        <Toolbar>
          <React.Fragment>
            {drill_href ? (
              <IconButton
                onClick={clickBack(drill_href)}
                edge="start"
                color="inherit"
                disableRipple
              >
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={openDrawer}
                edge="start"
                color="inherit"
                disableRipple
              >
                <MenuIcon />
              </IconButton>
            )}

            <div className={classes.title}>
              <Typography>{title}</Typography>
              {subtitle ? (
                <div style={{ textAlign: "center" }}>{subtitle}</div>
              ) : null}

              {subsubtitle ? (
                <div style={{ fontSize: 10, textAlign: "center" }}>
                  {subsubtitle}
                </div>
              ) : null}
            </div>
          </React.Fragment>

          <AppBarRight />
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}

import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import sum from "lodash/sum";
import compact from "lodash/compact";
import concat from "lodash/concat";
import School from "../models/School";
import User from "../models/User";
import Payscale, { midYearCutoffDateStr } from "../models/Payscale";
import { getModels, store } from "../constants/globals";
import Building from "../models/Building";
import Room from "../models/Room";
import Install from "../models/Install";
import { shorts, short_shorts } from "../constants/shorts";
import InstallAction from "../models/InstallAction";
import { filterModelsByAttrInclusion } from "../util/app_util";
import { areSameDay } from "../util/util";

export interface RoomTotals {
  total: number;
  pending_delivery: number;
  delivered: number;
  installed: number;
  pending_removal: number;
  uninstalled: number;
  removed: number;
  missing: number;
}

export interface InstallStats {
  [short: string]: RoomTotals;
}

export type ShortShortInstallStats = {
  [short_short: string]: RoomTotals;
};

export interface MoneyNumbers {
  total: number;
  delivered: number;
  installed: number;
  uninstalled: number;
  removed: number;
}

export interface ShortShortMoneyNumbers {
  [short_short: string]: MoneyNumbers;
}

export interface MoneyAmounts {
  delivered_amount: number;
  installed_amount: number;
  uninstalled_amount: number;
  removed_amount: number;
  total_amount: number;
}

export type ShortShortMoneyAmounts = {
  [short_short: string]: RoomTotals;
};

export type ShortShortMoneyStats = {
  [short_short: string]: MoneyAmounts;
};

export const getTruckLoadTotalForUser = (user: User): number => {
  const unloadPayscale = Payscale.find("dropoff", user, false);
  const loadPayscale = Payscale.find("pickup", user, false);

  const amts = getModels("truck_loads").map((tl) => {
    const payscale = tl.type === "load" || tl.type === "pickup" ? loadPayscale : unloadPayscale;
    return tl.loadedTruck(user) ? tl.individualCents(payscale) : 0;
  });

  return sum(amts);
};

export const getTruckLoadTotalForUserAndDate = (user: User, date: Date): number => {
  const unloadPayscale = Payscale.find("dropoff", user, false);
  const loadPayscale = Payscale.find("pickup", user, false);

  const amts = getModels("truck_loads").map((tl) => {
    const payscale = tl.type === "load" || tl.type === "pickup" ? loadPayscale : unloadPayscale;
    return tl.loadedTruck(user) && areSameDay(tl.performed_at, date)
      ? tl.individualCents(payscale)
      : 0;
  });

  return sum(amts);
};

export const getStatsForBuilding = (building: Building, rooms: Room[]): InstallStats => {
  rooms = rooms.filter((r) => r.building.id === building.id);
  const installs = flatten(rooms.map((r) => r.installs));

  const stats: InstallStats = {};
  let short, install, room_stats: RoomTotals;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];
    if (!install) {
      continue;
    }

    short = install.product.short;

    if (stats[short]) {
      room_stats = stats[short];
    } else {
      room_stats = {
        total: 0,
        pending_delivery: 0,
        delivered: 0,
        installed: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        missing: 0,
      };
    }
    stats[short] = room_stats;
    stats[short].total += 1;
    stats[short][install.status] += 1;
  }

  return stats;
};

const calc_statuses = [
  "pending_delivery",
  "delivered",
  "installed",
  "pending_removal",
  "uninstalled",
  "removed",
];

export type SchoolStats = {
  school: School;
  prods: InstallStats;
};
export const getStatsForSchool = function (school: School, installs: Install[]): SchoolStats {
  const prods: InstallStats = {};
  const stats = {
    school: school,
    prods: prods,
  };

  shorts.forEach(
    (s) =>
      (prods[s] = {
        total: 0,
        pending_delivery: 0,
        delivered: 0,
        installed: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        missing: 0,
      }),
  );

  installs = installs.filter((i) => i.school.id === school.id);

  let install: Install, short: string;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];

    short = install.product.short;

    if (calc_statuses.includes(install.status)) {
      prods[short].total += 1;
    }

    prods[short][install.status] += 1;
  }

  return stats;
};

export const getStatsForFilter = function (
  school: School,
  building_ids: number[],
  installs: Install[],
): SchoolStats {
  const prods: InstallStats = {};
  const stats = {
    school: school,
    prods: prods,
  };

  shorts.forEach(
    (s) =>
      (prods[s] = {
        total: 0,
        pending_delivery: 0,
        delivered: 0,
        installed: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        missing: 0,
      }),
  );

  installs = installs.filter(
    (i) => i.school.id === school.id && building_ids.includes(i.building_id),
  );

  let install: Install, short: string;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];

    short = install.product.short;

    if (calc_statuses.includes(install.status)) {
      prods[short].total += 1;
    }

    prods[short][install.status] += 1;
  }

  return stats;
};

type AllStats = {
  all_stats: InstallStats;
  stats: SchoolStats[];
};

export const getAllStats = function (): AllStats {
  const schools = getModels("schools");
  const installs = getModels("installs");

  const stats = schools.map((s) => getStatsForSchool(s, installs));
  const all_stats: InstallStats = {};

  shorts.forEach(
    (s) =>
      (all_stats[s] = {
        total: 0,
        pending_delivery: 0,
        delivered: 0,
        installed: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        missing: 0,
      }),
  );

  stats.forEach((stat) => {
    shorts.forEach((short) => {
      const counts = stat.prods[short];

      all_stats[short].total += counts.total;
      all_stats[short].pending_removal += counts.pending_removal;
      all_stats[short].uninstalled += counts.uninstalled;
      all_stats[short].removed += counts.removed;
    });
  });

  return {
    all_stats,
    stats,
  };
};

export const midYearActionsForUser = (user: User): InstallAction[] => {
  if (store.state.season !== "2021_installs" || !store.state.midyear_user_ids!.includes(user.id)) {
    return [];
  }

  const actions: InstallAction[] = [];

  const install_actions = getModels("install_actions");
  const len = install_actions.length;

  let ia: InstallAction;

  for (let i = 0; i < len; i++) {
    ia = install_actions[i];
    if (ia.getCreatedAtStr() >= midYearCutoffDateStr && ia.touchedByUser(user)) {
      actions.push(ia);
    }
  }

  return actions;
};

export const actionsForUser = (user: User): InstallAction[] => {
  const actions: InstallAction[] = [];

  const install_actions = getModels("install_actions");
  const len = install_actions.length;

  let ia: InstallAction;

  const isMidyear = store.state.midyear_user_ids.includes(user.id);

  if (isMidyear) {
    for (let i = 0; i < len; i++) {
      ia = install_actions[i];
      if (ia.touchedByUser(user) && ia.getCreatedAtStr() <= midYearCutoffDateStr) {
        actions.push(ia);
      }
    }
  } else {
    for (let i = 0; i < len; i++) {
      ia = install_actions[i];
      if (ia.touchedByUser(user)) {
        actions.push(ia);
      }
    }
  }

  return actions;
};

export const actionsForUserAndDate = (user: User, date: Date): InstallAction[] => {
  const actions: InstallAction[] = [];

  const install_actions = getModels("install_actions");
  const len = install_actions.length;

  let ia: InstallAction;

  for (let i = 0; i < len; i++) {
    ia = install_actions[i];

    if (ia.touchedByUser(user) && areSameDay(ia.created_at, date)) {
      actions.push(ia);
    }
  }

  return actions;
};

export const getStatsForUser = function (user: User, midyear: boolean): ShortShortMoneyNumbers {
  const stats: ShortShortMoneyNumbers = {};

  short_shorts.forEach(
    (s) =>
      (stats[s] = {
        total: 0,
        delivered: 0,
        installed: 0,
        uninstalled: 0,
        removed: 0,
      }),
  );

  let action: InstallAction, short_short: string, cnt: number;

  const actions = midyear ? midYearActionsForUser(user) : actionsForUser(user);

  for (let i = 0; i < actions.length; i++) {
    action = actions[i];

    if (!action.set) continue;
    if (!action.money_type) continue;

    if (!action.install) {
      continue;
    }

    short_short = action.install.product.short_short;

    cnt = action.individualCount();

    stats[short_short].total += cnt;

    stats[short_short][action.money_type] += cnt;
  }

  return stats;
};

const dayMonth = (date: Date): string => {
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

type DateAction = {
  str: string;
  date: Date;
};

export const getDaysWorked = (user: User): DateAction[] => {
  const actionDays: DateAction[] = actionsForUser(user).map((a) => ({
    str: dayMonth(a.created_at),
    date: a.created_at,
  }));

  const truckLoadDays: DateAction[] = compact(
    getModels("truck_loads").map((d) =>
      d.workedTruckLoad(user) ? { str: dayMonth(d.performed_at), date: d.performed_at } : null,
    ),
  );

  const bbDays: DateAction[] = compact(
    getModels("bonus_bucks").map((bb) =>
      bb.confirmed && bb.worker_ids.includes(user.id)
        ? { str: dayMonth(bb.performed_at), date: bb.performed_at }
        : null,
    ),
  );

  let days = concat(actionDays, truckLoadDays, bbDays);

  days = sortBy(
    uniqBy(days, (d) => d.str),
    (d) => d.date,
  );

  return days;
};

export const getStatsForUserOnDay = function (user: User, date: Date): ShortShortMoneyNumbers {
  const stats: ShortShortMoneyNumbers = {};

  short_shorts.forEach(
    (s) =>
      (stats[s] = {
        total: 0,
        delivered: 0,
        installed: 0,
        uninstalled: 0,
        removed: 0,
      }),
  );

  let action: InstallAction, short_short: string, cnt: number;

  const actions = actionsForUserAndDate(user, date);

  for (let i = 0; i < actions.length; i++) {
    action = actions[i];

    if (!action.set) continue;
    if (!action.money_type) continue;
    if (!action.install) continue;

    // if (!areSameDay(action.created_at, date)) continue;

    short_short = action.install.product.short_short;

    cnt = action.individualCount();

    stats[short_short].total += cnt;

    stats[short_short][action.money_type] += cnt;
  }

  return stats;
};

export const getMoneyForStats = function (
  stats: ShortShortMoneyNumbers,
  user: User,
  midyear: boolean,
): ShortShortMoneyStats {
  const delivered_payscale = Payscale.find("delivered", user, midyear);
  const installed_payscale = Payscale.find("installed", user, midyear);
  const uninstall_payscale = Payscale.find("uninstalled", user, midyear);
  const removal_payscale = Payscale.find("removed", user, midyear);

  const money: Partial<ShortShortMoneyStats> = {};

  let s: MoneyAmounts, room_stats: MoneyNumbers;

  short_shorts.forEach((short) => {
    room_stats = stats[short];

    const delivered_cents = delivered_payscale ? delivered_payscale.short_cents[short] : 0;
    const installed_cents = installed_payscale ? installed_payscale.short_cents[short] : 0;
    const uninstalled_cents = uninstall_payscale ? uninstall_payscale.short_cents[short] : 0;
    const removed_cents = removal_payscale ? removal_payscale.short_cents[short] : 0;

    const delivered_amount = delivered_cents * room_stats.delivered;
    const installed_amount = installed_cents * room_stats.installed;
    const uninstalled_amount = uninstalled_cents * room_stats.uninstalled;
    const removed_amount = removed_cents * room_stats.removed;

    s = {
      delivered_amount: delivered_amount,
      installed_amount: installed_amount,
      uninstalled_amount: uninstalled_amount,
      removed_amount: removed_amount,
      total_amount: delivered_amount + installed_amount + uninstalled_amount + removed_amount,
    };

    money[short] = s;
  });

  return money as ShortShortMoneyStats;
};

export const computeBonusBuckTotal = (user: User): number => {
  let bonus_bucks = filterModelsByAttrInclusion(getModels("bonus_bucks"), "worker_ids", user.id);
  bonus_bucks = bonus_bucks.filter((bb) => bb.confirmed);
  return sumBy(bonus_bucks, (bb) => bb.individualDollars());
};

export const computeBonusBuckTotalOnDay = (user: User, date: Date): number => {
  const bonus_bucks = getModels("bonus_bucks").filter(
    (bb) => bb.confirmed && bb.worker_ids.includes(user.id) && areSameDay(bb.performed_at, date),
  );

  return sumBy(bonus_bucks, (bb) => bb.individualDollars());
};

export const computeRoomTotal = (money: ShortShortMoneyStats): number => {
  return sum(map(money, (nums) => nums.total_amount));
};

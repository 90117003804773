import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { store } from "../../constants/globals";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const changeTab = (event: React.ChangeEvent<any>, newValue: number): void => {
  store.setState("workers_page", { worker_tab: newValue });
};

type WorkerTabsProps = {
  worker_tab: number;
  active_workers_count: number;
  inactive_workers_count: number;
};

function WorkerTabs(props: WorkerTabsProps): JSX.Element {
  const { worker_tab, active_workers_count, inactive_workers_count } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={worker_tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
          <Tab label={`Active workers (${active_workers_count})`} />
          <Tab label={`Inactive workers (${inactive_workers_count})`} />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default React.memo(WorkerTabs);

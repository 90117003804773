import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Paper, Tabs, Tab, Typography, Divider, Button } from "@material-ui/core";
import { store, getAllData, getModels } from "../../constants/globals";
import InstallActionCheckbox from "./InstallActionCheckbox";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { renderShort } from "../../helpers/install_view_helpers";
import { ApiRequest } from "../../libs/ApiRequest";
import { isErrorResponse, AllJSONResponse } from "../../types/json_types";
import { handleData } from "../../util/handle_data";
import { findModelById } from "../../util/app_util";

const style: CSSProperties = {
  top: `50%`,
  left: "0",
  right: "0",
  transform: `translateY(-50%)`,
  outline: 0,
  width: "100%",
  border: "none",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
    },
  }),
);

const onSubmit = (): void => {
  const state = getNonNullState();

  setPartialState({ working: true });
  const data = { ...state, install: null };
  ApiRequest.perform({
    url: "/worker_api/install_actions.json",
    method: "POST",
    data: { install_id: state.install.id, install_actions: data },
    onSuccess: (json: AllJSONResponse): void => {
      if (!json.data) {
        alert("an error occurred: data missing");
        return;
      }
      handleData(json.data);
      window.D = getAllData();
      setState(undefined);
      const room = store.getState("room");
      if (room) {
        store.state.room = findModelById(getModels("rooms"), room.id);
      }

      let collapsed_buildings = store.state.collapsed_buildings;
      const buildings = getModels("buildings");
      collapsed_buildings = collapsed_buildings.map((b) => findModelById(buildings, b.id));
      store.state.collapsed_buildings = collapsed_buildings;

      store.forceUpdate("viewShown");
    },
    onError: (resp: unknown): void => {
      if (isErrorResponse(resp)) {
        alert(resp.errors.join(" & "));
      } else {
        alert("an error occurred");
      }

      setPartialState({ working: false });
    },
  });
};

const clickNeedsRemoval = (): void => {
  const state = getNonNullState();

  setPartialState({ working: true });

  ApiRequest.perform({
    url: `/worker_api/install_actions/${state.install.id}/toggle_needs_removal.json`,
    method: "POST",
    onSuccess: (json: AllJSONResponse): void => {
      if (json.data) {
        handleData(json.data);
      }
      window.D = getAllData();
      setState(undefined);
      const room = store.getState("room");

      if (room) {
        store.state.room = findModelById(getModels("rooms"), room.id);
      }
      store.forceUpdate("viewShown");
    },
    onError: (resp: unknown): void => {
      if (isErrorResponse(resp)) {
        alert(resp.errors.join(" & "));
      } else {
        alert("an error occurred");
      }

      setPartialState({ working: false });
    },
  });
};

const clickMissing = (): void => {
  const state = getNonNullState();

  setPartialState({ working: true });

  ApiRequest.perform({
    url: `/worker_api/install_actions/${state.install.id}/toggle_missing.json`,
    method: "POST",
    onSuccess: (resp: unknown): void => {
      const json = resp as AllJSONResponse;
      if (json.data) {
        handleData(json.data);
        window.D = getAllData();
        setState(undefined);
        const room = store.getState("room");

        if (room) {
          store.state.room = findModelById(getModels("rooms"), room.id);
        }
        store.forceUpdate("viewShown");
      } else {
        window.location.reload();
      }
    },
    onError: (resp: unknown): void => {
      if (isErrorResponse(resp)) {
        alert(resp.errors.join(" & "));
      } else {
        alert("an error occurred");
      }

      setPartialState({ working: false });
    },
  });
};

const {
  useStoreState: useModal,
  setPartialState,
  setState,
  getNonNullState,
} = store.getScopedHelpers("install_action_modal");

export default function InstallActionModal(): JSX.Element | null {
  const classes = useStyles();

  const currentUser = store.getNonNullState("currentUser");

  const state = useModal();

  if (!state) {
    return null;
  }

  return (
    <Modal
      open={true}
      onClose={(): void => {
        setState(undefined);
      }}
    >
      <div style={style} className={classes.paper}>
        <Paper square>
          <Tabs
            value={state.tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, tab): void => {
              setPartialState({ tab });
            }}
          >
            <Tab label="Install" />
            <Tab label="Removal" />
          </Tabs>
        </Paper>

        <div className="my-2 px-1">
          <Typography>
            Please select the actions you did for {renderShort(state.install)}
          </Typography>

          {state.tab === 0 ? (
            <>
              <InstallActionCheckbox
                type="delivered"
                currentUser={currentUser}
                splitter_ids={state.delivered_by_splitter_ids}
                actor_id={state.delivered_by_id}
                action={state.install.delivered_action}
                onChangeActor={(actor_id: number | null): void =>
                  setPartialState({ delivered_by_id: actor_id })
                }
                onChangeSplitters={(splitter_ids): void => {
                  setPartialState({ delivered_by_splitter_ids: splitter_ids });
                }}
              />

              <div>
                <InstallActionCheckbox
                  type="installed"
                  currentUser={currentUser}
                  action={state.install.installed_action}
                  splitter_ids={state.installed_by_splitter_ids}
                  actor_id={state.installed_by_id}
                  onChangeSplitters={(splitter_ids): void => {
                    setPartialState({
                      installed_by_splitter_ids: splitter_ids,
                    });
                  }}
                  onChangeActor={(actor_id): void => setPartialState({ installed_by_id: actor_id })}
                />
              </div>
            </>
          ) : (
            <>
              <InstallActionCheckbox
                type="uninstalled"
                currentUser={currentUser}
                splitter_ids={state.uninstalled_by_splitter_ids}
                actor_id={state.uninstalled_by_id}
                action={state.install.uninstalled_action}
                onChangeActor={(actor_id: number | null): void =>
                  setPartialState({ uninstalled_by_id: actor_id })
                }
                onChangeSplitters={(splitter_ids): void => {
                  setPartialState({
                    uninstalled_by_splitter_ids: splitter_ids,
                  });
                }}
              />

              <div>
                <InstallActionCheckbox
                  type="removed"
                  currentUser={currentUser}
                  action={state.install.removed_action}
                  splitter_ids={state.removed_by_splitter_ids}
                  actor_id={state.removed_by_id}
                  onChangeSplitters={(splitter_ids): void => {
                    setPartialState({
                      removed_by_splitter_ids: splitter_ids,
                    });
                  }}
                  onChangeActor={(actor_id): void => setPartialState({ removed_by_id: actor_id })}
                />
              </div>
            </>
          )}
        </div>

        <Divider />

        <div className="d-flex justify-content-between py-3 px-1">
          <Button onClick={clickMissing} variant="outlined" disabled={state.working}>
            {state.install.status === "missing" ? "Not missing" : "Missing"}
          </Button>

          <Button onClick={clickNeedsRemoval} variant="outlined" disabled={state.working}>
            {state.install.needs_removal ? "Doesnt need removal" : "Needs Removal"}
          </Button>
        </div>

        <div className="d-flex justify-content-between py-3 px-1">
          <Button
            variant="outlined"
            disabled={state.working}
            onClick={(): void => setState(undefined)}
          >
            Cancel
          </Button>

          <Button variant="outlined" onClick={onSubmit} disabled={state.working}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
}

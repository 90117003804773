import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Activity } from "../../models/InstallAction";
import { store } from "../../constants/globals";
import InstallActionModal from "./InstallActionModal";
import sortBy from "lodash/sortBy";
import FieldNote from "../../models/FieldNote";
import { Button } from "@material-ui/core";
import { getImageUrl } from "../../helpers/paperclip_helpers";
import {
  getLockedIcon,
  getFieldNoteIcon,
  getTicketIcon,
  getOccupiedIcon,
} from "../../helpers/icon_helpers";
import FieldNoteModal from "./FieldNoteModal";
import LockedSection from "./LockedSection";
import { setTicketModalState } from "../../helpers/ticket_helpers";
import TicketModal from "./TicketModal";
import TicketResolutionModal from "./TicketResolutionModal";
import TicketsSection from "./TicketsSection";
import OccupiedButton from "./OccupiedButton";
import { ReservationRow } from "./ReservationRow";
import InstallButton from "./InstallButton";

const renderActions = (activities: Array<Activity>): JSX.Element | null => {
  if (activities.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6">Activity ({activities.length})</Typography>
        <div className="pl-1">
          {activities.map((a) => (
            <div key={a.key}>{a.txt}</div>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const clickAddFieldNote = (): void => {
  const state = FieldNote.getModalState(null);

  store.setState("field_note_modal", state);
};

const clickEditNote = (note: FieldNote): void => {
  const state = FieldNote.getModalState(note);
  store.setState("field_note_modal", state);
};

const renderImg = (id: number, image_file_name: string | null): JSX.Element | null => {
  if (!image_file_name) {
    return null;
  }

  const thumb = getImageUrl(id, "field_notes", "thumb", image_file_name);
  const original = getImageUrl(id, "field_notes", "original", image_file_name);

  return (
    <a href={original} target="_blank" rel="noopener noreferrer">
      <img alt="" width="50" src={thumb} style={{ marginRight: 5 }} />
    </a>
  );
};

const renderFieldNote = (field_note: FieldNote): JSX.Element => {
  const currentUser = store.getNonNullState("currentUser");

  return (
    <div key={`field_note-list-${field_note.id}`} className="d-flex mt-3 justify-content-between">
      <Box display="flex">
        {renderImg(field_note.id, field_note.image_file_name)}
        <div>{field_note.getText()}</div>
      </Box>

      <div>
        {(field_note.creator_id === currentUser.id || currentUser.isAdmin()) && (
          <Button onClick={(): void => clickEditNote(field_note)} size="small" variant="outlined">
            edit
          </Button>
        )}
      </div>
    </div>
  );
};

const renderFieldNotes = (field_notes: FieldNote[]): JSX.Element | null => {
  if (field_notes.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6">Field Notes ({field_notes.length})</Typography>
        <div className="pl-1">{field_notes.map(renderFieldNote)}</div>
      </div>
    );
  } else {
    return null;
  }
};

const clickNeedsAttention = (): void => {
  setTicketModalState(null);
};

const JobDetail = (): JSX.Element => {
  const room = store.useNonNullState("room");

  const activities = sortBy(room.getActivity(), (a) => a.created_at);

  const installsWithoutReservation = room.installs
    ? room.installs.filter((i) => i.reservation_id === null)
    : [];

  return (
    <div className="view-div">
      <Typography
        variant="h6"
        className="my-2 d-flex align-items-center justify-content-center"
        style={{ textAlign: "center" }}
      >
        {room.showName()}
        {getLockedIcon(room)}
        {getOccupiedIcon(room)}
        {getTicketIcon(room)}
        {getFieldNoteIcon(room)}
      </Typography>

      <LockedSection room={room} />

      <Box display="flex" justifyContent="center" className="mt-3">
        <OccupiedButton room={room} />

        <Button
          variant="outlined"
          color="default"
          size="small"
          className="mx-2"
          onClick={clickNeedsAttention}
        >
          needs attention?
        </Button>

        <Button size="small" variant="outlined" color="default" onClick={clickAddFieldNote}>
          add a field note
        </Button>
      </Box>

      <div className="pl-2 mt-2 mb-3">
        {room.getReservations().map((r) => (
          <ReservationRow room={room} reservation={r} key={`res_row_${r.id}`} />
        ))}

        {installsWithoutReservation.length ? (
          <div>
            <div>no associated reservation: </div>
            <div className="d-flex">
              {installsWithoutReservation.map((i) => (
                <InstallButton className="mx-1" key={`insta_${i.id}`} install={i} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <TicketsSection tickets={room.getTickets()} />
      {renderFieldNotes(room.getFieldNotes())}
      {renderActions(activities)}

      <FieldNoteModal />
      <TicketModal />
      <TicketResolutionModal />
      <InstallActionModal />
    </div>
  );
};

export default JobDetail;

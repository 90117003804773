import React from "react";
import Install from "../../models/Install";
import Button from "@material-ui/core/Button";
import { store } from "../../constants/globals";
import { getInstallActionModalState } from "../../helpers/install_helpers";
import { getColor } from "../../helpers/install_view_helpers";

type InstallButtonProps = {
  install: Install;
  className?: string;
};

const clickInstall = (i: Install): void => {
  const state = getInstallActionModalState(i);
  store.setState("install_action_modal", state);
};

const InstallButton = ({ install, className }: InstallButtonProps): JSX.Element => {
  const color = getColor(install.status);

  const style = {
    color: store.getState("colormode") === "dark" ? "white" : "black",
    borderColor: color,
    backgroundColor: color,
  };

  const short = install.product.id === 21 ? "LBX_purchase" : install.getShort();

  return (
    <Button
      style={style}
      className={className}
      variant="contained"
      onClick={(): void => clickInstall(install)}
    >
      {short}
    </Button>
  );
};

export default InstallButton;

import ApplicationModel from "./ApplicationModel";
import { ClassName } from "../types/types";
import { isPresent } from "../util/util";
import { UserJSON } from "../types/json_types";

class User extends ApplicationModel {
  email: string;
  admin: boolean;
  username: string;
  first_name: string;
  last_name: string;
  address1: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  ssn: string | null;
  role: string;
  phone: string;
  agreed_to_terms: boolean;
  active: boolean;

  constructor(json: UserJSON) {
    super(json);
    this.address1 = json.address1;
    this.city = json.city;
    this.state = json.state;
    this.zip_code = json.zip_code;
    this.ssn = json.ssn;
    this.email = json.email;
    this.admin = json.role === "admin";
    this.role = json.role;
    this.username = json.username || "";
    this.first_name = json.first_name;
    this.phone = json.phone;
    this.last_name = json.last_name;
    this.agreed_to_terms = json.agreed_to_terms;
    this.active = json.active;
  }

  getClassName(): ClassName {
    const className: ClassName = "User";
    return className;
  }

  fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  isAdmin(): boolean {
    return this.role === "admin";
  }

  selectName(): string {
    return `${this.username} - ${this.fullName()}`;
  }

  canPassGo(): boolean {
    return (
      this.agreed_to_terms &&
      isPresent(this.username) &&
      isPresent(this.first_name) &&
      isPresent(this.last_name)
    );
  }
}

export default User;

import { ListOnScrollProps } from "react-window";
import { store } from "../constants/globals";

let list_hold: Element | null = null;
let section_names: string[] = [];

export const clearListCache = (): void => {
  list_hold = null;
  section_names = [];
};

export const onScroll = (props: ListOnScrollProps): void => {
  if (list_hold) {
    let ele: Element | null = null;
    let title: string | null = null;
    if (list_hold.scrollTop < 40) {
      ele = list_hold.children[0];
    }

    if (props.scrollDirection === "forward") {
      ele = ele || list_hold.children[1];
      if (ele && ele.classList.contains("building-row")) {
        const tempTitle = ele.getAttribute("title");
        if (!tempTitle) {
          throw new Error("element doenst have title attribute");
        }

        title = tempTitle;
        if (!section_names.includes(title)) {
          section_names.push(title);
        }
      }
    } else if (props.scrollDirection === "backward") {
      ele = list_hold.children[2];

      if (ele && ele.classList.contains("building-row")) {
        const tempTitle = ele.getAttribute("title");
        if (!tempTitle) {
          throw new Error("element doenst have title attribute");
        }
        if (section_names.includes(tempTitle)) {
          section_names.pop();
          title = section_names[section_names.length - 1];
        }
      }
    }

    if (title) {
      store.setState("subtitle", title);
    }
  } else {
    const listEle = document.querySelector(".List");
    if (!listEle) {
      throw new Error(".List is not present in DOM");
    }
    list_hold = listEle.children[0];
  }
};

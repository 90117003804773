import { Store } from "set-state-is-great";
import { AppState } from "../types/state_types";
import { router } from "./router";
import { seasonStorageKey } from "../constants/globals";

const search = window.location.search;
// eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
let [_, year] = search.split("?year=");

const years = ["2020", "2021", "2022", "2023"];

export const seasons = [
  "2020_installs",
  "2020_removals",
  "2021_installs",
  "2021_removals",
  "2022_installs",
  "2022_removals",
  "2023_installs",
];

// default season
let season = "2023_installs";

if (year && !years.includes(year)) {
  season = `${year}_installs`;
  localStorage.setItem(seasonStorageKey, season);
} else {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
  let [_hold, s] = search.split("?season=");

  if (s && seasons.includes(s)) {
    season = s;
    localStorage.setItem(seasonStorageKey, season);
  } else {
    const localSeason = localStorage.getItem(seasonStorageKey) || "2023_installs";

    localStorage.setItem(seasonStorageKey, localSeason);

    if (localSeason && seasons.includes(localSeason)) {
      season = localSeason;
    }
  }
}

const appState: AppState = {
  home_note: "hello world",
  midyear_user_ids: [],
  season,
  colormode: localStorage.getItem("lightMode") ? "light" : "dark",
  working: false,
  drawerOpen: false,
  currentUser: null,
  collapsed_buildings: [],
  collapsed_dates: [],
};

const store = new Store<AppState>(appState);
export { store, router };

import School from "./School";
import { getModels } from "../constants/globals";
import { findModelById, filterModelsByAttr } from "../util/app_util";
import { FilterModalState } from "../types/state_types";
import { InstallStatus } from "./Install";
import Building from "./Building";
import { getDateFromDateStr, getDateStr, then } from "../util/util";
import { JobSearchType } from "../types/types";

export type FilterJSON = {
  install_statuses: InstallStatus[] | null;
  locked: boolean | null | undefined;
  school_id: number;
  building_ids: number[] | null;
  has_special_instructions: boolean | null;
  has_unresolved_tickets: boolean | null;
  has_resolved_tickets: boolean | null;
  jobSearchType: JobSearchType | null;
  no_upgrades: boolean | null;
  occupied: boolean | null;
  only_available_jobs: boolean | null;
  product_ids: number[] | null;
  arrival_date: string | undefined | null;
  leaving_before: string | null;
  upgrade_ids: number[] | null;
};

class Filter {
  school_id: number;
  building_ids: number[];
  product_ids: number[];
  upgrade_ids: number[];
  has_special_instructions: boolean;
  has_unresolved_tickets: boolean;
  has_resolved_tickets: boolean;
  no_upgrades: boolean;
  occupied: boolean;
  jobSearchType: JobSearchType;
  only_available_jobs: boolean;
  arrival_date: Date | null;
  leaving_before: Date | null;
  install_statuses: InstallStatus[];
  school?: School;
  locked: boolean | null | undefined;
  buildings?: Building[];

  constructor(json: FilterJSON) {
    this.occupied = json.occupied || false;
    this.only_available_jobs = json.only_available_jobs || false;
    this.arrival_date = json.arrival_date ? new Date(json.arrival_date) : null;
    this.install_statuses = json.install_statuses || [];
    this.school_id = json.school_id;
    this.locked = json.locked;
    this.building_ids = json.building_ids || [];
    this.has_special_instructions = json.has_special_instructions || false;
    this.has_unresolved_tickets = json.has_unresolved_tickets || false;
    this.has_resolved_tickets = json.has_resolved_tickets || false;
    this.jobSearchType = json.jobSearchType || "wing letter, then room number";
    this.no_upgrades = json.no_upgrades || false;
    this.product_ids = json.product_ids || [];
    this.upgrade_ids = json.upgrade_ids || [];
    this.leaving_before =
      json.leaving_before == null
        ? null
        : then(getDateFromDateStr(json.leaving_before), (d) => {
            d.setHours(9);
            return d;
          });
  }

  static emptyJSON(): FilterJSON {
    return {
      install_statuses: [],
      school_id: getModels("schools")[0].id,
      building_ids: [],
      has_special_instructions: false,
      has_unresolved_tickets: false,
      has_resolved_tickets: false,
      jobSearchType: "wing letter, then room number",
      occupied: false,
      only_available_jobs: false,
      no_upgrades: false,
      leaving_before: null,
      product_ids: [],
      upgrade_ids: [],
      locked: undefined,
      arrival_date: null,
    };
  }

  getSchool(): School {
    if (this.school) {
      return this.school;
    } else {
      const school = findModelById(getModels("schools"), this.school_id);

      this.school = school;

      return school;
    }
  }

  getBuildings(): Building[] {
    if (this.buildings) {
      return this.buildings;
    } else {
      const schoolBuildings = filterModelsByAttr(
        getModels("buildings"),
        "school_id",
        this.school_id,
      );
      this.buildings = this.building_ids.map((bid) => findModelById(schoolBuildings, bid));
      return this.buildings;
    }
  }

  clearCache(): void {
    this.school = undefined;
    this.buildings = undefined;
  }

  static loadFromLocalStorage(): Filter | null {
    const json_str = window.localStorage.getItem("filter");

    const filter_json = json_str ? (JSON.parse(json_str) as FilterJSON) : null;

    if (filter_json) {
      const filter = new Filter(filter_json);

      return filter;
    } else {
      return null;
    }
  }

  modalState(): FilterModalState {
    return {
      install_statuses: this.install_statuses,
      school_id: this.school_id,
      locked: this.locked,
      building_ids: this.building_ids,
      has_special_instructions: this.has_special_instructions,
      has_unresolved_tickets: this.has_unresolved_tickets,
      has_resolved_tickets: this.has_resolved_tickets,
      jobSearchType: this.jobSearchType,
      no_upgrades: this.no_upgrades,
      occupied: this.occupied,
      only_available_jobs: this.only_available_jobs,
      product_ids: this.product_ids,
      upgrade_ids: this.upgrade_ids,
      arrival_date: this.arrival_date,
      leaving_before: this.leaving_before,
    };
  }

  toJSON(): FilterJSON {
    return {
      install_statuses: this.install_statuses,
      locked: this.locked,
      has_unresolved_tickets: this.has_unresolved_tickets,
      has_resolved_tickets: this.has_resolved_tickets,
      school_id: this.school_id,
      building_ids: this.building_ids,
      has_special_instructions: this.has_special_instructions,
      no_upgrades: this.no_upgrades,
      jobSearchType: this.jobSearchType,
      occupied: this.occupied,
      leaving_before: this.leaving_before == null ? null : getDateStr(this.leaving_before),
      only_available_jobs: this.only_available_jobs,
      product_ids: this.product_ids,
      upgrade_ids: this.upgrade_ids,
      arrival_date: this.arrival_date?.toString(),
    };
  }

  saveToLocalStorage(): void {
    localStorage.setItem("filter", JSON.stringify(this));
  }

  static emptyModalState(): FilterModalState {
    return {
      install_statuses: [],
      school_id: null,
      locked: undefined,
      building_ids: [],
      occupied: false,
      has_special_instructions: false,
      has_unresolved_tickets: false,
      has_resolved_tickets: false,
      jobSearchType: "wing letter, then room number",
      no_upgrades: false,
      only_available_jobs: false,
      leaving_before: null,
      product_ids: [],
      upgrade_ids: [],
      arrival_date: null,
    };
  }
}

export default Filter;

import { store } from "../constants/globals";

const Home = (): JSX.Element => {
  const data = store.state.home_note || "hello world";
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: data }} className="pl-1"></div>
    </div>
  );
};

export default Home;

import { pathToRegexp } from "path-to-regexp";
import { then } from "../util/util";

type PlainObject = Record<string, any>;

export type RouteMap<T> = {
  [route: string]: (...params: string[]) => T;
};

function _shallowEqual(a: any, b: any): boolean {
  const keys1 = Object.keys(a);
  const keys2 = Object.keys(b);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

type Props<T> = {
  routeMappers: RouteMap<T>;
  handler: (state: T) => void;
  badRouterHandler: (path?: string) => T;
};

export default class StateRouter<T> {
  private regexpMap: [RegExp, (...params: string[]) => T][];
  private badRouterHandler: (path?: string) => T;
  private handler: (state: T) => void;

  constructor({ routeMappers, handler, badRouterHandler }: Props<T>) {
    this.regexpMap = Object.entries(routeMappers).map(([path, fn]) => [pathToRegexp(path), fn]);
    this.handler = handler;
    this.badRouterHandler = badRouterHandler;

    window.onpopstate = (): void => {
      const state = this.stateFromPath(window.location.pathname);
      this.handler(state);
    };
  }
  replace(path: string): void {
    const state = this.stateFromPath(path);
    const currentState = then(window.history.state, (s) =>
      s == null ? (this.stateFromPath(window.location.pathname) as PlainObject | undefined) : s,
    );

    if (state == null) {
      this.badRouterHandler(path);
      return;
    }

    if (currentState == null || !_shallowEqual(state, currentState)) {
      window.history.replaceState(state, "", path);

      if (state == null) {
        this.badRouterHandler(path);
      } else {
        this.handler(state);
      }
    }
  }

  go(path: string): void {
    const state = this.stateFromPath(path);
    const currentState = then(window.history.state, (s) =>
      s == null ? (this.stateFromPath(window.location.pathname) as PlainObject | undefined) : s,
    );

    if (state == null) {
      this.badRouterHandler(path);
      return;
    }

    if (currentState == null || !_shallowEqual(state, currentState)) {
      window.history.pushState(state, "", path);

      if (state == null) {
        this.badRouterHandler(path);
      } else {
        this.handler(state);
      }
    }
  }

  stateFromPath(path: string): T {
    const arr = this.regexpMap.find((m) => m[0].test(path));
    if (!arr) {
      return this.badRouterHandler(path);
    }

    const [regexp, stateMapper] = arr;

    const execArr = regexp.exec(path);
    const params = execArr ? execArr.slice(1, execArr.length) : null;

    return params ? stateMapper(...params) : stateMapper();
  }
}

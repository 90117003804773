import ApplicationModel from "./ApplicationModel";
import { InstallActionJSON } from "../types/json_types";
import { ClassName } from "../types/types";
import Install from "./Install";
import User from "./User";
import strftime from "strftime";
import { getModels } from "../constants/globals";
import { findModelById } from "../util/app_util";
import { andJoin, getDateStr, myRound } from "../util/util";

export type Activity = {
  id: number;
  key: string;
  txt: string;
  room_id: number;
  creator: User;
  created_at: Date;
};

export type MoneyType = "delivered" | "installed" | "uninstalled" | "removed";

export type InstallActionType = MoneyType | "set_needs_removal" | "set_missing";

export const money_types: InstallActionType[] = [
  "delivered",
  "installed",
  "uninstalled",
  "removed",
];

class InstallAction extends ApplicationModel {
  set: boolean;
  creator_id: number;
  creator: User;
  splitter_ids: number[];
  splitters: User[];
  actor_id: number;
  actor: User;
  install_id: number;
  install?: Install;
  type: InstallActionType;
  created_at_str?: string;

  money_type: MoneyType | null;

  constructor(json: InstallActionJSON) {
    super(json);

    const users = getModels("users");

    this.set = json.set;
    this.type = json.type;

    this.money_type =
      this.type === "set_needs_removal" || this.type === "set_missing"
        ? null
        : money_types.includes(this.type)
        ? this.type
        : null;
    this.splitter_ids = json.splitter_ids;
    this.splitters = this.splitter_ids.map((id) => findModelById(users, id));
    this.creator_id = json.creator_id;
    this.creator = findModelById(users, this.creator_id);
    this.actor_id = json.actor_id;
    this.actor = findModelById(users, this.actor_id);
    this.install_id = json.install_id;
  }

  getClassName(): ClassName {
    const className: ClassName = "InstallAction";
    return className;
  }

  getCreatedAtStr(): string {
    if (this.created_at_str) {
      return this.created_at_str;
    } else {
      this.created_at_str = getDateStr(this.created_at);
      return this.created_at_str;
    }
  }

  isMoney(): boolean {
    return money_types.includes(this.type);
  }

  toActivity(): Activity | null {
    if (!this.install) {
      return null;
    }

    return {
      id: this.id,
      txt: this.getText(),
      room_id: this.install.room_id,
      creator: this.creator,
      created_at: this.created_at,
      key: `install_action_${this.id}_activity`,
    };
  }

  individualCount(): number {
    return myRound(1 / (this.splitter_ids.length + 1));
  }

  touchedByUser(user: User): boolean {
    return this.actor_id === user.id || this.splitter_ids.includes(user.id);
  }

  getText(): string {
    const creatorName = this.creator.username;
    if (!this.install) {
      return "?";
    }

    const short = this.install.product.short;
    const dateStr = `on ${strftime("%b %e %Y @ %l:%M %p", this.created_at)}`;

    let splitterTxt = "";

    if (this.type === "set_needs_removal") {
      return `${creatorName} set that ${short} needs removal on ${dateStr}`;
    } else if (this.type === "set_missing") {
      return `${creatorName} set ${short} missing on ${dateStr}`;
    } else {
      if (this.splitters.length) {
        const usernames = andJoin(this.splitters.map((s) => s.username));
        splitterTxt = `+ ${usernames}`;
      }

      if (this.creator === this.actor) {
        return `${creatorName} ${splitterTxt} ${this.type} ${short} ${dateStr}`;
      } else {
        const actorName = this.actor.username;
        return `${creatorName} set that ${actorName} ${splitterTxt} ${this.type} ${short} ${dateStr}`;
      }
    }
  }
}

export default InstallAction;

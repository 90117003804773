import { getAllData, router, store } from "../constants/globals";
import { RouterState, transformState } from "../init_app/router";
import { ApiRequest } from "../libs/ApiRequest";
import User from "../models/User";
import { AllJSONResponse } from "../types/json_types";
import { AppState } from "../types/state_types";
import { handleData } from "../util/handle_data";
import { then } from "../util/util";
import { onAuthPage } from "./auth_helpers";

const dataRequest = new ApiRequest({
  url: `/worker_api/all_worker_season_json.json?season=${store.state.season}`,
  method: "GET",
  onSuccess: (resp: unknown): void => {
    const json = resp as AllJSONResponse;
    if (json.auth?.currentUser != null && json.data != null) {
      handleData(json.data);
      window.D = getAllData();

      const currentUser = new User(json.auth.currentUser);
      store.state.currentUser = currentUser;
      if (json.data.accounts) {
        store.state.midyear_user_ids = json.data.accounts[0]?.midyear_user_ids || [];
      }

      const state = router.stateFromPath(window.location.pathname);
      const {
        home_note,
        midyear_user_ids,
        season,
        colormode,
        working,
        drawerOpen,
        collapsed_buildings,
        collapsed_dates,
      } = store.state;

      const appState = then(
        state,
        (s: RouterState): AppState => {
          const hold: AppState = {
            home_note,
            midyear_user_ids,
            season,
            colormode,
            working,
            drawerOpen,
            currentUser,
            collapsed_buildings,
            collapsed_dates,
          };
          Object.assign(hold, s);
          return hold;
        },
      );

      store.state = appState;
      store.forceUpdate("viewShown");
    } else {
      store.state.currentUser = null;

      if (onAuthPage()) {
        const routerState = router.stateFromPath(window.location.pathname);
        store.state = transformState(store.state, routerState);

        setTimeout(() => {
          store.forceUpdate("viewShown");
        });
      } else {
        router.go("/login");
      }
    }
  },
  onError: (): void => {
    localStorage.clear();
    alert("an error occurred while loading the data");
  },
});

export function fetchData(): void {
  if (dataRequest.status > 0) {
    return;
  }

  dataRequest.perform();
}

import ApplicationModel from "./ApplicationModel";
import { ClassName } from "../types/types";
import { PaycheckJSON } from "../types/json_types";
import { PaycheckModalState } from "../types/state_types";

class Paycheck extends ApplicationModel {
  date_at: Date;
  type: string;
  check_number: string;
  amount_cents: number;
  payee_id: number;
  creator_id: number;
  notes: string | null;

  constructor(json: PaycheckJSON) {
    super(json);

    this.date_at = new Date(json.date_at + "Z");
    this.type = json.type;
    this.check_number = json.check_number;
    this.amount_cents = json.amount_cents;
    this.payee_id = json.payee_id;
    this.creator_id = json.creator_id;
    this.notes = json.notes;
  }

  getClassName(): ClassName {
    const className: ClassName = "Paycheck";
    return className;
  }

  static getModalState(
    paycheck: Paycheck | null,
    payee_id: number | null
  ): PaycheckModalState {
    const id = paycheck?.id || null;
    payee_id = payee_id || paycheck?.payee_id || null;
    const amount_cents = paycheck?.amount_cents || null;
    const amount = amount_cents ? amount_cents / 100 : null;
    const type = paycheck?.type || "check";
    const check_number = paycheck?.check_number || null;
    const notes = paycheck?.notes || null;
    const date_at = paycheck?.date_at || new Date();
    const working = false;
    const errors: string[] = [];

    return {
      id,
      notes,
      payee_id,
      check_number,
      amount,
      type,
      date_at,
      working,
      errors,
    };
  }
}

export default Paycheck;

import React from "react";
import { isErrorResponse, RoomLockingJSON } from "../../types/json_types";
import reject from "lodash/reject";
import { store, getModels, setModels } from "../../constants/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import Room from "../../models/Room";
import CenterAll from "../util/CenterAll";
import LockedSelect from "./LockedSelect";
import RoomLocking from "../../models/RoomLocking";

interface LockedSectionProps {
  room: Room;
}

type RoomLockingResp = {
  room_locking: RoomLockingJSON;
};

const onChangedLocked = (nextLocked: boolean | null, room: Room): void => {
  ApiRequest.perform({
    url: `/worker_api/room_lockings.json`,
    method: "POST",
    onSuccess: (resp: unknown) => {
      const json = resp as RoomLockingResp;
      const roomLocking = new RoomLocking(json.room_locking as RoomLockingJSON);
      const room_lockings = reject(getModels("room_lockings"), (rl) => rl.room_id === room.id);
      room_lockings.unshift(roomLocking);
      setModels("room_lockings", room_lockings);
      room.locked = roomLocking.locked;
      store.forceUpdate("room");
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        alert(json.errors.join(". "));
      } else {
        alert("an error occurred while changing locked status");
      }
    },
    data: {
      room_id: room.id,
      locked: nextLocked,
    },
  });
};

const LockedSection = (props: LockedSectionProps): JSX.Element => {
  const { room } = props;
  return (
    <CenterAll className="mt-2">
      <LockedSelect
        locked={room.locked}
        onChange={(nextLocked): void => onChangedLocked(nextLocked, room)}
      />
    </CenterAll>
  );
};

export default LockedSection;

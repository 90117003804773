import ApplicationModel from "./ApplicationModel";
import { TruckLoadJSON } from "../types/json_types";
import { ClassName } from "../types/types";
import User from "./User";
import School from "./School";
import Warehouse from "./Warehouse";
import { findModelById, findModelsByIdInclusion } from "../util/app_util";
import { getModels } from "../constants/globals";
import Building from "./Building";
import { counts } from "../constants/shorts";
import Payscale from "./Payscale";
import Vehicle from "./Vehicle";

export type TruckLoadType = "dropoff" | "pickup" | "load" | "unload";

class TruckLoad extends ApplicationModel {
  bl_count: number;
  mf_count: number;
  sr_count: number;
  ft_count: number;
  dft_count: number;
  bs_count: number;
  ffc_count: number;
  lbx_count: number;
  pm_count: number;
  bb_count: number;
  dsk_count: number;
  tv32_count: number;
  tv40_count: number;
  notes: string;
  building_ids: number[];
  worker_ids: number[];
  creator_id: number;
  creator: User;
  school_id: number;
  school: School;
  driver_id: number | null;
  driver: User | null;
  warehouse_id: number | null;
  warehouse: Warehouse | null;
  vehicle_id: number | null;
  vehicle: Vehicle | null;
  type: TruckLoadType;
  performed_at: Date;

  constructor(json: TruckLoadJSON) {
    super(json);

    this.bl_count = json.bl_count || 0;
    this.ffc_count = json.ffc_count || 0;
    this.mf_count = json.mf_count || 0;
    this.sr_count = json.sr_count || 0;
    this.ft_count = json.ft_count || 0;
    this.dft_count = json.dft_count || 0;
    this.bs_count = json.bs_count || 0;
    this.lbx_count = json.lbx_count || 0;
    this.pm_count = json.pm_count || 0;
    this.tv32_count = json.tv32_count || 0;
    this.tv40_count = json.tv40_count || 0;
    this.bb_count = json.bb_count || 0;
    this.dsk_count = json.dsk_count || 0;
    this.notes = json.notes;
    this.building_ids = json.building_ids;
    this.worker_ids = json.worker_ids;
    this.creator_id = json.creator_id;
    this.creator = findModelById(getModels("users"), this.creator_id);
    this.school_id = json.school_id;
    this.school = findModelById(getModels("schools"), json.school_id);
    this.driver_id = json.driver_id;
    this.driver = json.driver_id ? findModelById(getModels("users"), json.driver_id) : null;
    this.warehouse_id = json.warehouse_id;
    this.warehouse = json.warehouse_id
      ? findModelById(getModels("warehouses"), json.warehouse_id)
      : null;

    this.vehicle_id = json.vehicle_id;
    this.vehicle = json.vehicle_id ? findModelById(getModels("vehicles"), json.vehicle_id) : null;
    this.type = json.type;
    this.performed_at = new Date(json.performed_at + "Z");
  }

  getClassName(): ClassName {
    const className: ClassName = "TruckLoad";
    return className;
  }

  getWorkers(): User[] {
    const users = getModels("users");
    return findModelsByIdInclusion(users, this.worker_ids);
  }

  getBuildings(): Building[] {
    const buildings = getModels("buildings");
    return findModelsByIdInclusion(buildings, this.building_ids);
  }

  individualCents(payscale: Payscale | null): number {
    if (!payscale) {
      return 0;
    }

    return this.totalCents(payscale) / this.worker_ids.length;
  }

  loadedTruck(user: User): boolean {
    return this.worker_ids.includes(user.id);
  }

  workedTruckLoad(user: User): boolean {
    return this.driver_id === user.id || this.loadedTruck(user);
  }

  totalCents(payscale: Payscale | null): number {
    if (!payscale) {
      return 0;
    }

    return (
      this.bl_count * payscale.getCents("BL") +
      this.mf_count * payscale.getCents("MF") +
      this.sr_count * payscale.getCents("SR") +
      this.ft_count * payscale.getCents("FT") +
      this.dft_count * payscale.getCents("FT") +
      this.bs_count * payscale.getCents("BS") +
      this.lbx_count * payscale.getCents("LBX") +
      this.ffc_count * payscale.getCents("FFC") +
      this.pm_count * payscale.getCents("PM") +
      this.tv32_count * payscale.getCents("TV") +
      this.tv40_count * payscale.getCents("TV")
    );
  }

  total(): number {
    let sum = 0;
    for (let index = 0; index < counts.length; index++) {
      const count = counts[index];
      sum += this[count];
    }

    return sum;
  }
}

export default TruckLoad;

import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Ticket from "../../models/Ticket";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { store, getModels } from "../../constants/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { findModelById } from "../../util/app_util";
import { TicketRespJSON } from "../../types/json_types";

const { setPartialState, setState, useStoreState } = store.getScopedHelpers("ticket_modal");

function getModalStyle(): CSSProperties {
  const top = 50;

  return {
    top: `${top}%`,
    left: 0,
    right: 0,
    transform: `translateY(-${top}%)`,
    outline: 0,
    width: "100%",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

const clickUpdate = (id: number, text: string, working: boolean): void => {
  if (working) {
    return;
  }

  const data = {
    text: text,
  };
  setPartialState({ working: true });
  ApiRequest.perform({
    url: `/worker_api/tickets/${id}.json`,
    method: "PATCH",
    onSuccess: (resp: unknown) => {
      const allTickets = getModels("tickets");
      const ticket = findModelById(allTickets, id);
      const room = findModelById(getModels("rooms"), ticket.room_id);

      const json = resp as TicketRespJSON;

      const nextTicket = new Ticket(json.ticket);
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;
      room.tickets_count = json.room.tickets_count;

      Object.assign(ticket, nextTicket);

      store.state.ticket_modal = undefined;

      store.forceUpdate("room");
    },

    onError: () => {
      alert("an error occurred");
    },
    data,
  });
};

function clickSubmit(text: string, working: boolean): void {
  if (working) {
    return;
  }

  const room = store.getNonNullState("room");

  setPartialState({ working: true });

  const data = {
    room_id: room.id,
    text: text,
  };

  ApiRequest.perform({
    url: "/worker_api/tickets.json",
    method: "POST",
    onSuccess: (resp: unknown): void => {
      const json = resp as TicketRespJSON;
      const ticket = new Ticket(json.ticket);
      getModels("tickets").unshift(ticket);
      const appState = store.state;

      room.tickets_count = json.room.tickets_count;
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;

      appState.ticket_modal = undefined;

      store.forceUpdate("room");
    },
    onError: (): void => {
      alert("an error occurred....  or did it?  yes it did");
      setPartialState({ working: false });
    },

    data,
  });
}

const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  setPartialState({ text: event.target.value });
};

const handleClose = (): void => {
  setState(undefined);
};

const TicketModal = (): JSX.Element | null => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();

  if (!state) {
    return null;
  }

  const { id, working, text } = state;

  const title = id ? "Update needs attention ticket" : "Why does this room need attention?";

  return (
    <Modal open={true} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography variant="h6" className="d-flex">
            {title}
          </Typography>

          <TextField
            style={{ width: "100%" }}
            className="mt-2"
            id="outlined-multiline-static"
            multiline
            rows="4"
            value={text}
            onChange={onChange}
            variant="outlined"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={working}>
            Cancel
          </Button>

          {id ? (
            <Button
              onClick={(): void => clickUpdate(id, text, working)}
              variant="outlined"
              disabled={working}
            >
              Update
            </Button>
          ) : (
            <Button
              onClick={(): void => clickSubmit(text, working)}
              variant="outlined"
              disabled={working}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TicketModal;

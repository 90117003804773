import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { router } from "../../constants/globals";
import User from "../../models/User";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

interface EarningsTabsProps {
  earned_tab: number;
  user: User;
}

function EarningsTabs(props: EarningsTabsProps): JSX.Element {
  const { earned_tab, user } = props;

  const classes = useStyles();

  const changeTab = (event: React.ChangeEvent<unknown>, newValue: number): void => {
    const path = newValue === 0 ? `/workers/${user.id}` : `/workers/${user.id}/daily_earnings`;

    router.go(path);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={earned_tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
          <Tab label="Seasonal earnings" />
          <Tab label="Daily earnings" />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default React.memo(EarningsTabs);

import sortBy from "lodash/sortBy";
import Install, { InstallStatus, install_statuses } from "../models/Install";
import { shorts } from "../constants/shorts";
import { InstallActionModalState } from "../types/state_types";

export const getInstallActionModalState = (
  install: Install
): InstallActionModalState => {
  return {
    install,
    working: false,
    tab: install.needs_removal ? 1 : 0,
    delivered_by_id: install.delivered_action?.actor_id || null,
    delivered_by_splitter_ids: install.delivered_action?.splitter_ids || [],
    installed_by_id: install.installed_action?.actor_id || null,
    installed_by_splitter_ids: install.installed_action?.splitter_ids || [],
    uninstalled_by_id: install.uninstalled_action?.actor_id || null,
    uninstalled_by_splitter_ids: install.uninstalled_action?.splitter_ids || [],
    removed_by_id: install.removed_action?.actor_id || null,
    removed_by_splitter_ids: install.removed_action?.splitter_ids || [],
    set_needs_removal_by_id: install.set_needs_removal_action?.actor_id || null,
  };
};

export const sortInstallsByShort = (installs: Install[]): Install[] => {
  return sortBy(installs, (i) => {
    return shorts.indexOf(i.product.short);
  });
};

export const sortStatuses = (statuses: InstallStatus[]): InstallStatus[] => {
  return sortBy(statuses, (s) => {
    return install_statuses.indexOf(s);
  });
};

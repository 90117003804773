import User from "../../models/User";
import EarningsTabs from "./EarningsTabs";
import AllTimeEarnings from "./AllTimeEarnings";
import DailyEarnings from "./DailyEarnings";

export interface EarningsPageProps {
  user: User;
  earned_tab: number;
  date: Date | null;
}

const EarningsPage = (props: EarningsPageProps): JSX.Element => {
  const { earned_tab, user } = props;

  return (
    <div>
      <EarningsTabs earned_tab={earned_tab} user={user} />
      {earned_tab === 0 ? <AllTimeEarnings {...props} /> : <DailyEarnings {...props} />}
    </div>
  );
};

export default EarningsPage;

import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import BuildIcon from "@material-ui/icons/Build";
import IconButton from "@material-ui/core/IconButton";
import { FilterModalState } from "../../types/state_types";
import Filter from "../../models/Filter";
import { store, getModels, router } from "../../constants/globals";

const clickFilter = (): void => {
  const filter = window.App.filter;
  let state: FilterModalState;

  if (filter) {
    state = filter.modalState();
  } else {
    state = {
      ...Filter.emptyModalState(),
      school_id: getModels("schools")[0].id,
    };
  }

  store.setState("filter_modal", state);
};

const clickJobs = (): void => {
  router.go("/jobs");
};

function AppBarRight(): JSX.Element {
  const viewShown = store.useState("viewShown");

  return (
    <div className="d-flex">
      {viewShown === "Jobs" ? null : (
        <IconButton onClick={clickJobs} edge="start" color="inherit" aria-label="menu">
          <BuildIcon />
        </IconButton>
      )}

      <IconButton
        style={{ marginLeft: 1 }}
        onClick={clickFilter}
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <FilterListIcon />
      </IconButton>
    </div>
  );
}

export default AppBarRight;

import Filter from "../models/Filter";
import { store } from "../constants/globals";
import Ticket from "../models/Ticket";
import {
  TicketModalState,
  TicketResolutionModalState,
} from "../types/state_types";
import { filterModelsByInclusion } from "../util/app_util";

export const getTicketModalState = (
  ticket: Ticket | null
): TicketModalState => {
  return {
    id: ticket?.id || null,
    text: ticket?.text || "",
    working: false,
  };
};

export const setTicketModalState = (ticket: Ticket | null): void => {
  const state = getTicketModalState(ticket);
  store.setState("ticket_modal", state);
};

export const getTicketResolutionModalState = (
  ticket: Ticket
): TicketResolutionModalState => {
  return {
    ticket_id: ticket.id,
    resolution_notes: ticket?.resolution_notes || "",
    working: false,
  };
};

export const setTicketResolutionModalState = (ticket: Ticket): void => {
  const state = getTicketResolutionModalState(ticket);
  store.setState("ticket_resolution_modal", state);
};

export const filterTickets = (
  tickets: Ticket[],
  filter: Filter | null
): Ticket[] => {
  if (!filter) {
    return tickets;
  }

  tickets = tickets.filter((t) => t.getSchool().id === filter.school_id);

  if (filter.building_ids.length) {
    tickets = filterModelsByInclusion(
      tickets,
      "building_id",
      filter.building_ids
    );
  }

  return tickets;
};

import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { router } from "../../constants/globals";
import User from "../../models/User";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

type UserWorkTabsProps = {
  tab: number;
  user: User;
};

function UserWorkTabs({ tab, user }: UserWorkTabsProps): JSX.Element {
  const classes = useStyles();

  const changeTab = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ): void => {
    if (newValue === 0) {
      router.go(`/workers/${user.id}`);
    } else if (newValue === 1) {
      router.go(`/workers/${user.id}/bonus_bucks`);
    } else {
      router.go(`/workers/${user.id}/received`);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={tab}
          onChange={changeTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Earned" />
          <Tab label="Bonus Bucks" />
          <Tab label="Received" />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default UserWorkTabs;

import ApplicationModel from "../models/ApplicationModel";

import uniq from "lodash/uniq";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import Building from "../models/Building";
import User from "../models/User";
import { getModels, store } from "../constants/globals";
import { Count } from "../constants/shorts";
import toUpper from "lodash/toUpper";

export const findModelById = function <T extends ApplicationModel>(arr: Array<T>, id: number): T {
  const length = arr.length;

  for (let index = 0; index < length; index++) {
    if (arr[index].id === id) return arr[index];
  }

  throw new Error(`Couldn't find ${arr[0].getClassName()} by id ${id}`);
};

export const findModelByAttr = function <T, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  value: T[Key],
): T {
  const length = arr.length;

  for (let index = 0; index < length; index++) {
    if (arr[index][attr] === value) return arr[index];
  }

  throw new Error(`Couldn't find thing by attr ${attr.toString()} & val ${value}`);
};

export const filterModelsByAttr = function <T, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  value: T[Key],
): T[] {
  const length = arr.length;

  const foundItems: Array<T> = [];
  let item: T;

  for (let index = 0; index < length; index++) {
    item = arr[index];
    if (item[attr] === value) {
      foundItems.push(item);
    }
  }

  return foundItems;
};

export const groupById = <T extends { id: number }>(arr: T[]): { [id: number]: T } => {
  const obj: { [id: number]: T } = {};
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];

    obj[element.id] = element;
  }

  return obj;
};

export const filterModelsByInclusion = function <T extends ApplicationModel, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  ids: number[],
): T[] {
  const length = arr.length;
  const models = [];
  let model: T;

  for (let index = 0; index < length; index++) {
    model = arr[index];
    const id: unknown = model[attr];
    if (ids.includes(id as number)) {
      models.push(model);
    }
  }

  return models;
};

export const groupByIdAttr = <T, Key extends keyof T>(
  arr: T[],
  attr: Key,
): { [id: number]: T[] } => {
  const obj: { [id: number]: T[] } = {};

  for (let index = 0; index < arr.length; index++) {
    const ele = arr[index];
    const val = (ele[attr] as unknown) as number;

    if (obj[val]) {
      obj[val].push(ele);
    } else {
      obj[val] = [ele];
    }
  }

  return obj;
};

export type ServerUrl =
  | `https://dd4.dormsdirect.com`
  | "http://localhost:3000"
  | "https://play.dorms.direct";
export const getServer = (): ServerUrl => {
  if (window.location.host === "play.dorms.direct") {
    return "https://play.dorms.direct";
  }
  return window.location.host === `dd4.dormsdirect.com`
    ? `https://dd4.dormsdirect.com`
    : "http://localhost:3000";
};

type BelongsToBuilding = {
  building_id: number;
};

export const intersperseBuildings = function <T extends BelongsToBuilding>(
  items: T[],
  buildings: Array<Building>,
): Array<T | Building> {
  const building_ids = uniq(map(items, "building_id"));
  buildings = sortBy(
    buildings.filter((b) => building_ids.includes(b.id)),
    (b) => b.shown_name,
  );

  return buildings
    .map((building) => [building, items.filter((r) => r.building_id === building.id)].flat())
    .flat();
};

export const getActiveUsers = (): User[] => {
  return filterModelsByAttr(getModels("users"), "active", true);
};

export const findModelsByIdInclusion = function <T extends { id: number }>(
  arr: Array<T>,
  ids: number[],
): T[] {
  const length = arr.length;
  const models = [];
  let model: T;

  for (let index = 0; index < length; index++) {
    model = arr[index];
    if (ids.includes(model.id)) {
      models.push(model);
    }
  }

  return models;
};

export const filterModelsByAttrInclusion = function <T, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  id: number,
): T[] {
  const length = arr.length;
  const models = [];
  let model: T;
  let ids: unknown;

  for (let index = 0; index < length; index++) {
    model = arr[index];
    ids = model[attr];
    if ((ids as number[]).includes(id)) {
      models.push(model);
    }
  }

  return models;
};

export const shortFromCount = (count: Count): string => {
  return toUpper(count.split("_")[0]);
};

export const toggleDarkmode = (): void => {
  const state = store.state;

  if (localStorage.getItem("lightMode")) {
    localStorage.removeItem("lightMode");
    state.colormode = "dark";
    document.body.className = "darkmode";
  } else {
    localStorage.setItem("lightMode", "yup");
    state.colormode = "light";
    document.body.className = "lightmode";
  }

  store.forceUpdate("colormode");
};

export const isIos = (): boolean => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const clearSelection = (): void => {
  const sel = window.getSelection ? window.getSelection() : null;
  if (sel) {
    if (sel.removeAllRanges) {
      sel.removeAllRanges();
    } else if (sel.empty) {
      sel.empty();
    }
  }
};

export const departureDateStrForSchool = (school_id: number): string => {
  switch (school_id) {
    case 1:
      return "2021-05-24";
    case 2:
      return "2021-05-10";
    case 3:
      return "2021-05-07";
    case 8:
      return "2021-05-07";
    case 9:
      return "2021-05-07";
    default:
      return "2021-05-07";
  }
};

export const simpleDate = (date: Date): string => {
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

export const friendlySeason = (): string => {
  return store.state.season.replace("_", " ");
};

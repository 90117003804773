import { BasicInfoFormState } from "../types/state_types";
import User from "../models/User";

const authRegex = /^\/(login|signup)/;
export const onAuthPage = (): boolean => {
  return authRegex.test(window.location.pathname);
};

export const getBasicInfoFormState = (
  user: User | null
): BasicInfoFormState => {
  return {
    user_id: user?.id || null,
    email: user?.email || null,
    first_name: user?.first_name || null,
    last_name: user?.last_name || null,
    username: user?.username || null,
    phone: user?.phone || null,
    agreed_to_terms: user?.agreed_to_terms || false,
    working: false,
    errors: [],
  };
};

import React from "react";
import Button from "@material-ui/core/Button";
import FieldNote from "../../models/FieldNote";
import { store, getModels } from "../../constants/globals";
import { findModelById } from "../../util/app_util";
import { FieldNoteJsonResp } from "./FieldNoteModal";

const { getNonNullState, setState, setPartialState } = store.getScopedHelpers("field_note_modal");

function clickSave(id: number, working: boolean): void {
  const { note, image_file_name } = getNonNullState();
  if (working) {
    return;
  }

  const room = store.getState("room");

  if (!room) {
    throw new Error("no room, wtf");
  }

  const formData = new FormData();

  const fileInput = document.querySelector("input#image") as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append("image", fileInput.files[0]);
  }

  formData.append("note", note);
  formData.append("image_file_name", image_file_name || "null");

  setPartialState({ working: true });
  fetch(`/worker_api/field_notes/${id}`, {
    method: "PUT",
    body: formData,
  })
    .then((response) => response.json())
    .then((resp: unknown): void => {
      const json = resp as FieldNoteJsonResp;
      const allFieldNotes = getModels("field_notes");
      const field_note = findModelById(allFieldNotes, id);

      const nextFieldNote = new FieldNote(json.field_note);

      const index = allFieldNotes.indexOf(field_note);
      allFieldNotes[index] = nextFieldNote;

      setState(undefined);
      setTimeout(() => {
        store.forceUpdate("viewShown");
      });
    })
    .catch(() => {
      setPartialState({ working: false });
    });
}

interface FieldNoteSaveButtonProps {
  id: number;
  working: boolean;
}

const FieldNoteSaveButton = (props: FieldNoteSaveButtonProps): JSX.Element => {
  const { id, working } = props;
  return (
    <Button
      onClick={(): void => clickSave(id, working)}
      variant="outlined"
      size="large"
      disabled={working}
    >
      Save
    </Button>
  );
};

export default FieldNoteSaveButton;

import { AllModelsJSONResponse } from "../types/json_types";
import { setModels, store } from "../constants/globals";
import Product from "../models/Product";
import sortBy from "lodash/sortBy";
import Upgrade from "../models/Upgrade";
import School from "../models/School";
import Building from "../models/Building";
import User from "../models/User";
import { groupById, groupByIdAttr } from "./app_util";
import Room from "../models/Room";
import Install from "../models/Install";
import { shorts } from "../constants/shorts";
import InstallAction from "../models/InstallAction";
import Warehouse from "../models/Warehouse";
import TruckLoad from "../models/TruckLoad";
import BonusBuck from "../models/BonusBuck";
import Payscale from "../models/Payscale";
import FieldNote from "../models/FieldNote";
import RoomLocking from "../models/RoomLocking";
import Ticket from "../models/Ticket";
import StripeCharge from "../models/StripeCharge";
import Reservation from "../models/Reservation";
import Paycheck from "../models/Paycheck";
import Vehicle from "../models/Vehicle";

const groupInstalls = (installs: Install[]): { [key: string]: Install[] } => {
  const obj: { [key: string]: Install[] } = {};

  for (let index = 0; index < installs.length; index++) {
    const i = installs[index];

    const skey = `student_${i.student_id}`;
    if (obj[skey]) {
      obj[skey].push(i);
    } else {
      obj[skey] = [i];
    }

    const rkey = `room_${i.room_id}`;
    if (obj[rkey]) {
      obj[rkey].push(i);
    } else {
      obj[rkey] = [i];
    }
  }

  return obj;
};

export const handleData = function (allJson: AllModelsJSONResponse): void {
  const d = Date.now();
  const account = allJson.accounts ? allJson.accounts[0] : null;

  if (account) {
    store.state.home_note = account.home_note;
  }

  setModels(
    "users",
    allJson.users.map((u) => new User(u)),
  );

  const schools = setModels(
    "schools",
    allJson.schools.map((s) => new School(s)),
  );

  const schoolsById = groupById(schools);

  setModels(
    "warehouses",
    allJson.warehouses.map((w) => new Warehouse(w)),
  );

  setModels(
    "vehicles",
    (allJson.vehicles || []).map((v) => new Vehicle(v)),
  );

  setModels(
    "upgrades",
    allJson.upgrades.map((u) => new Upgrade(u)),
  );

  const products = sortBy(
    allJson.products.map((p) => new Product(p)),
    (prod: Product) => {
      shorts.indexOf(prod.short);
    },
  );
  setModels("products", products);

  const groupedProducts = groupById(products);

  const groupedInvoices = groupByIdAttr(allJson.invoices || [], "reservation_id");

  const reservations = setModels(
    "reservations",
    allJson.reservations.map((r) => new Reservation(r, !!groupedInvoices[r.id])),
  );

  const groupedReservations = groupById(reservations);

  setModels(
    "stripe_charges",
    (allJson.stripe_charges || []).map((sc) => {
      const m = new StripeCharge(sc);
      const res = groupedReservations[m.reservation_id];
      if (res) {
        res.stripe_charges.push(m);
      }

      return m;
    }),
  );

  const buildings = allJson.buildings
    ? setModels(
        "buildings",
        allJson.buildings.map((b) => new Building(b, schoolsById[b.school_id])),
      )
    : [];

  const groupedBuildings = groupById(buildings);

  const rooms = setModels(
    "rooms",
    (allJson.rooms || []).map((r) => new Room(r, groupedBuildings[r.building_id])),
  );

  const groupedRooms = groupById(rooms);

  const install_actions = setModels(
    "install_actions",
    (allJson.install_actions || []).map((ia) => new InstallAction(ia)),
  );

  const groupedActions = groupByIdAttr(install_actions, "install_id");

  const installs = setModels(
    "installs",
    (allJson.installs || []).map(
      (i) =>
        new Install(
          i,
          groupedProducts[i.product_id],
          groupedRooms[i.room_id],
          groupedActions[i.id] || [],
        ),
    ),
  );

  const groupedInstalls = groupInstalls(installs);

  for (let index = 0; index < rooms.length; index++) {
    const room = rooms[index];
    room.installs = groupedInstalls[`room_${room.id}`] || [];
  }

  const installsById = groupById(installs);
  for (let index = 0; index < install_actions.length; index++) {
    const ia = install_actions[index];
    ia.install = installsById[ia.install_id];
  }

  setModels(
    "truck_loads",
    (allJson.truck_loads || []).map((tl) => new TruckLoad(tl)),
  );

  setModels(
    "bonus_bucks",
    (allJson.bonus_bucks || []).map((bb) => new BonusBuck(bb)),
  );

  setModels(
    "room_lockings",
    (allJson.room_lockings || []).map((rl) => new RoomLocking(rl)),
  );

  setModels(
    "payscales",
    (allJson.payscales || []).map((ps) => new Payscale(ps)),
  );

  setModels(
    "field_notes",
    (allJson.field_notes || []).map((n) => new FieldNote(n)),
  );

  setModels(
    "tickets",
    (allJson.tickets || []).map((t) => new Ticket(t)),
  );

  setModels(
    "paychecks",
    (allJson.paychecks || []).map((pc) => new Paycheck(pc)),
  );

  const d2 = Date.now();

  console.log("time: ", d2 - d);
};

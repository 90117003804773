import ApplicationModel from "./ApplicationModel";
import { TableName, ReservationJSON } from "../types/json_types";
import { ClassName } from "../types/types";
import { getDateFromDateStr } from "../util/util";
import { departureDateStrForSchool } from "../util/app_util";
import StripeCharge from "./StripeCharge";

type StudentDeets = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  vacancy: Date;
  full_name: string;
  special_instructions: string;
};

class Reservation extends ApplicationModel {
  student_id: number;
  room_id: number;
  student_first_name: string;
  student_last_name: string;
  student_email: string;
  student_phone: string;
  special_instructions: string;
  arrival_date_at: Date | null;
  departure_date: Date;
  has_unpaid_invoice: boolean;
  stripe_charges: StripeCharge[];

  constructor(json: ReservationJSON, has_unpaid_invoice: boolean) {
    super(json);

    this.student_first_name = json.student_first_name;
    this.student_last_name = json.student_last_name;
    this.student_email = json.student_email;
    this.student_phone = json.student_phone;
    this.student_id = json.student_id;
    this.room_id = json.room_id;
    this.special_instructions = json.special_instructions;
    this.arrival_date_at = json.arrival_date_at ? new Date(json.arrival_date_at + "Z") : null;
    this.has_unpaid_invoice = has_unpaid_invoice;
    this.departure_date =
      json.departure_date == null
        ? getDateFromDateStr(departureDateStrForSchool(json.school_id))
        : getDateFromDateStr(json.departure_date);

    this.stripe_charges = [];
  }

  getClassName(): ClassName {
    const className: ClassName = "Reservation";
    return className;
  }

  static getTableName(): TableName {
    return "reservations";
  }

  leavingBefore(d: Date): boolean {
    return this.departure_date < d;
  }

  studentDeets(): StudentDeets {
    return {
      first_name: this.student_first_name,
      last_name: this.student_last_name,
      email: this.student_email,
      phone: this.student_phone,
      vacancy: this.departure_date,
      full_name: this.fullName(),
      special_instructions: this.special_instructions,
    };
  }

  fullName(): string {
    return `${this.student_first_name} ${this.student_last_name}`;
  }

  arrivingBefore(date: Date | null): boolean {
    if (!this.arrival_date_at || !date) {
      return false;
    }

    const filter_month = date.getMonth() + 1;
    const filter_day = date.getDate();

    const arrival_month = this.arrival_date_at.getMonth() + 1;
    const arrival_day = this.arrival_date_at.getDate();

    return (
      arrival_month < filter_month || (arrival_month <= filter_month && arrival_day < filter_day)
    );
  }

  getLatestPaymentDate(): Date | null {
    return (
      this.stripe_charges
        .map((sc) => sc.created_at)
        .sort()
        .reverse()[0] || null
    );
  }
}

export default Reservation;

import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import sortBy from "lodash/sortBy";
import Vehicle from "../../models/Vehicle";
import { getModels } from "../../constants/globals";

export const renderVehicle = (v: Vehicle, label: string): JSX.Element => {
  const key = `vehicle-${v.id}-option-${label}`;

  return (
    <option key={key} value={v.id}>
      {v.name}
    </option>
  );
};

export interface VehicleIdSelectNullProps {
  vehicle_id: number | null;
  onChange: (vehicle_id: number | null) => void;
  nullLabel: string;
  label: string;
}

export default function VehicleIdSelectNull(props: VehicleIdSelectNullProps): JSX.Element {
  const { vehicle_id, onChange, nullLabel, label } = props;

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    const val = e.target.value as string;
    const vehicle_id = val === "null" ? null : parseInt(val);

    onChange(vehicle_id);
  };

  const vehicles = sortBy(getModels("vehicles"), (w) => w.name);

  const val = vehicle_id || "";

  return (
    <div>
      <FormControl style={{ minWidth: 235 }}>
        <InputLabel>{label}</InputLabel>
        <Select
          native
          value={val}
          onChange={handleChange}
          inputProps={{
            name: "vehicle_id",
          }}
        >
          <option>{nullLabel}</option>

          {vehicles.map((w) => renderVehicle(w, label))}
        </Select>
      </FormControl>
    </div>
  );
}

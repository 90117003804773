/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import Button from "@material-ui/core/Button";
import React from "react";
import { getModels, store } from "../../constants/globals";
import BonusBuck from "../../models/BonusBuck";
import { BonusBuckJSON, SentBonusBuckJSON, isErrorResponse } from "../../types/json_types";
import { findModelById } from "../../util/app_util";

const { getNonNullState, setPartialState } = store.getScopedHelpers("bonus_buck_modal");

type BonusBuckResp = {
  bonus_buck: BonusBuckJSON;
};

function clickSave(id: number): void {
  const state = getNonNullState();

  if (state.working) {
    return;
  }

  setPartialState({ working: true });

  const { image_file_name } = state;

  if (id == null) {
    throw new Error("BB id should be naw");
  }

  const data: SentBonusBuckJSON = {
    worker_ids: state.worker_ids,
    reason: state.reason,
    confirmed: state.confirmed,
    dollars: state.dollars,
    performed_at: state.performed_at,
  };

  const formData = new FormData();

  const fileInput = document.querySelector("input#bbimage") as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append("image", fileInput.files[0]!);
  }

  formData.append("json", JSON.stringify(data));
  formData.append("image_file_name", image_file_name || "null");

  setPartialState({ working: true });
  fetch(`/worker_api/bonus_bucks/${id}`, {
    method: "PUT",
    body: formData,
  })
    .then((response) => response.json())
    .then((resp: unknown): void => {
      if (isErrorResponse(resp)) {
        setPartialState({ errors: resp.errors, working: false });
      } else {
        const allBonusBucks = getModels("bonus_bucks");
        const bonus_buck = findModelById(allBonusBucks, id);

        const json = resp as BonusBuckResp;

        const nextBonusBuck = new BonusBuck(json.bonus_buck);

        const index = allBonusBucks.indexOf(bonus_buck);
        allBonusBucks[index] = nextBonusBuck;

        store.setState("bonus_buck_modal", undefined);
        setTimeout(() => {
          store.forceUpdate("viewShown");
        });
      }
    })
    .catch(() => {
      alert("an error occurred");
      setPartialState({ working: false });
    });
}

function clickSubmit(): void {
  const state = getNonNullState();

  if (state.working) {
    return;
  }

  setPartialState({ working: true });

  const formData = new FormData();

  const fileInput = document.querySelector("input#bbimage") as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append("image", fileInput.files[0]!);
  }

  const data: SentBonusBuckJSON = {
    reason: state.reason,
    dollars: state.dollars,
    confirmed: state.confirmed,
    worker_ids: state.worker_ids,
    performed_at: state.performed_at,
  };

  formData.append("season", store.state.season);
  formData.append("json", JSON.stringify(data));

  setPartialState({ working: true });
  fetch("/worker_api/bonus_bucks.json", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((resp: unknown) => {
      if (isErrorResponse(resp)) {
        setPartialState({ errors: resp.errors, working: false });
      } else {
        const json = resp as BonusBuckResp;
        const bonus_buck = new BonusBuck(json.bonus_buck);
        getModels("bonus_bucks").unshift(bonus_buck);

        store.setState("bonus_buck_modal", undefined);
        setTimeout(() => {
          store.forceUpdate("viewShown");
        });
      }
    })
    .catch(() => {
      alert("an error occurred");
      setPartialState({ working: false });
    });
}

interface BonusBuckSaveButtonProps {
  id: number | null;
  working: boolean;
}

const BonusBuckSaveButton = (props: BonusBuckSaveButtonProps): JSX.Element => {
  const { id, working } = props;

  return (
    <Button
      onClick={(): void => (id == null ? clickSubmit() : clickSave(id))}
      className="next-button"
      variant="outlined"
      size="large"
      disabled={working}
    >
      Save
    </Button>
  );
};

export default BonusBuckSaveButton;

import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PaycheckModalDestroyButton from "./PaycheckDestroyButton";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import CloseableErrorAlert from "../util/CloseableErrorAlert";
import { PaycheckModalState } from "../../types/state_types";
import AppDatePicker from "../selects/AppDatePicker";
import PaycheckSaveButton from "./PaycheckSaveButton";
import { store, getModels } from "../../constants/globals";
import UserIdSelectNull from "../selects/UserIdSelectNull";

const { setPartialState, useStoreState, setState } = store.getScopedHelpers("paycheck_modal");

function getModalStyle(): CSSProperties {
  return {
    zIndex: 99999,
    top: "0",
    left: "0",
    outline: "0",
    width: "100%",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      padding: 5,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

const handleClose = (): void => {
  setState(undefined);
};

const onChangePayee = (user_id: number | null): void => {
  if (!user_id) {
    user_id = getModels("users")[0].id;
  }

  setPartialState({ payee_id: user_id });
};

const onChangeDate = (date: Date | null): void => {
  setPartialState({ date_at: date || new Date() });
};

const onChange = <Key extends keyof PaycheckModalState>(
  attr: Key,
  value: PaycheckModalState[Key],
): void => {
  setPartialState({ [attr]: value });
};

const closeErrorAlert = (): void => {
  setPartialState({ errors: [] });
};

const PaycheckModal = (): JSX.Element | null => {
  const classes = useStyles();

  const modalStyle = getModalStyle();

  const state = useStoreState();

  if (!state) {
    return null;
  }

  return (
    <div>
      <Modal open={true} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Add Paycheck
          </Typography>

          <Divider className="mb-2" />

          <Box flexGrow="1" style={{ overflow: "scroll" }}>
            <CloseableErrorAlert
              errors={state.errors}
              closeAlert={closeErrorAlert}
              className="mt-2 mb-3"
            />

            <UserIdSelectNull
              label="Set payee"
              nullLabel="Select payee"
              user_id={state.payee_id}
              onChange={onChangePayee}
            />

            <div className="mt-3">
              <AppDatePicker
                label="Date received"
                date={state.date_at}
                onChangeDate={onChangeDate}
              />
            </div>

            <div className="mt-3">
              <TextField
                label="Amount"
                type="number"
                name="amount"
                placeholder="100.00"
                value={state.amount || ""}
                onChange={(e): void => onChange("amount", parseFloat(e.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {state.type === "check" ? (
              <div className="mt-3">
                <TextField
                  label="Check number"
                  type="textarea"
                  name="check_number"
                  onChange={(e): void => onChange("check_number", e.target.value)}
                  value={state.check_number || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            ) : null}

            <FormControl variant="outlined" className="mt-4">
              <InputLabel>Type</InputLabel>
              <Select
                value={state.type}
                onChange={(e): void => onChange("type", e.target.value as string)}
                label="Type"
              >
                <MenuItem value="check">Check</MenuItem>
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="electronic">Electronic</MenuItem>
              </Select>
            </FormControl>

            <div className="my-3">
              <TextField
                style={{ width: "100%" }}
                className="mt-2"
                label="Notes"
                multiline
                rows="3"
                value={state.notes || ""}
                onChange={(e): void => onChange("notes", e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Box>

          <Divider className="mb-2" />

          <Box display="flex" justifyContent="space-between">
            <Button onClick={handleClose} variant="outlined" size="large" disabled={state.working}>
              Cancel
            </Button>

            {state.id ? (
              <PaycheckModalDestroyButton paycheck_id={state.id} working={state.working} />
            ) : null}

            <PaycheckSaveButton id={state.id} working={state.working} />
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default PaycheckModal;

import React from "react";
import MuiDrawer from "@material-ui/core/Drawer";
import BuildIcon from "@material-ui/icons/Build";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import MoneyIcon from "@material-ui/icons/Money";
import BarChartIcon from "@material-ui/icons/BarChart";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import WarningIcon from "@material-ui/icons/Warning";
import SearchIcon from "@material-ui/icons/Search";
import SettingsBrightnessIcon from "@material-ui/icons/SettingsBrightness";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import List from "@material-ui/core/List";
import { store, router } from "../constants/globals";
import { ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PeopleIcon from "@material-ui/icons/People";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import { ApiRequest } from "../libs/ApiRequest";
import PiggyBankIcon from "./icons/PiggyBankIcon";
import { toggleDarkmode } from "../util/app_util";

const { setState: setDrawerOpen, useStoreState: useDrawerOpen } = store.getScopedHelpers(
  "drawerOpen",
);

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

const closeDrawer = (): void => {
  setDrawerOpen(false);
};

const go = (route: string) => (): void => {
  router.go(route);
};

let working = false;

function logout(e: React.MouseEvent<HTMLElement>): void {
  e.preventDefault();

  if (working) {
    return;
  }

  working = true;
  ApiRequest.perform({
    url: "/users/sign_out.json",
    method: "DELETE",
    onSuccess: (): void => {
      store.setState("currentUser", null);
    },
    onError: () => {
      alert("couldn't log out: an error occurred");
    },
    onDone: () => {
      working = false;
    },
  });
}

const clickSearch = (): void => {
  const resp = window.prompt("search by student name");

  if (resp != null && resp.trim() !== "") {
    const path = `/jobs/search/${encodeURIComponent(resp)}`;
    window.resetList();
    router.go(path);
  }
};

export default function Drawer(): JSX.Element | null {
  const classes = useStyles();

  const open = useDrawerOpen();
  const currentUser = store.state.currentUser;

  if (!currentUser) {
    return null;
  }

  return (
    <MuiDrawer open={open} onClose={closeDrawer}>
      <div
        className={classes.list}
        role="presentation"
        onClick={closeDrawer}
        onKeyDown={closeDrawer}
      >
        <List>
          <ListItem button key={"home"} onClick={go("/")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>

          <ListItem button key={"jobs"} onClick={go("/jobs")}>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary={"Jobs"} />
          </ListItem>

          <ListItem button key={"piggy_bank"} onClick={go("/your_work")}>
            <ListItemIcon>
              <PiggyBankIcon />
            </ListItemIcon>
            <ListItemText primary={"Piggy Bank"} />
          </ListItem>

          <ListItem button key={"search"} onClick={clickSearch}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={"Search"} />
          </ListItem>

          {currentUser.admin && (
            <ListItem button key={"workers"} onClick={go("/workers")}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={"Workers"} />
            </ListItem>
          )}

          <ListItem button key={"truck_loads"} onClick={go("/truck_loads")}>
            <ListItemIcon>
              <AirportShuttleIcon />
            </ListItemIcon>
            <ListItemText primary={"Truck Loads"} />
          </ListItem>

          <ListItem button key={"needs_attention"} onClick={go("/needs_attention_tickets")}>
            <ListItemIcon>
              <WarningIcon />
            </ListItemIcon>
            <ListItemText primary={"Needs Attention"} />
          </ListItem>

          <ListItem button key={"bonus_bucks"} onClick={go("/bonus_bucks")}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary={"Bonus Bucks"} />
          </ListItem>

          <ListItem button key={"profile"} onClick={go("/profile")}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={"Profile"} />
          </ListItem>

          {currentUser.admin && (
            <ListItem button key={"payscales"} onClick={go("/payscales")}>
              <ListItemIcon>
                <MoneyIcon />
              </ListItemIcon>
              <ListItemText primary={"Payscales"} />
            </ListItem>
          )}

          <ListItem button key={"stats"} onClick={go("/stats")}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary={"Stats"} />
          </ListItem>

          <ListItem button key={"info_icon"} onClick={go("/cheatsheet")}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary={"Cheat Sheet"} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key={"darkmode_toggle"} onClick={toggleDarkmode}>
            <ListItemIcon>
              <SettingsBrightnessIcon />
            </ListItemIcon>
            <ListItemText primary={"Toggle dark/light mode"} />
          </ListItem>
        </List>

        <Divider />
        <Divider />

        <List>
          <ListItem button key="logout" onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </MuiDrawer>
  );
}

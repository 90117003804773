import ApplicationModel from "./ApplicationModel";
import { BuildingJSON, TableName } from "../types/json_types";
import { ClassName } from "../types/types";
import School from "./School";
import { filterModelsByAttr } from "../util/app_util";
import { getModels } from "../constants/globals";
import Ticket from "./Ticket";

class Building extends ApplicationModel {
  name: string;
  shown_name: string;
  notes: string | null;
  gmaps_link: string | null;
  school_id: number;
  school: School;

  constructor(json: BuildingJSON, school: School) {
    super(json);

    this.school_id = json.school_id;
    this.school = school;
    this.name = json.name;
    this.gmaps_link = json.gmaps_link;
    this.notes = json.notes;
    this.shown_name = json.shown_name;
  }

  getClassName(): ClassName {
    const className: ClassName = "Building";
    return className;
  }

  getUnresolvedTickets(): Ticket[] {
    const room_ids = filterModelsByAttr(
      getModels("rooms"),
      "building_id",
      this.id
    ).map((r) => r.id);

    return getModels("tickets").filter(
      (t) => !t.resolved_at && room_ids.includes(t.room_id)
    );
  }

  static getTableName(): TableName {
    return "buildings";
  }
}

export default Building;

import ApplicationModel from "./ApplicationModel";
import Room from "./Room";
import User from "./User";
import { ClassName } from "../types/types";
import strftime from "strftime";
import { RoomLockingJSON } from "../types/json_types";
import { getModels } from "../constants/globals";
import { findModelById } from "../util/app_util";
import { Activity } from "./InstallAction";

class RoomLocking extends ApplicationModel {
  locked: boolean | null;
  room_id: number;
  room: Room;
  set_by_id: number;
  set_by: User;

  constructor(json: RoomLockingJSON) {
    super(json);

    this.locked = json.locked;
    this.room_id = json.room_id;

    const rooms = getModels("rooms");
    this.room = findModelById(rooms, this.room_id);

    this.set_by_id = json.set_by_id;
    const users = getModels("users");
    this.set_by = findModelById(users, this.set_by_id);
  }

  getClassName(): ClassName {
    const className: ClassName = "RoomLocking";
    return className;
  }

  toActivity(): Activity {
    return {
      id: this.id,
      txt: this.getText(),
      room_id: this.room_id,
      creator: this.set_by,
      created_at: this.created_at,
      key: `room_locking_${this.id}_activity`,
    };
  }

  getText(): string {
    const creatorName = this.set_by.username;
    const dateStr = `on ${strftime("%b %e %Y @ %l:%M %p", this.created_at)}`;

    let lockedTxt: string;
    switch (this.locked) {
      case true:
        lockedTxt = "room to locked";
        break;
      case false:
        lockedTxt = "room to unlocked";
        break;
      case null:
        lockedTxt = "locked status to unknown";
        break;
    }

    return `${creatorName} set ${lockedTxt} on ${dateStr}`;
  }
}

export default RoomLocking;

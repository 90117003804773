import React from "react";
import sortBy from "lodash/sortBy";
import PhoneIcon from "@material-ui/icons/Phone";
import Link from "@material-ui/core/Link";

import WorkerTabs from "./WorkerTabs";
import groupBy from "lodash/groupBy";
import { store, router, getModels } from "../../constants/globals";
import User from "../../models/User";
import { workerName } from "../../helpers/user_helpers";
import { getActiveUsers } from "../../util/app_util";

const clickRow = (user: User): void => {
  if (store.getNonNullState("currentUser").admin) {
    router.go(`/workers/${user.id}`);
  }
};

const renderWorker = (user: User): JSX.Element => {
  const key = "workuh_row" + user.id;

  return (
    <div
      key={key}
      style={{ height: 50 }}
      onClick={(): void => {
        clickRow(user);
      }}
      className="pl-2 d-flex align-items-center justify-content-between list-group-item"
    >
      <div className="d-flex">{workerName(user)}</div>

      <Link href={`tel:${user.phone}`} className="mx-3">
        <PhoneIcon />
      </Link>
    </div>
  );
};

const WorkersPage = (): JSX.Element => {
  const { worker_tab } = store.useNonNullState("workers_page");
  const currentUser = store.getNonNullState("currentUser");

  if (currentUser.admin) {
    const groupedWorkers = groupBy(getModels("users"), (u) =>
      u.active ? "active" : "inactive"
    );

    let workers: User[];

    const active_workers_count = groupedWorkers.active.length;
    const inactive_workers_count = (groupedWorkers.inactive || []).length;

    const props = { worker_tab, active_workers_count, inactive_workers_count };

    if (worker_tab === 0) {
      workers = groupedWorkers.active;
    } else {
      workers = groupedWorkers.inactive;
    }

    workers = sortBy(workers, (w) => w.username.toLowerCase());

    return (
      <div className="fit">
        <WorkerTabs {...props} />
        {workers.map(renderWorker)}
      </div>
    );
  } else {
    const workers = sortBy(getActiveUsers(), (w) => w.username.toLowerCase());

    return <div className="fit">{workers.map(renderWorker)}</div>;
  }
};

export default WorkersPage;

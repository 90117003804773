import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { FieldNoteJSON } from "../../types/json_types";
import FieldNote from "../../models/FieldNote";
import FieldNoteDestroyButton from "./FieldNoteDestroyButton";
import FieldNoteSaveButton from "./FieldNoteSaveButton";
import FieldNoteModalPhotoRow from "./FieldNoteModalPhotoRow";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { store, getModels } from "../../constants/globals";

const { setState, setPartialState, useStoreState } = store.getScopedHelpers("field_note_modal");

function getModalStyle(): CSSProperties {
  const top = 50;

  return {
    top: `${top}%`,
    transform: `translateY(-${top}%)`,
    outline: "0",
    width: "100%",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

export type FieldNoteJsonResp = {
  field_note: FieldNoteJSON;
};

function clickSubmit(note: string, working: boolean): void {
  if (working) {
    return;
  }

  const room = store.getNonNullState("room");

  const formData = new FormData();

  const fileInput = document.querySelector("input#image") as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append("image", fileInput.files[0]);
  }

  formData.append("note", note);
  formData.append("room_id", room.id.toString());

  setPartialState({ working: true });
  fetch("/worker_api/field_notes.json", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((resp: unknown) => {
      const json = resp as FieldNoteJsonResp;
      const field_note = new FieldNote(json.field_note);
      getModels("field_notes").unshift(field_note);
      const appState = store.state;

      appState.field_note_modal = undefined;

      store.forceUpdate("viewShown");
    })
    .catch(() => {
      alert("an error occurred");
      setPartialState({ working: false });
    });
}

const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  setPartialState({ note: event.target.value });
};

const handleClose = (): void => {
  setState(undefined);
};

const renderPhotoRow = (
  image_file_name: string | null,
  id: number | null,
  working: boolean,
): JSX.Element => {
  if (image_file_name && id) {
    return <FieldNoteModalPhotoRow {...{ image_file_name, id, working }} />;
  } else {
    return (
      <div style={{ position: "relative" }} className="mt-3">
        <label
          htmlFor="image"
          style={{ position: "absolute", left: 0, top: -3 }}
          className="file-cover-btn MuiButton-root MuiButton-outlined MuiButtonBase-root MuiButton-outlinedSizeSmall"
        >
          Attach a photo
        </label>

        <input
          type="file"
          id="image"
          name="image"
          accept="image/png, image/jpeg"
          style={{ marginLeft: 47, outline: "none" }}
        />
      </div>
    );
  }
};
const FieldNoteModal = (): JSX.Element | null => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();
  if (!state) {
    return null;
  }
  const { id, working, note, image_file_name } = state;

  const title = id ? "Edit field note" : "Add a field note";

  return (
    <Modal open={true} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography variant="h6" className="d-flex">
            {title}
          </Typography>

          <TextField
            style={{ width: "100%" }}
            className="mt-2"
            id="outlined-multiline-static"
            multiline
            rows="4"
            value={note}
            onChange={onChange}
            variant="outlined"
          />

          {renderPhotoRow(image_file_name, id, working)}
        </div>
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={working}>
            Cancel
          </Button>

          {id ? <FieldNoteDestroyButton field_note_id={id} working={working} /> : null}

          {id ? (
            <FieldNoteSaveButton id={id} working={working} />
          ) : (
            <Button
              onClick={(): void => clickSubmit(note, working)}
              variant="outlined"
              disabled={working}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FieldNoteModal;

import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { store, getModels } from "../../constants/globals";
import { FilterModalState } from "../../types/state_types";
import Filter, { FilterJSON } from "../../models/Filter";
import { Box, FormControlLabel, Checkbox, Divider } from "@material-ui/core";
import SchoolIdSelect from "../selects/SchoolIdSelect";
import BuildingIdsSelect from "../selects/BuildingIdsSelect";
import { findModelById } from "../../util/app_util";
import { andJoin, getDateStr } from "../../util/util";
import ProductIdsSelect from "../selects/ProductIdsSelect";
import UpgradeIdsSelect from "../selects/UpgradeIdsSelect";
import AppDatePicker from "../selects/AppDatePicker";
import { InstallStatus, install_statuses } from "../../models/Install";
import MultiGenericStringSelect from "../selects/MultiGenericStringSelect";
import LockedFilterSelect from "../selects/LockedFilterSelect";
import GenericStringSelect from "../selects/GenericStringSelect";
import { JobSearchType, jobSearchTypes } from "../../types/types";

const { getNonNullState, setState, setPartialState, useStoreState } = store.getScopedHelpers(
  "filter_modal",
);

function getModalStyle(): CSSProperties {
  return {
    zIndex: 99999,
    top: "0",
    left: "0",
    outline: "0",
    width: "100%",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      padding: 5,
    },
  }),
);

const handleReset = (): void => {
  const state: FilterModalState = Filter.emptyModalState();

  const school_id = getNonNullState().school_id;
  state.school_id = school_id || getModels("schools")[0].id;

  setState(state);
};

const handleClose = (): void => {
  setState(undefined);
};

const clickSave = (): void => {
  const state = getNonNullState();

  if (!state.school_id) {
    throw new Error(`school_id should be present here`);
  }

  const json: FilterJSON = {
    install_statuses: state.install_statuses,
    locked: state.locked,
    building_ids: state.building_ids,
    school_id: state.school_id,
    has_special_instructions: state.has_special_instructions,
    has_unresolved_tickets: state.has_unresolved_tickets,
    has_resolved_tickets: state.has_resolved_tickets,
    no_upgrades: state.no_upgrades,
    occupied: state.occupied,
    jobSearchType: state.jobSearchType,
    only_available_jobs: state.only_available_jobs,
    leaving_before: state.leaving_before == null ? null : getDateStr(state.leaving_before),
    product_ids: state.product_ids,
    upgrade_ids: state.upgrade_ids,
    arrival_date: state.arrival_date?.toString(),
  };

  const filter = new Filter(json);

  filter.saveToLocalStorage();

  window.App.filter = filter;

  window.resetList();
  store.setState("filter_modal", undefined);
  setTimeout(() => {
    store.forceUpdate("viewShown");
  });
};

const onChangeSchool = (school_id: number): void => {
  setPartialState({ school_id });
};

const onChangeBuildingIds = (building_ids: number[]): void => {
  setPartialState({ building_ids });
};

const renderBuildings = (school_id: number, building_ids: number[]): JSX.Element => {
  const buildingNames = andJoin(
    building_ids.map((bid) => findModelById(getModels("buildings"), bid).shown_name),
  );
  return (
    <Box className="mt-3" display="flex" justifyContent="space-between">
      <BuildingIdsSelect
        label="filter by building(s)"
        school_id={school_id}
        building_ids={building_ids}
        onChange={onChangeBuildingIds}
      />

      {building_ids.length > 0 && <div style={{ maxWidth: "50%" }}>{buildingNames}</div>}
    </Box>
  );
};

const FilterModal = (): JSX.Element | null => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();

  if (!state) {
    return null;
  }

  const school_id = state.school_id || getModels("schools")[0].id;

  return (
    <Modal open={true} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <Typography style={{ textAlign: "center" }} variant="h6" gutterBottom>
          Set Your Filter
        </Typography>
        <Divider />
        <Box flexGrow="1" style={{ overflow: "scroll" }} className="pb-4 pt-3">
          <SchoolIdSelect school_id={school_id} onChange={onChangeSchool} />

          <Box className="mt-2">
            <GenericStringSelect<JobSearchType>
              label="sort rooms by..."
              options={jobSearchTypes}
              value={state.jobSearchType}
              onChange={(v): void => setPartialState({ jobSearchType: v })}
            />
          </Box>

          <Box className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.occupied}
                  onChange={(e): void => {
                    setPartialState({
                      occupied: e.target.checked,
                    });
                  }}
                  color="primary"
                />
              }
              label="Occupied"
            />
          </Box>

          <Box className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.only_available_jobs}
                  onChange={(e): void => {
                    setPartialState({
                      only_available_jobs: e.target.checked,
                    });
                  }}
                  color="primary"
                />
              }
              label="Only show available jobs"
            />
          </Box>

          {school_id ? renderBuildings(school_id, state.building_ids) : null}

          <Box
            className="mt-3 ml-1"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ProductIdsSelect
              label="Products"
              product_ids={state.product_ids}
              onChange={(product_ids: number[]): void => setPartialState({ product_ids })}
            />

            {state.product_ids.length > 0 && (
              <div style={{ maxWidth: "50%" }}>
                {andJoin(
                  state.product_ids.map(
                    (pid: number): string => findModelById(getModels("products"), pid).name,
                  ),
                  "OR",
                )}
              </div>
            )}
          </Box>

          <Box className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.no_upgrades}
                  onChange={(e): void => {
                    setPartialState({
                      no_upgrades: e.target.checked,
                    });
                  }}
                  color="primary"
                />
              }
              label="No upgrades"
            />
          </Box>

          <Box className="mt-3" display="flex" justifyContent="space-between" alignItems="center">
            <MultiGenericStringSelect<InstallStatus>
              label="Item Status"
              onChange={(install_statuses): void => setPartialState({ install_statuses })}
              options={install_statuses}
              value={state.install_statuses}
            />

            <div style={{ maxWidth: "50%" }}>{andJoin(state.install_statuses)}</div>
          </Box>

          <Box
            className="mt-3 ml-1"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <UpgradeIdsSelect
              label="Upgrades"
              upgrade_ids={state.upgrade_ids}
              onChange={(upgrade_ids: number[]): void => setPartialState({ upgrade_ids })}
            />

            {state.upgrade_ids.length > 0 && (
              <div style={{ maxWidth: "50%" }}>
                {andJoin(
                  state.upgrade_ids.map((pid: number): string =>
                    findModelById(getModels("upgrades"), pid).labelName(),
                  ),
                  "OR",
                )}
              </div>
            )}
          </Box>
          <Box className="mt-3">
            <AppDatePicker
              date={state.arrival_date}
              onChangeDate={(d): void => {
                setPartialState({ arrival_date: d });
              }}
              label={`Arriving before`}
            />
          </Box>

          <FormControlLabel
            className="mt-3"
            control={
              <Checkbox
                checked={state.has_unresolved_tickets}
                onChange={(e): void => {
                  setPartialState({
                    has_unresolved_tickets: e.target.checked,
                  });
                }}
                color="primary"
              />
            }
            label="Has unresolved tickets"
          />

          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={state.has_resolved_tickets}
                onChange={(e): void => {
                  setPartialState({
                    has_resolved_tickets: e.target.checked,
                  });
                }}
                color="primary"
              />
            }
            label="Has resolved tickets"
          />

          <Box className="mt-3">
            <AppDatePicker
              date={state.leaving_before}
              onChangeDate={(d): void => {
                setPartialState({ leaving_before: d });
              }}
              label={`Leaving before`}
            />
          </Box>

          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={state.has_special_instructions}
                onChange={(e): void => {
                  setPartialState({
                    has_special_instructions: e.target.checked,
                  });
                }}
                color="primary"
              />
            }
            label="Has special instructions"
          />

          <Box className="mt-3">
            <LockedFilterSelect
              locked={state.locked}
              onChange={(locked): void => {
                setPartialState({ locked });
              }}
            />
          </Box>
        </Box>

        <Divider />
        <Box display="flex" justifyContent="space-between">
          <Button onClick={handleClose} variant="outlined" size="large">
            Cancel
          </Button>

          <Button onClick={handleReset} variant="outlined" size="large">
            Reset
          </Button>

          <Button onClick={clickSave} variant="outlined" size="large">
            Save
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default FilterModal;

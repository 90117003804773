import { store } from "../constants/globals";

/* eslint-disable @typescript-eslint/no-explicit-any */
const makeRequest = async function (
  url: string,
  method: HTTPMethod,
  payload?: any,
): Promise<{
  response: Response;
  json: unknown;
}> {
  const options: RequestInit = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
    credentials: "same-origin",
  };

  if (payload != null) {
    options.body = JSON.stringify(payload);
  }

  const response = await fetch(url, options);

  let json: unknown;
  if (response.status === 204) {
    json = {};
  } else {
    try {
      json = await response.json();
    } catch {
      json = {};
    }
  }

  return {
    response: response,
    json: json,
  };
};

export type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

enum Status {
  Idle,
  Loading,
  Success,
  Error,
}

type ApiRequestProps = {
  url: string;
  method: HTTPMethod;
  data?: any;
  onLoading?: () => void;
  onSuccess: (json: any) => void;
  onDone?: () => void;
  onError: (json: any) => void;
};

export class ApiRequest {
  url: string;
  method: HTTPMethod;
  http_status?: number;
  data?: any;
  success?: boolean;
  error?: boolean;
  status: Status;
  onLoading?: () => void;
  onSuccess: (json: any) => void;
  onDone?: () => void;
  onError: (json: any) => void;

  constructor(props: ApiRequestProps) {
    this.url = props.url;
    this.status = Status.Idle;
    this.method = props.method;
    this.onLoading = props.onLoading;
    this.onSuccess = props.onSuccess;
    this.onDone = props.onDone;
    this.onError = props.onError;
    this.data = props.data;
    if (this.data && this.method === "POST") {
      this.data.season = store.getState("season");
    }

    this.http_status = undefined;
  }

  isLoading(): boolean {
    return this.status === Status.Loading;
  }

  async perform(): Promise<void> {
    this.status = Status.Loading;

    if (this.onLoading) {
      this.onLoading();
    }

    const result = await makeRequest(this.url, this.method, this.data);

    const { json, response } = result;

    this.status = response.status;

    if (this.status >= 200 && this.status < 300) {
      this.status = Status.Success;

      this.onSuccess(json);
    } else if (this.status >= 400 && this.status < 600) {
      this.status = Status.Error;

      this.onError(json);
    }

    if (this.onDone) {
      this.onDone();
    }
  }

  static perform(props: ApiRequestProps): ApiRequest {
    const req = new ApiRequest(props);
    req.perform();
    return req;
  }

  static get(props: Omit<ApiRequestProps, "method">): ApiRequest {
    return ApiRequest.perform({ ...props, method: "GET" });
  }

  static post(props: Omit<ApiRequestProps, "method">): ApiRequest {
    return ApiRequest.perform({ ...props, method: "POST" });
  }
}

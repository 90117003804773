import School from "../../models/School";
import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { findModelById } from "../../util/app_util";
import { getModels } from "../../constants/globals";

const renderOption = (school: School): JSX.Element => {
  const key = `school_${school.id}_option`;

  return (
    <option value={school.id} key={key}>
      {school.name}
    </option>
  );
};

type SchoolSelectProps = {
  onChange: (school: School) => void;
  school: School;
};

const SchoolSelect = (props: SchoolSelectProps): JSX.Element => {
  const id = `school-select`;
  const schools = getModels("schools");
  const school = props.school;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>): void => {
    const value = e.target.value as string;

    props.onChange(findModelById(schools, parseInt(value)));
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>Select School</InputLabel>
      <Select
        id={id}
        native
        value={school.id}
        onChange={handleChange}
        label="Select School"
      >
        {schools.map(renderOption)}
      </Select>
    </FormControl>
  );
};

export default SchoolSelect;

import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Ticket from "../../models/Ticket";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { store, getModels } from "../../constants/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { findModelById } from "../../util/app_util";
import { TicketRespJSON } from "../../types/json_types";
import SchoolSelect from "../selects/SchoolSelect";

const { setPartialState, getNonNullState, setState, useStoreState } = store.getScopedHelpers(
  "new_ticket_modal",
);

function getModalStyle(): CSSProperties {
  const top = 50;

  return {
    top: `${top}%`,
    left: 0,
    right: 0,
    transform: `translateY(-${top}%)`,
    outline: 0,
    width: "100%",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

function clickSubmit(): void {
  const { school_id, text, working } = getNonNullState();
  if (working) {
    return;
  }

  setPartialState({ working: true });

  const room = getModels("rooms").find((r) => r.school_id === school_id && /^AAA/.test(r.number))!;

  const data = {
    room_id: room.id,
    text,
  };

  ApiRequest.perform({
    url: "/worker_api/tickets.json",
    method: "POST",
    onSuccess: (resp: unknown): void => {
      const json = resp as TicketRespJSON;
      const ticket = new Ticket(json.ticket);
      getModels("tickets").unshift(ticket);
      // const appState = store.state;

      const room = findModelById(getModels("rooms"), json.room.id);

      room.tickets_count = json.room.tickets_count;
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;

      store.forceUpdate("viewShown");
      setTimeout(() => {
        store.setState("new_ticket_modal", undefined);
      });
    },
    onError: (): void => {
      alert("an error occurred....  or did it?  yes it did");
      setPartialState({ working: false });
    },

    data,
  });
}

const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  setPartialState({ text: event.target.value });
};

const handleClose = (): void => {
  setState(undefined);
};

const NewTicketModal = (): JSX.Element | null => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();

  if (!state) {
    return null;
  }

  const { school_id, working, text } = state;

  const title = "NA Ticket for school AAA room";

  const school = findModelById(getModels("schools"), school_id);

  return (
    <Modal open={true} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography className="d-flex text-align-center mb-2">{title}</Typography>
          <div>
            <SchoolSelect
              school={school}
              onChange={(s): void => setPartialState({ school_id: s.id })}
            />
          </div>

          <TextField
            style={{ width: "100%" }}
            className="mt-2"
            id="outlined-multiline-static"
            multiline
            rows="4"
            value={text}
            onChange={onChange}
            variant="outlined"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={working}>
            Cancel
          </Button>

          <Button onClick={clickSubmit} variant="outlined" disabled={working}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewTicketModal;

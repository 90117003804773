import React from "react";
import TextField from "@material-ui/core/TextField";

type Props<T extends string> = {
  options: T[];
  value: T;
  onChange: (value: T) => void;
  label: string;
};

function GenericStringSelect<T extends string>(props: Props<T>): JSX.Element {
  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const val = e.target.value as string;

    props.onChange(val as T);
  }

  return (
    <TextField
      variant="outlined"
      SelectProps={{
        native: true,
      }}
      select
      label={props.label}
      value={props.value}
      onChange={handleOnChange}
    >
      {props.options.map((option) => (
        <option key={`generic_select_${option}`} value={option}>
          {option}
        </option>
      ))}
    </TextField>
  );
}

export default GenericStringSelect;

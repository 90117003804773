import React from "react";
import TextField from "@material-ui/core/TextField";
import { router } from "../../constants/globals";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import { ReactFormEvent } from "../../types/types";

const clickCancel = (): void => {
  window.resetList();
  router.go("/jobs");
};

const SearchField = ({ search }: { search: string }): JSX.Element => {
  const [txt, setTxt] = React.useState(search);

  const submitSearch = (e: ReactFormEvent): void => {
    e.preventDefault();
    window.resetList();
    router.go(`/jobs?search=${txt}`);
  };

  return (
    <div
      className="my-2 d-flex justify-content-center align-items-center Search"
      style={{ maxHeight: 50 }}
    >
      <form onSubmit={submitSearch}>
        <TextField
          type="search"
          onChange={(e): void => setTxt(e.target.value)}
          label="Search"
          color="secondary"
          variant="outlined"
          size="small"
          value={txt}
        />
      </form>
      <div className="ml-1">
        Searching for <i>{search}</i>
      </div>

      <IconButton style={{ marginLeft: 1 }} onClick={clickCancel}>
        <CancelIcon />
      </IconButton>
    </div>
  );
};

export default SearchField;

import ApplicationModel from "./ApplicationModel";
import { ClassName } from "../types/types";
import User from "./User";
import { getModels, store } from "../constants/globals";
import { PayscaleJSON } from "../types/json_types";
import sortBy from "lodash/sortBy";
import { then } from "../util/util";

export type PayscaleType =
  | "delivered"
  | "installed"
  | "uninstalled"
  | "removed"
  | "dropoff"
  | "driver"
  | "pickup"
  | "fridge_cleaning";

export type PayscaleGroup = "basic" | "brich" | "midyear";
export const payscale_groups: PayscaleGroup[] = ["basic", "brich", "midyear"];

export const payscale_types: PayscaleType[] = [
  "delivered",
  "installed",
  "uninstalled",
  "removed",
  "dropoff",
  "driver",
  "pickup",
  "fridge_cleaning",
];

export interface ShortCents {
  [short: string]: number;
}

export const orderPayscalesByType = (payscales: Payscale[]): Payscale[] => {
  return sortBy(payscales, (ps) => payscale_types.indexOf(ps.type));
};

export const midYearCutoffDateStr = "2022-09-02";

class Payscale extends ApplicationModel {
  label: string;
  type: PayscaleType;
  group: PayscaleGroup;
  short_cents: ShortCents;

  constructor(json: PayscaleJSON) {
    super(json);

    this.label = json.label;
    this.type = json.type;
    this.group = json.group;
    this.short_cents = {
      BL: json.bl_cents || 0,
      SR: json.sr_cents || 0,
      MF: json.mf_cents || 0,
      FT: json.ft_cents || 0,
      BS: json.bs_cents || 0,
      LBX: json.lbx_cents || 0,
      FFC: json.ffc_cents || 0,
      PM: json.pm_cents || 0,
      TV: json.tv_cents || 0,
      BB: json.bb_cents || 0,
      DSK: json.dsk_cents || 0,
      HB: 0,
    };
  }

  getClassName(): ClassName {
    const className: ClassName = "Payscale";
    return className;
  }

  static find(type: PayscaleType, user: User, midyear: boolean): Payscale | null {
    const is_install_action =
      type === "delivered" || type === "installed" || type === "uninstalled" || type === "removed";

    const season = store.getState("season");

    const group = then(
      is_install_action,
      (ia): PayscaleGroup => {
        if (ia) {
          if (user.id === 15) {
            return "brich";
          } else if (season === "2020_installs" && user.id === 12) {
            return "midyear";
          } else if (midyear) {
            return "midyear";
          }
        }

        return "basic";
      },
    );

    const payscale = getModels("payscales").find((ps) => ps.type === type && ps.group === group);

    if (!payscale) {
      // throw new Error(`couldnt find payscale of type ${type} and group ${group}`);
      return null;
    }

    return payscale;
  }

  getCents(short: string): number {
    return this.short_cents[short];
  }
}

export default Payscale;

import React, { useState } from "react";
import { Formik } from "formik";
import { TextField, Button, Typography, makeStyles, Theme, createStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { store, router } from "../../constants/globals";
import GoogleOauthButton from "./GoogleOauthButton";
import { ApiRequest } from "../../libs/ApiRequest";
import User from "../../models/User";
import CloseableAlert from "../util/CloseableAlert";
import CenterAll from "../util/CenterAll";
import { UserJSONResponse } from "../../types/json_types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    space: {
      marginTop: theme.spacing(2),
    },
  }),
);

const LoginForm: React.FC = () => {
  const [hasServerError, setHasErrors] = useState(false);
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values): void => {
        const request = new ApiRequest({
          url: "/users/sign_in.json",
          method: "POST",
          onSuccess: (json: unknown): void => {
            const { currentUser } = json as UserJSONResponse;

            const user = new User(currentUser);
            store.state.currentUser = user;
            router.go("/");
          },
          onError: (): void => {
            setHasErrors(true);
          },
          data: { user: values },
        });
        request.perform();
      }}
    >
      {(props): JSX.Element => {
        const { errors, values, handleSubmit, handleChange } = props;
        return (
          <div className="p-2">
            {hasServerError ? (
              <CloseableAlert
                open={hasServerError}
                closeAlert={(): void => setHasErrors(false)}
                variant="outlined"
                severity="error"
              >
                Invalid email/password combo
              </CloseableAlert>
            ) : null}

            <Typography variant="h4" className="text-align-center">
              App Login
            </Typography>

            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className={classes.space}
                  error={!!errors?.email?.length}
                  helperText={errors.email ? errors.email : null}
                />

                <TextField
                  className={classes.space}
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                />

                <Button
                  className={classes.space}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                >
                  login
                </Button>
              </Box>
            </form>

            <Box display="flex" flexDirection="column" className={classes.space}>
              <Box my="2">
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  OR
                </Typography>
              </Box>

              <CenterAll>
                <GoogleOauthButton />
              </CenterAll>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography className="mt-2" variant="h6" style={{ textAlign: "center" }}>
                OR
              </Typography>

              <Button
                onClick={(): void => {
                  router.go("/signup");
                }}
                variant="outlined"
                color="secondary"
                size="large"
                className="mt-2"
              >
                Go to sign up page
              </Button>
            </Box>
          </div>
        );
      }}
    </Formik>
  );
};

export default LoginForm;

import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TruckLoad from "../../models/TruckLoad";
import Typography from "@material-ui/core/Typography";
import sortBy from "lodash/sortBy";
import { store, router, getModels } from "../../constants/globals";
import { andJoin, getDateStr, spliceFromArray, then } from "../../util/util";
import { setTruckLoadModalState } from "../../helpers/truck_load_helpers";
import TruckLoadModal from "./TruckLoadModal";
import ChangeSeasonSelect from "../util/ChangeSeasonSelect";
import groupBy from "lodash/groupBy";
import strftime from "strftime";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const clickNewTruckLoad = (): void => {
  setTruckLoadModalState(null);
};

const clickRow = (truck_load: TruckLoad): void => {
  router.go(`/truck_loads/${truck_load.id}`);
};

const renderTruckLoadRow = (truck_load: TruckLoad): JSX.Element => {
  const time = strftime("%l:%M %p", truck_load.performed_at);

  const workers = truck_load.getWorkers();

  const workerNames = andJoin(workers.map((w) => w.username));

  const verb = truck_load.type === "pickup" || truck_load.type === "load" ? "LOADED" : "UNLOADED";

  const truckText = then(truck_load, (l) => {
    if (!l.vehicle) {
      return null;
    }

    const v = verb === "LOADED" ? "onto" : "from";

    return ` ${v} ${l.vehicle.name}`;
  });

  return (
    <div
      key={"truck_load_" + truck_load.id}
      onClick={(): void => {
        clickRow(truck_load);
      }}
      className="truck_load-row list-group-item d-flex justify-content-between align-items-center p-1"
    >
      <div>
        <span>{time}</span> ~{" "}
        <span>
          {verb} {truck_load.total()} items {truckText}
        </span>{" "}
        ~ {workerNames}
      </div>
      <ArrowForwardIcon />
    </div>
  );
};

const clickDate = (d: string, collapsed: boolean): void => {
  if (collapsed) {
    spliceFromArray(store.state.collapsed_dates, d);
  } else {
    store.state.collapsed_dates.push(d);
  }

  store.forceUpdate("collapsed_dates");
};

const renderDate = (d: string, loads: TruckLoad[]): JSX.Element => {
  const [year, month, day] = d.split("-");
  const str = `${month}/${day}/${year}`;
  const collapsed = store.state.collapsed_dates.includes(d);

  return (
    <div key={str}>
      <div
        style={{ minHeight: 50 }}
        onClick={(): void => clickDate(d, collapsed)}
        className="job-row d-flex justify-content-between align-items-center"
      >
        <div style={{ width: 24 }}></div>

        {str}

        {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>

      {collapsed ? null : loads.map(renderTruckLoadRow)}
    </div>
  );
};

function TruckLoadList(): JSX.Element {
  let truck_loads = sortBy(getModels("truck_loads"), (tl) => -tl.performed_at);

  const currentUser = store.getNonNullState("currentUser");

  if (!currentUser.admin) {
    truck_loads = truck_loads.filter(
      (d) => d.creator_id === currentUser.id || d.worker_ids.includes(currentUser.id),
    );
  }

  store.useState("collapsed_dates");
  const grouped = groupBy(truck_loads, (tl) => getDateStr(tl.performed_at));
  const dates = Object.keys(grouped).sort().reverse();

  return (
    <div>
      <ChangeSeasonSelect />
      <div className="d-flex align-items-center justify-content-between mt-2">
        <Typography variant="h6">Truck Loads ({truck_loads.length})</Typography>

        <Button onClick={clickNewTruckLoad} variant="outlined">
          Add Ticket
        </Button>
      </div>

      <div id="truck_loads" className="mt-3">
        {dates.map((d) => renderDate(d, grouped[d]))}
        {/* {truck_loads.map(renderTruckLoadRow)} */}
      </div>
      <TruckLoadModal />
    </div>
  );
}

export default TruckLoadList;

import ApplicationModel from "./ApplicationModel";
import { RoomJSON, TableName } from "../types/json_types";
import { ClassName } from "../types/types";
import Building from "./Building";
import Install, { InstallStatus } from "./Install";
import uniq from "lodash/uniq";
import { getModels } from "../constants/globals";
import { findModelById, filterModelsByAttr, findModelsByIdInclusion } from "../util/app_util";
import School from "./School";
import InstallAction, { Activity } from "./InstallAction";
import { isPresent, doesIntersect, getDayMonth } from "../util/util";
import FieldNote from "./FieldNote";
import compact from "lodash/compact";
import Ticket from "./Ticket";
import Reservation from "./Reservation";

class Room extends ApplicationModel {
  installs_count: number;
  number: string;
  wing: string;
  school_id: number;
  building_id: number;
  building: Building;
  occupied: boolean;
  unresolved_tickets_count: number;
  tickets_count: number;
  locked: boolean | null;
  reservations?: Reservation[];
  field_notes_count: number;
  installs: Install[] | null;

  constructor(json: RoomJSON, building: Building) {
    super(json);

    this.number = json.number;
    this.wing = json.wing;
    this.school_id = building.school_id;
    this.building_id = json.building_id;
    this.building = building;

    this.occupied = json.occupied;
    this.locked = json.locked;
    this.field_notes_count = json.field_notes_count;
    this.unresolved_tickets_count = json.unresolved_tickets_count;
    this.tickets_count = json.tickets_count;
    this.installs = null;
    this.installs_count = json.installs_count;
  }

  getReservations(): Reservation[] {
    if (this.reservations == null) {
      const res_ids = compact(uniq(this.installs!.map((i) => i.reservation_id)));

      this.reservations = findModelsByIdInclusion(getModels("reservations"), res_ids);
    }

    return this.reservations;
  }

  getSchool(): School {
    return findModelById(getModels("schools"), this.school_id);
  }

  getClassName(): ClassName {
    const className: ClassName = "Room";
    return className;
  }

  getTickets(): Ticket[] {
    return filterModelsByAttr(getModels("tickets"), "room_id", this.id);
  }

  getFieldNotes(): FieldNote[] {
    return filterModelsByAttr(getModels("field_notes"), "room_id", this.id);
  }

  hasInstallStatuses(statuses: InstallStatus[]): boolean {
    if (!this.installs) {
      return false;
    }

    return !!this.installs.find((i) => statuses.includes(i.status));
  }

  wingName(): string {
    if (this.wing?.trim()) {
      return `#${this.number}, ${this.wing}`;
    } else {
      return "#" + this.number;
    }
  }

  getInstallActions(): InstallAction[] {
    if (!this.installs) {
      return [];
    }
    return this.installs.flatMap((i) => i.getInstallActions());
  }

  hasPaymentPending(): boolean {
    return !!this.getReservations().find((r) => r.has_unpaid_invoice);
  }

  hasSpecialInstructions(): boolean {
    return !!this.getReservations().find((s) => isPresent(s.special_instructions));
  }

  hasArrivalDate(): boolean {
    return !!this.getReservations().find((s) => !!s.arrival_date_at);
  }

  getActivity(): Activity[] {
    return compact(
      [
        this.getInstallActions().map((ia) => ia.toActivity()),
        // this.getRoomLockings().map((rl) => rl.toActivity()),
        // this.getRoomOccupations().map((ro) => ro.toActivity()),
      ].flat(),
    );
  }

  hasAvailableJobs(): boolean {
    if (!this.installs) {
      return false;
    }

    const hasAvailableInstall = !!this.installs.find((i) => i.isAvailable());
    const hasUnresolvedTicket = this.unresolved_tickets_count > 0;

    return hasAvailableInstall || hasUnresolvedTicket;
  }

  showName(sep = "-"): string {
    return `${this.wingName()} ${sep} ${this.building.shown_name}`;
  }

  roomStr(): string {
    return `${this.building.shown_name}, ${this.number} ${this.wing}`.trim();
  }

  searchStudent(search: string): boolean {
    const regexp = new RegExp(search.replace(/\s/g, ""), "i");
    return !!this.getReservations().find((r) => regexp.test(r.fullName().replace(/\s/g, "")));
  }

  getArrivalDateStrings(): string | null {
    const str = compact(
      this.getReservations().map((r) =>
        r.arrival_date_at ? getDayMonth(r.arrival_date_at) : null,
      ),
    ).join(" & ");

    if (str) {
      return ` (${str})`;
    } else {
      return null;
    }
  }

  hasProducts(product_ids: number[], install_statuses?: InstallStatus[] | null): boolean {
    if (!this.installs) {
      return false;
    }

    if (install_statuses?.length) {
      return !!this.installs.find(
        (i) => product_ids.includes(i.product.id) && install_statuses.includes(i.status),
      );
    } else {
      return !!this.installs.find((i) => product_ids.includes(i.product.id));
    }
  }

  hasProductsWithNoUpgrades(
    product_ids: number[],
    install_statuses?: InstallStatus[] | null,
  ): boolean {
    if (!this.installs) {
      return false;
    }

    if (install_statuses?.length) {
      return !!this.installs.find(
        (i) =>
          i.upgrade_ids.length === 0 &&
          product_ids.includes(i.product.id) &&
          install_statuses.includes(i.status),
      );
    } else {
      return !!this.installs.find(
        (i) => i.upgrade_ids.length === 0 && product_ids.includes(i.product.id),
      );
    }
  }

  hasNoUpgrades(): boolean {
    if (!this.installs) {
      return false;
    }

    return !!this.installs.find((i) => i.upgrade_ids.length === 0);
  }

  hasUpgrades(upgrades_ids: number[]): boolean {
    if (!this.installs) {
      return false;
    }

    return !!this.installs.find((i) => doesIntersect(upgrades_ids, i.upgrade_ids));
  }

  hasStatuses(statuses: InstallStatus[]): boolean {
    if (!this.installs) {
      return false;
    }

    return !!this.installs.find((i) => statuses.includes(i.status));
  }

  hasProductsAndStatuses(product_ids: number[], statuses: InstallStatus[]): boolean {
    if (!this.installs) {
      return false;
    }

    return !!this.installs.find(
      (i) => product_ids.includes(i.product.id) && statuses.includes(i.status),
    );
  }

  arrivingBefore(date: Date | null): boolean {
    if (!date) {
      return false;
    }

    return !!this.getReservations().find((r) => r.arrivingBefore(date));
  }

  leavingBefore(d: Date): boolean {
    return this.getReservations().find((r) => r.leavingBefore(d)) != null;
  }

  static getTableName(): TableName {
    return "rooms";
  }
}

export default Room;

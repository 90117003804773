import ApplicationModel from "./ApplicationModel";
import { SchoolJSON, TableName } from "../types/json_types";
import { ClassName } from "../types/types";
import Install from "./Install";
import { filterModelsByAttr } from "../util/app_util";
import { getModels } from "../constants/globals";

class School extends ApplicationModel {
  name: string;
  short: string;

  constructor(json: SchoolJSON) {
    super(json);

    this.name = json.name;
    this.short = json.short;
  }

  getInstalls(): Install[] {
    return filterModelsByAttr(getModels("installs"), "school", this);
  }

  isMethodist(): boolean {
    return this.short === "methodist";
  }

  isRichmond(): boolean {
    return this.short === "richmond";
  }

  isWm(): boolean {
    return this.short === "wm";
  }

  getClassName(): ClassName {
    const className: ClassName = "School";
    return className;
  }

  static getTableName(): TableName {
    return "schools";
  }
}

export default School;

import { getAllStats } from "../../helpers/stats_helpers";
import StatsTable from "./StatsTable";
import { store } from "../../constants/globals";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import FilteredStats from "./FilteredStats";
import { install_status_colors } from "../../constants/colors";
import ChangeSeasonSelect from "../util/ChangeSeasonSelect";

const { useNonNullState, setPartialState } = store.getScopedHelpers("stats");

const Stats = (): JSX.Element => {
  const state = useNonNullState();
  const stats = getAllStats();

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={state.tab}
          onChange={(_, tab): void => {
            setPartialState({ tab });
          }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="All stats" />
          <Tab label="Filtered stats" />
        </Tabs>
      </AppBar>
      <ChangeSeasonSelect />
      <div className="d-flex flex-wrap">
        <span style={{ color: install_status_colors.pending_delivery }} className="ml-1">
          pending delivery
        </span>
        <span style={{ color: install_status_colors.delivered }} className="ml-1">
          delivered
        </span>
        <span style={{ color: install_status_colors.installed }} className="ml-1">
          installed
        </span>
        <span style={{ color: install_status_colors.pending_removal }} className="ml-1">
          pending removal
        </span>
        <span style={{ color: install_status_colors.uninstalled }} className="ml-1">
          uninstalled
        </span>
        <span style={{ color: install_status_colors.removed }} className="ml-1">
          removed
        </span>
        <span style={{ color: install_status_colors.missing }} className="ml-1">
          missing
        </span>
      </div>
      {state.tab === 0 ? (
        stats.stats.map((s) => StatsTable(s.prods, s.school.name, true))
      ) : (
        <FilteredStats />
      )}
    </div>
  );
};

export default Stats;

import Typography from "@material-ui/core/Typography";
import BonusBuck from "../../models/BonusBuck";
import Box from "@material-ui/core/Box";
import User from "../../models/User";
import { andJoin, isPresent, centsToDollarString, getDateStr } from "../../util/util";
import { getModels } from "../../constants/globals";
import sortBy from "lodash/sortBy";
import ChangeSeasonSelect from "../util/ChangeSeasonSelect";
import { renderPhotoIcon, renderConfirmed, clickBBRow } from "../bonus_bucks/BonusBucks";
import groupBy from "lodash/groupBy";

const renderBB = (bb: BonusBuck): JSX.Element => {
  const users = andJoin(bb.getWorkers().map((u) => u.username));
  const reason = isPresent(bb.reason) ? bb.reason : "reasons";
  const each_cents = bb.individualDollars() * 100;

  return (
    <Box
      key={`bb_${bb.id}`}
      className="job-row pl-1"
      style={{ minHeight: 50 }}
      display="flex"
      alignItems="center"
      onClick={(): void => clickBBRow(bb)}
    >
      {renderPhotoIcon(bb.image_file_name)}
      {renderConfirmed(bb.confirmed)}
      {users} received ${bb.dollars} for {reason} ({centsToDollarString(each_cents)} each)
    </Box>
  );
};

const renderBBsByDate = (d: string, loads: BonusBuck[]): JSX.Element => {
  const [year, month, day] = d.split("-");
  const str = `${month}/${day}/${year}`;

  return (
    <div key={str}>
      <div
        className="job-row d-flex justify-content-center align-items-center"
        style={{ minHeight: 50 }}
      >
        {str}
      </div>

      {loads.map(renderBB)}
    </div>
  );
};

interface UserWorkBonusesProps {
  user: User;
}

const UserWorkBonuses = (props: UserWorkBonusesProps): JSX.Element => {
  const { user } = props;
  let bonus_bucks = getModels("bonus_bucks").filter((bb) => bb.worker_ids.includes(user.id));

  bonus_bucks = sortBy(bonus_bucks, (bb) => -bb.performed_at);

  const grouped = groupBy(bonus_bucks, (tl) => getDateStr(tl.performed_at));
  const dates = Object.keys(grouped).sort().reverse();

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <Typography variant="h6" gutterBottom>
          Your Bonus Bucks ({bonus_bucks.length})
        </Typography>
        <ChangeSeasonSelect />
      </div>

      {dates.map((d) => renderBBsByDate(d, grouped[d]))}
    </div>
  );
};

export default UserWorkBonuses;

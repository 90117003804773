import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { AlertProps } from '@material-ui/lab/Alert/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

interface CloseableAlertProps extends AlertProps {
  open: boolean;
  closeAlert: () => void;
  className?: string;
}

const CloseableAlert = (props: CloseableAlertProps): JSX.Element => {
  return (
    <Collapse in={props.open}>
      <Alert
        className={props.className}
        severity={props.severity}
        variant={props.variant}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={props.closeAlert}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {props.children}
      </Alert>
    </Collapse>
  );
};

export default CloseableAlert;

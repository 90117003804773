import React from "react";
import UserWorkTabs from "./UserWorkTabs";
import EarningsPage from "./EarningsPage";
import User from "../../models/User";
import UserWorkBonuses from "./UserWorkBonuses";
import { store } from "../../constants/globals";
import { getTotalCentsForUser } from "../../helpers/user_helpers";
import Received from "./Received";
import { friendlySeason } from "../../util/app_util";
import BonusBuckModal from "../bonus_bucks/BonusBuckModal";

const { useNonNullState } = store.getScopedHelpers("user_work_page");

const getView = (
  user: User,
  tab: number,
  date: Date | null,
  earned_tab: number,
): JSX.Element | null => {
  switch (tab) {
    case 0:
      return <EarningsPage user={user} earned_tab={earned_tab} date={date} />;
    case 1:
      return <UserWorkBonuses user={user} />;
    case 2:
      const { totals } = getTotalCentsForUser(user);
      return <Received user={user} total={totals.total} />;
    default:
      return null;
  }
};

const UserWorkPage = (): JSX.Element | null => {
  const { user, tab, date, earned_tab } = useNonNullState();

  const viewShown = store.useNonNullState("viewShown");

  React.useEffect(() => {
    let title: string;
    if (tab === 1) {
      title = `${user.username}'s Bonuses (${friendlySeason()})`;
    } else if (tab === 0) {
      title = `${user.username}'s Work (${friendlySeason()})`;
    } else {
      title = `${user.username}'s Paychecks (${friendlySeason()})`;
    }

    if (viewShown === "UserWork") {
      store.setState("title", title);
    }
  }, [tab, user.username, viewShown]);

  return (
    <div>
      <BonusBuckModal />
      <UserWorkTabs tab={tab} user={user} />
      {getView(user, tab, date, earned_tab)}
    </div>
  );
};

export default UserWorkPage;

import { ClassName } from "../types/types";
import Product from "./Product";
import { ModelJSON } from "../types/json_types";
import Building from "./Building";
import Room from "./Room";

const dummy = new Date();
abstract class ApplicationModel {
  id: number;
  created_at: Date;

  constructor(attrs: ModelJSON) {
    this.id = attrs.id;
    this.created_at = attrs.created_at
      ? new Date(attrs.created_at + "Z")
      : dummy;
  }

  isProduct(): this is Product {
    return this.getClassName() === "Product";
  }

  isBuilding(): this is Building {
    return this.getClassName() === "Building";
  }

  isRoom(): this is Room {
    return this.getClassName() === "Room";
  }

  abstract getClassName(): ClassName;
}

export default ApplicationModel;

import React from "react";
import strftime from "strftime";
import { store, router, getModels } from "../../constants/globals";
import Ticket from "../../models/Ticket";
import Building from "../../models/Building";
import { filterModelsByAttr, friendlySeason, intersperseBuildings } from "../../util/app_util";
import Button from "@material-ui/core/Button";
import NewTicketModal from "./NewTicketModal";
import ChangeSeasonSelect from "../util/ChangeSeasonSelect";

const clickRow = (ticket: Ticket): void => {
  router.go(`/tickets/${ticket.id}/room`);
};

const style = { minHeight: 60 };
const classes = "list-group-item d-flex align-items-center pl-1";
const renderTicket = (ticket: Ticket): JSX.Element => {
  const key = `ticket-${ticket.id}`;
  const room = ticket.getRoom();

  const created_at_str = strftime("%b %e %l:%M %p", ticket.created_at);

  return (
    <div key={key} style={style} className={classes} onClick={(): void => clickRow(ticket)}>
      <div>
        <span className="fw-500">{room.wingName()}:</span>
        {ticket.text}
        <span> -- created {created_at_str}</span>
      </div>
    </div>
  );
};

const renderBuilding = (b: Building): JSX.Element => {
  const key = `na-building-${b.id}`;

  return (
    <div
      key={key}
      className="building list-group-item d-flex align-items-center p-2 bold uppercase justify-content-center"
      style={style}
    >
      <span className="fw-500">
        {b.shown_name} - {b.school.name}
      </span>
    </div>
  );
};

const addTicket = (): void => {
  const school_id = window.App.filter?.school_id || 1;
  store.setState("new_ticket_modal", { working: false, text: "", school_id });
};

const TicketsList = (): JSX.Element => {
  let tickets = filterModelsByAttr(getModels("tickets"), "resolved_by_id", null);

  const filter = window.App.filter;

  const school_id = filter?.school_id;
  const building_ids = filter?.building_ids;

  if (school_id) {
    if (building_ids?.length) {
      tickets = tickets.filter(
        (t) => building_ids.includes(t.building_id) && t.getSchool().id === school_id,
      );
    } else {
      tickets = tickets.filter((t) => t.getSchool().id === school_id);
    }
  }

  const length = tickets.length;

  React.useEffect(() => {
    const title = `Needs Attention Tickets (${length}) (${friendlySeason()})`;

    const state = store.state;

    if (school_id) {
      let subtitle = `filtering by school`;

      if (building_ids?.length) {
        subtitle = `filtering by school & buildings`;
      }
      state.title = title;
      state.subtitle = subtitle;
    } else {
      state.title = title;
    }

    store.forceUpdate("title");
  }, [length, school_id, building_ids]);

  const tickets_w_buildings = intersperseBuildings(tickets, getModels("buildings"));

  return (
    <div className="fit">
      <NewTicketModal />

      <ChangeSeasonSelect />
      <div className="d-flex justify-content-between">
        <Button variant="outlined" onClick={addTicket} className="mt-2">
          New Ticket
        </Button>
      </div>

      {tickets_w_buildings.map((item: Ticket | Building) =>
        item.isBuilding() ? renderBuilding(item) : renderTicket(item),
      )}
    </div>
  );
};

export default TicketsList;

import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { isErrorResponse } from "../../types/json_types";
import { ShortCents } from "../../models/Payscale";
import { PayscaleModalState } from "../../types/state_types";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { store, getModels } from "../../constants/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { findModelById } from "../../util/app_util";
import { centsToDollarString } from "../../util/util";
import { short_shorts } from "../../constants/shorts";
import CloseableErrorAlert from "../util/CloseableErrorAlert";

const { getNonNullState, setState, useStoreState, setPartialState } = store.getScopedHelpers(
  "payscale_modal",
);

function getModalStyle(): CSSProperties {
  return {
    width: "100%",
    top: "0",
    left: "0",
    bottom: "0",
    outline: "0",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

const clickUpdate = (state: PayscaleModalState): void => {
  if (state.working) {
    return;
  }
  const data = { ...state, errors: [] };
  setPartialState({ working: true });
  ApiRequest.perform({
    url: `/worker_api/payscales/${state.id}.json`,
    method: "PATCH",
    onSuccess: (resp: unknown): void => {
      const json = resp as ShortCents;

      const allPayscales = getModels("payscales");
      const payscale = findModelById(allPayscales, state.id);

      Object.assign(payscale.short_cents, json);

      setState(undefined);

      store.forceUpdate("viewShown");
    },
    onError: (json: unknown): void => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors });
      } else {
        alert("some funky error occurred");
      }

      setPartialState({ working: false });
    },
    data,
  });
};

const handleClose = (): void => {
  setState(undefined);
};

const clearErrors = (): void => {
  setPartialState({ errors: [] });
};

const handleChangeCount = (short: string, num: number): void => {
  num = num < 0 ? 0 : num;

  getNonNullState().short_cents[short] = num;

  store.forceUpdate("payscale_modal");
};

const renderShort = (short: string): JSX.Element => {
  const num = getNonNullState().short_cents[short];

  return (
    <div key={`td_ps_${short}`} className="d-flex mt-3 align-items-center">
      <TextField
        id="standard-number"
        label={short + " Cents"}
        type="number"
        style={{ maxWidth: 100 }}
        variant="outlined"
        size="small"
        value={num || ""}
        onChange={(e): void => handleChangeCount(short, parseInt(e.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <div className="ml-2">{centsToDollarString(num || 0)}</div>
    </div>
  );
};

const PayscaleModal = (): JSX.Element | null => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();

  if (!state) {
    return null;
  }

  const title = `Update ${state.label} payscale`;

  return (
    <Modal open={true} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div>
          <Typography
            variant="h6"
            className="d-flex align-center"
            style={{ justifyContent: "center" }}
          >
            {title}
          </Typography>

          <CloseableErrorAlert errors={state.errors} closeAlert={clearErrors} />
        </div>

        <Divider className="mb-2" />

        <Box flexGrow="1" style={{ overflow: "scroll" }} className="pl-1">
          <div>{short_shorts.map(renderShort)}</div>
        </Box>

        <Divider className="mt-1" />

        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={state.working}>
            Cancel
          </Button>

          <Button
            onClick={(): void => clickUpdate(state)}
            variant="outlined"
            disabled={state.working}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PayscaleModal;

import React from "react";
import Box from "@material-ui/core/Box";
import Room from "../../models/Room";
import {
  getLockedIcon,
  getOccupiedIcon,
  getSpecialInstructionsIcon,
  getTicketIcon,
  getHasPaymentPendingIcon,
} from "../../helpers/icon_helpers";
import { router } from "../../constants/globals";
import { renderShort } from "../../helpers/install_view_helpers";

const clickRoom = (room_id: number): void => {
  router.go(`/jobs/${room_id}`);
};

// const getVacancyDates = (room: Room): JSX.Element | null => {
//   const reservations = room.getReservations();

//   if (reservations == null) {
//     return null;
//   }

//   return (
//     <span className="d-flex align-items-center mx-1" style={{ fontSize: 11 }}>
//       {reservations
//         .map((r) => {
//           const dd = r.departure_date;
//           return `${dd.getMonth() + 1}/${dd.getDate()}`;
//         })
//         .join(" & ")}
//     </span>
//   );
// };

const RoomRow = (index: number, style: React.CSSProperties, room: Room): JSX.Element => {
  const installs = room.installs;
  return (
    <Box
      onClick={(): void => clickRoom(room.id)}
      className="job-row pl-2"
      style={style}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
    >
      {room.wingName()}
      {getLockedIcon(room)}
      {getTicketIcon(room)}
      {getSpecialInstructionsIcon(room)}
      {getOccupiedIcon(room)}
      {getHasPaymentPendingIcon(room)}
      {room.getArrivalDateStrings()}: {installs ? installs.map(renderShort) : null}
    </Box>
  );
};

export default RoomRow;

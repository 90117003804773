import ApplicationModel from "./ApplicationModel";
import { TableName, WarehouseJSON } from "../types/json_types";
import { ClassName } from "../types/types";

class Warehouse extends ApplicationModel {
  name: string;

  constructor(json: WarehouseJSON) {
    super(json);

    this.name = json.name;
  }

  getClassName(): ClassName {
    const className: ClassName = "Warehouse";
    return className;
  }

  static getTableName(): TableName {
    return "warehouses";
  }
}

export default Warehouse;

import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { parse4StateBoolStr } from "../../util/util";

type LockedFilterSelectProps = {
  onChange: (locked: boolean | null | undefined) => void;
  locked: boolean | null | undefined;
};

const LockedFilterSelect = (props: LockedFilterSelectProps): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const locked = parse4StateBoolStr(event.target.value as string);

    props.onChange(locked);
  };
  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="filter-locked-select-simple">
        Locked Status
      </InputLabel>
      <Select
        native
        value={`${props.locked}`}
        onChange={handleChange}
        label="Locked Status"
        inputProps={{
          id: "filter-locked-select-simple",
        }}
      >
        <option value="undefined">Locked Status</option>
        <option value="null">Unknown</option>
        <option value="false">Unlocked</option>
        <option value="true">Locked</option>
      </Select>
    </FormControl>
  );
};

export default LockedFilterSelect;

import { UpgradeJSON } from "../types/json_types";
import ApplicationModel from "./ApplicationModel";
import { ClassName } from "../types/types";
import Product from "./Product";
import { getModels } from "../constants/globals";
import { findModelById } from "../util/app_util";

class Upgrade extends ApplicationModel {
  product_id: number;
  product?: Product;
  short: string;
  name: string;

  constructor(json: UpgradeJSON) {
    super(json);

    this.name = json.name;
    this.product_id = json.product_id;
    this.short = json.short;
  }

  key(): string {
    return `Upgrade_${this.id}`;
  }

  labelName(): string {
    return this.name
      .replace(/\(.*?\)/g, "")
      .replace(/\s\s+/, " ")
      .trim();
  }

  formLabel(): string {
    if (this.getProduct().short_short === "TV") {
      return `${this.short.split("_")[1]} ${this.name}`;
    } else {
      return this.name;
    }
  }

  getProduct(): Product {
    if (this.product) {
      return this.product;
    } else {
      this.product = findModelById(getModels("products"), this.product_id);
      return this.product;
    }
  }

  getClassName(): ClassName {
    return "Upgrade";
  }
}

export default Upgrade;

import PayscalesTable from "./PayscalesTable";
import MoneyTable from "./MoneyTable";
import TotalsTable from "./TotalsTable";
import { EarningsPageProps } from "./EarningsPage";
import { Divider } from "@material-ui/core";
import UserStatsTable from "./StatsTable";
import { getTotalCentsForUser } from "../../helpers/user_helpers";
import ChangeSeasonSelect from "../util/ChangeSeasonSelect";
import { store } from "../../constants/globals";

const AllTimeEarnings = (props: EarningsPageProps): JSX.Element => {
  const { user } = props;

  const { totals, stats, midyearStats, money, midyearMoney } = getTotalCentsForUser(user);

  const midyear = store.state.midyear_user_ids.includes(user.id);

  return (
    <div>
      <ChangeSeasonSelect />
      <div className="mt-2">{PayscalesTable({ user, midyear: false })}</div>
      {midyear ? <div className="mt-2">{PayscalesTable({ user, midyear: true })}</div> : null}
      <div className="mt-2">Seasonal earnings:</div>
      <TotalsTable {...totals} />
      {midyear ? (
        <div>
          <div>stats: </div>
          <MoneyTable money={money} />
          <Divider />
          <UserStatsTable stats={stats} />
          <div>midyear stats: </div>
          <MoneyTable money={midyearMoney} />
          <Divider />
          <UserStatsTable stats={midyearStats} />
        </div>
      ) : (
        <>
          <MoneyTable money={money} />
          <Divider />
          <UserStatsTable stats={stats} />
        </>
      )}
    </div>
  );
};

export default AllTimeEarnings;

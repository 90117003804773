import Payscale from "../../models/Payscale";
import User from "../../models/User";
import { centsToDollarString } from "../../util/util";
import { money_shorts } from "../../constants/shorts";
import { install_status_colors } from "../../constants/colors";
import compact from "lodash/compact";

let key1 = 1;

const renderPayscalesTd = (short: string, payscales: Payscale[]): JSX.Element | null => {
  if (payscales.length === 0) {
    return null;
  }

  const key = "payscales_" + key1++;
  let cents = 0;
  for (let index = 0; index < payscales.length; index++) {
    const payscale = payscales[index]!;
    cents += payscale.short_cents[short];
  }

  const str = centsToDollarString(cents);

  return <td key={key}>{str}</td>;
};

const renderPayscaleTd = (short: string, payscale: Payscale | null): JSX.Element | null => {
  if (!payscale) {
    return null;
  }

  const key = "payscale_" + key1++;
  const str = centsToDollarString(payscale.short_cents[short]);

  return <td key={key}>{str}</td>;
};

type PayscalesTableProps = {
  user: User;
  midyear: boolean;
};

function PayscalesTable({ midyear, user }: PayscalesTableProps): JSX.Element {
  const delivered_payscale = Payscale.find("delivered", user, midyear);
  const installed_payscale = Payscale.find("installed", user, midyear);
  const uninstalled_payscale = Payscale.find("uninstalled", user, midyear);
  const removed_payscale = Payscale.find("removed", user, midyear);

  const title = midyear ? "midyear payscale: " : "payscale: ";

  return (
    <div>
      <div>
        <span className="ml-2">
          <b>{title}</b>
        </span>
        <span className="ml-2">total</span>
        <span className="ml-2 " style={{ color: install_status_colors.delivered }}>
          delivery
        </span>
        <span className="ml-2 " style={{ color: install_status_colors.installed }}>
          installed
        </span>
        <span className="ml-2 " style={{ color: install_status_colors.uninstalled }}>
          uninstall
        </span>
        <span className="ml-2" style={{ color: install_status_colors.removed }}>
          removal
        </span>
      </div>
      <table className="muhtable">
        <thead>
          <tr>
            {money_shorts.map((s) => (
              <th key={key1++}>{s}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="text_delivered" style={{ color: "white" }}>
            {money_shorts.map((s) => {
              return renderPayscalesTd(
                s,
                compact([
                  delivered_payscale,
                  installed_payscale,
                  uninstalled_payscale,
                  removed_payscale,
                ]),
              );
            })}
          </tr>
          <tr className="text_delivered" style={{ color: install_status_colors.delivered }}>
            {money_shorts.map((s) => {
              return renderPayscaleTd(s, delivered_payscale);
            })}
          </tr>
          <tr className="text_installed" style={{ color: install_status_colors.installed }}>
            {money_shorts.map((s) => {
              return renderPayscaleTd(s, installed_payscale);
            })}
          </tr>
          <tr className="text_uninstalled" style={{ color: install_status_colors.uninstalled }}>
            {money_shorts.map((s) => {
              return renderPayscaleTd(s, uninstalled_payscale);
            })}
          </tr>
          <tr className="text_removed" style={{ color: install_status_colors.removed }}>
            {money_shorts.map((s) => {
              return renderPayscaleTd(s, removed_payscale);
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PayscalesTable;

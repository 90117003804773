import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Upgrade from "../../models/Upgrade";
import sortBy from "lodash/sortBy";
import { shorts } from "../../constants/shorts";
import { getModels } from "../../constants/globals";

const renderUpgradeOption = (upgrade: Upgrade): JSX.Element => {
  const key = `upgrade_option_${upgrade.id}`;

  return (
    <option key={key} value={upgrade.id}>
      {upgrade.labelName()} ({upgrade.short})
    </option>
  );
};

export interface UpgradeIdsSelectProps {
  upgrade_ids: number[] | null;
  onChange: (upgrade_ids: number[]) => void;
  label: string;
}

const sortUpgradesByShort = (upgrades: Upgrade[]): Upgrade[] => {
  return sortBy(upgrades, (p) => {
    return shorts.indexOf(p.short);
  });
};

const handleChangeMultiple = (
  event: React.ChangeEvent<{ value: unknown }>,
  onChange: (upgrade_ids: number[]) => void
): void => {
  const { options } = event.target as HTMLSelectElement;
  const upgrade_ids: number[] = [];
  for (let i = 0, l = options.length; i < l; i += 1) {
    if (options[i].selected) {
      upgrade_ids.push(parseInt(options[i].value));
    }
  }

  onChange(upgrade_ids);
};

export default function UpgradeIdsSelect(
  props: UpgradeIdsSelectProps
): JSX.Element {
  const { upgrade_ids, label, onChange } = props;

  const upgrades = sortUpgradesByShort(getModels("upgrades"));

  return (
    <div>
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel shrink htmlFor="select-multiple-native">
          {label}
        </InputLabel>
        <Select
          multiple
          native
          value={upgrade_ids}
          onChange={(e): void => handleChangeMultiple(e, onChange)}
        >
          {upgrades.map(renderUpgradeOption)}
        </Select>
      </FormControl>
    </div>
  );
}

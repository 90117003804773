import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import sortBy from "lodash/sortBy";
import Building from "../../models/Building";
import { filterModelsByAttr } from "../../util/app_util";
import { getModels } from "../../constants/globals";

export interface BuildingsSelectProps {
  building_ids: number[] | null;
  onChange: (building_ids: number[]) => void;
  school_id: number;
  label: string;
}

const handleChangeMultiple = (
  event: React.ChangeEvent<{ value: unknown }>,
  handleChange: (building_ids: number[]) => void
): void => {
  const { options } = event.target as HTMLSelectElement;
  const building_ids: number[] = [];
  for (let i = 0, l = options.length; i < l; i += 1) {
    if (options[i].selected) {
      building_ids.push(parseInt(options[i].value));
    }
  }

  handleChange(building_ids);
};

export default function BuildingsSelect(
  props: BuildingsSelectProps
): JSX.Element {
  let schoolBuildings = filterModelsByAttr(
    getModels("buildings"),
    "school_id",
    props.school_id
  );
  schoolBuildings = sortBy(schoolBuildings, (b) => b.shown_name);

  return (
    <div className="multi-user-select">
      <FormControl>
        <InputLabel shrink htmlFor="select-buildings">
          {props.label}
        </InputLabel>
        <Select
          id="select-buildings"
          multiple
          native
          value={props.building_ids}
          onChange={(e): void => handleChangeMultiple(e, props.onChange)}
        >
          {schoolBuildings.map(
            (building: Building): JSX.Element => (
              <option
                key={`buildings-filter-${building.id}`}
                value={building.id}
              >
                {building.shown_name}
              </option>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
}

import React from "react";
import { ShortShortMoneyNumbers } from "../../helpers/stats_helpers";
import { money_shorts } from "../../constants/shorts";
import { install_status_colors } from "../../constants/colors";
import { myRound } from "../../util/util";

let key1 = 1;

const renderTd = (count: number): JSX.Element => {
  const key = "brah" + key1++;

  return <td key={key}>{myRound(count)}</td>;
};

interface MoneyTableProps {
  stats: ShortShortMoneyNumbers;
}

function UserStatsTable(props: MoneyTableProps): JSX.Element {
  const { stats } = props;

  return (
    <React.Fragment>
      <table className="muhtable">
        <thead>
          <tr>
            <th></th>
            {money_shorts.map((s) => (
              <th key={key1++}>{s}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            {money_shorts.map((s) => {
              return renderTd(stats[s].total);
            })}
          </tr>

          <tr style={{ color: install_status_colors.delivered }}>
            <td></td>
            {money_shorts.map((s) => {
              return renderTd(stats[s].delivered);
            })}
          </tr>

          <tr style={{ color: install_status_colors.installed }}>
            <td></td>
            {money_shorts.map((s) => {
              return renderTd(stats[s].installed);
            })}
          </tr>
          <tr style={{ color: install_status_colors.uninstalled }}>
            <td></td>
            {money_shorts.map((s) => {
              return renderTd(stats[s].uninstalled);
            })}
          </tr>
          <tr style={{ color: install_status_colors.removed }}>
            <td></td>
            {money_shorts.map((s) => {
              return renderTd(stats[s].removed);
            })}
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default UserStatsTable;

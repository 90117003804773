import React from "react";
import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import { ApiRequest } from "../../libs/ApiRequest";
import { store, getModels } from "../../constants/globals";
import { findModelById } from "../../util/app_util";
import { spliceFromArray, andJoin } from "../../util/util";
import { isErrorResponse } from "../../types/json_types";

const { setState, setPartialState } = store.getScopedHelpers("field_note_modal");

const destroyFieldNote = (field_note_id: number, working: boolean): void => {
  if (working) {
    return;
  }

  if (window.confirm("are you sure?")) {
    setPartialState({ working: true });
    ApiRequest.perform({
      url: `/worker_api/field_notes/${field_note_id}.json`,
      method: "DELETE",
      onSuccess: (): void => {
        const allFieldNotes = getModels("field_notes");
        const field_note = findModelById(allFieldNotes, field_note_id);
        spliceFromArray(allFieldNotes, field_note);

        store.forceUpdate("viewShown");
        setState(undefined);
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert("Error:" + andJoin(json.errors));
        } else {
          alert("some funky error occurred");
        }

        setPartialState({ working: false });
      },
    });
  }
};

interface FieldNoteDestroyButtonProps {
  working: boolean;
  field_note_id: number;
}

const FieldNoteDestroyButton = (props: FieldNoteDestroyButtonProps): JSX.Element => {
  return (
    <Button
      style={{ color: red[900], borderColor: red[900] }}
      onClick={(): void => destroyFieldNote(props.field_note_id, props.working)}
      variant="outlined"
      disabled={props.working}
    >
      Destroy
    </Button>
  );
};

export default FieldNoteDestroyButton;

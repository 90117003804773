import ApplicationModel from "./ApplicationModel";
import { TableName, BonusBuckJSON } from "../types/json_types";
import { ClassName } from "../types/types";
import { BonusBuckModalState } from "../types/state_types";
import User from "./User";
import { getModels } from "../constants/globals";
import { findModelById } from "../util/app_util";
import { then } from "../util/util";

class BonusBuck extends ApplicationModel {
  worker_ids: number[];
  creator_id: number;
  confirmed: boolean;
  creator: User;
  dollars: number;
  image_file_name: string | null;
  reason: string | null;
  performed_at: Date;

  constructor(json: BonusBuckJSON) {
    super(json);
    this.worker_ids = json.worker_ids;
    this.creator_id = json.creator_id;
    this.dollars = json.dollars;
    this.performed_at = new Date(json.performed_at + "Z");
    this.image_file_name = json.image_file_name;
    this.confirmed = json.confirmed;
    this.reason = json.reason;
    const allUsers = getModels("users");
    this.creator = findModelById(allUsers, this.creator_id);
  }

  getWorkers(): User[] {
    const allUsers = getModels("users");
    return this.worker_ids.map((wid) => findModelById(allUsers, wid));
  }

  getClassName(): ClassName {
    const className: ClassName = "BonusBuck";
    return className;
  }

  individualDollars(): number {
    return this.dollars / this.worker_ids.length;
  }

  static getTableName(): TableName {
    return "bonus_bucks";
  }

  static getModalState(
    bonus_buck: BonusBuck | null,
    currentUser: User | null,
  ): BonusBuckModalState {
    const id = bonus_buck?.id || null;
    const dollars = bonus_buck?.dollars || 0;
    const image_file_name = bonus_buck?.image_file_name || null;
    const worker_ids: number[] =
      bonus_buck?.worker_ids || then(currentUser, (u) => (u == null || u.admin ? [] : [u.id]));
    const reason = bonus_buck?.reason || null;
    const working = false;
    const errors: string[] = [];
    const performed_at = bonus_buck?.performed_at || new Date();
    const confirmed = bonus_buck?.confirmed || currentUser?.admin ? true : false;

    return {
      id,
      reason,
      confirmed,
      image_file_name,
      dollars,
      worker_ids,
      performed_at,
      working,
      errors,
    };
  }
}

export default BonusBuck;

import React from "react";
import Ticket from "../../models/Ticket";
import Typography from "@material-ui/core/Typography";
import strftime from "strftime";
import Button from "@material-ui/core/Button";
import { setTicketModalState, setTicketResolutionModalState } from "../../helpers/ticket_helpers";
import UnresolveTicketButton from "./UnresolveTicketButton";
import { store } from "../../constants/globals";

const clickEditTicket = (ticket: Ticket): void => {
  setTicketModalState(ticket);
};

const clickResolveTicket = (ticket: Ticket): void => {
  setTicketResolutionModalState(ticket);
};

const renderResolution = (ticket: Ticket): JSX.Element | null => {
  if (ticket.resolved_by) {
    if (!ticket.resolved_at) {
      throw new Error("resolved_at should be present here");
    }

    const resolved_at_str = strftime("%b %e %l:%M %p", ticket.resolved_at);

    const notes = ticket.resolution_notes?.trim() ? `"${ticket.resolution_notes}"` : null;

    return (
      <div className="d-flex">
        {notes ? <div className="mr-2">{notes}</div> : null}
        <div>
          resolved by {ticket.resolved_by.username} @ {resolved_at_str}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const renderTicket = (ticket: Ticket): JSX.Element => {
  const key = `ticket-list-${ticket.id}`;
  const created_at_str = strftime("%b %e %l:%M %p", ticket.created_at);
  const currentUser = store.getNonNullState("currentUser");
  const txt = `"${ticket.text}"`;

  return (
    <div key={key} className="d-flex justify-content-between align-items-center my-2 mb-4">
      <div className="mr-1 d-flex flex-column" style={{ fontSize: 12 }}>
        <div className="d-flex">
          <div
            className="mr-2"
            style={{ fontSize: "13px", color: ticket.resolved_by_id ? "initial" : "#ff5722" }}
          >
            {txt}
          </div>
          <div>
            created by {ticket.creator.username} @ {created_at_str}
          </div>
        </div>
        {renderResolution(ticket)}
      </div>

      <div className="d-flex flex-column">
        {(ticket.creator_id === currentUser.id || currentUser.isAdmin()) && (
          <Button onClick={(): void => clickEditTicket(ticket)} size="small" variant="outlined">
            edit
          </Button>
        )}

        {!ticket.resolved_by && (
          <Button
            variant="outlined"
            size="small"
            className="mt-2"
            onClick={(): void => clickResolveTicket(ticket)}
          >
            Resolve
          </Button>
        )}

        {ticket.resolved_by && (currentUser.isAdmin() || ticket.resolved_by === currentUser) && (
          <UnresolveTicketButton ticket={ticket} />
        )}
      </div>
    </div>
  );
};

interface TicketsSectionProps {
  tickets: Ticket[];
}

const TicketsSection = (props: TicketsSectionProps): JSX.Element | null => {
  const { tickets } = props;

  if (tickets.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6">Needs Attention Tickets ({tickets.length})</Typography>
        <div>{tickets.map(renderTicket)}</div>
      </div>
    );
  } else {
    return null;
  }
};

export default TicketsSection;

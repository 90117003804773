import User from "../models/User";
import {
  getStatsForUser,
  getMoneyForStats,
  computeRoomTotal,
  getTruckLoadTotalForUser,
  computeBonusBuckTotal,
  ShortShortMoneyNumbers,
  ShortShortMoneyStats,
} from "./stats_helpers";

export const workerName = (user: User): string => {
  return `${user.username} - ${user.last_name}, ${user.first_name}`;
};

type CentsTotals = {
  total: number;
  truck_load_total: number;
  room_total: number;
  bonus_buck_total: number;
};

type Totals = {
  totals: CentsTotals;
  stats: ShortShortMoneyNumbers;
  midyearStats: ShortShortMoneyNumbers;
  money: ShortShortMoneyStats;
  midyearMoney: ShortShortMoneyStats;
};

export const getTotalCentsForUser = (user: User): Totals => {
  const stats = getStatsForUser(user, false);
  const midyearStats = getStatsForUser(user, true);

  const money = getMoneyForStats(stats, user, false);
  const midyearMoney = getMoneyForStats(midyearStats, user, true);

  const room_total = computeRoomTotal(money) + computeRoomTotal(midyearMoney);

  const truck_load_total = getTruckLoadTotalForUser(user);

  const bonus_buck_total = computeBonusBuckTotal(user);

  const total = room_total + truck_load_total + bonus_buck_total * 100;

  const totals = {
    total,
    truck_load_total,
    room_total,
    bonus_buck_total,
  };

  return { totals, stats, midyearStats, money, midyearMoney };
};

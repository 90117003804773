import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import sortBy from "lodash/sortBy";
import { getActiveUsers } from "../../util/app_util";

export interface MultiUserSelectProps {
  user_ids: number[];
  onChange: (user_ids: number[]) => void;
  label: string;
}

export default function MultiUserIdSelect(
  props: MultiUserSelectProps
): JSX.Element {
  const handleChangeMultiple = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const { options } = event.target as HTMLSelectElement;
    const user_ids: number[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        user_ids.push(parseInt(options[i].value));
      }
    }

    props.onChange(user_ids);
  };

  const users = sortBy(getActiveUsers(), (u) => u.username);

  return (
    <div className="multi-user-select">
      <FormControl>
        <InputLabel shrink htmlFor="select-multiple-native">
          {props.label}
        </InputLabel>
        <Select
          multiple
          native
          value={props.user_ids}
          onChange={handleChangeMultiple}
        >
          {users.map((user) => (
            <option key={`somebs-${user.id}`} value={user.id}>
              {user.selectName()}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

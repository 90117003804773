import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { store } from "../../constants/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import CloseableErrorAlert from "../util/CloseableErrorAlert";
import { isErrorResponse, CurrentUserJSONResponse } from "../../types/json_types";
import User from "../../models/User";
import { BasicInfoFormState } from "../../types/state_types";

const { getNonNullState, setPartialState, useNonNullState } = store.getScopedHelpers(
  "basic_info_form",
);

const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
  event.preventDefault();

  const state = getNonNullState();

  if (state.working) {
    return;
  }

  setPartialState({ working: true });

  const data = { user_id: state.user_id, profile: state };

  ApiRequest.perform({
    url: `/worker_api/basic_profile_update.json`,
    data,
    method: "PATCH",
    onSuccess: (resp: unknown) => {
      const json = resp as CurrentUserJSONResponse;
      if (!json.currentUser) {
        throw new Error("current user should be here");
      }

      const heldUser = new User(json.currentUser);

      Object.assign(store.getNonNullState("currentUser"), heldUser);

      store.forceUpdate("viewShown");
    },
    onError: (json: any) => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors });
      } else {
        alert("an error occurred");
      }
    },
    onDone: () => {
      setPartialState({ working: false });
    },
  });
};

const onChange = <Key extends keyof BasicInfoFormState>(
  attr: Key,
  value: BasicInfoFormState[Key],
): void => {
  setPartialState({ [attr]: value });
};

const onCloseErrorAlert = (): void => {
  setPartialState({ errors: [] });
};

const BasicInfoForm = (): JSX.Element => {
  const state = useNonNullState();

  return (
    <div className="p-2">
      <CloseableErrorAlert errors={state.errors} closeAlert={onCloseErrorAlert} />
      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            value={state.email}
            onChange={(e): void => onChange("email", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            label="Email*"
            variant="outlined"
            disabled
            className="mt-2"
          />

          <TextField
            value={state.first_name || ""}
            onChange={(e): void => onChange("first_name", e.target.value)}
            label="First name*"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.last_name || ""}
            onChange={(e): void => onChange("last_name", e.target.value)}
            label="Last name*"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.username || ""}
            onChange={(e): void => onChange("username", e.target.value)}
            label="Username*"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.phone || ""}
            onChange={(e): void => onChange("phone", e.target.value)}
            label="Phone number"
            variant="outlined"
            className="mt-2"
          />

          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={state.agreed_to_terms}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  onChange("agreed_to_terms", e.target.checked)
                }
                name="checkedB"
                color="primary"
              />
            }
            label="I agree to terms linked below"
          />

          <div>
            <Link
              href="http://dormsdirect.com/ica.html"
              target="_blank"
              className="mb-3 mt-1 d-flex align-items-center"
            >
              <div>INDEPENDENT CONTRACTOR AGREEMENT</div>
              <OpenInNewIcon className="ml-1" />
            </Link>
          </div>

          <Button
            disabled={state.working}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className="mt-2"
            disableElevation
          >
            Save
          </Button>

          <div>* required</div>
        </Box>
      </form>
    </div>
  );
};

export default BasicInfoForm;

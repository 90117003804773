import TruckLoad from "../../models/TruckLoad";
import Button from "@material-ui/core/Button";
import { isErrorResponse } from "../../types/json_types";
import strftime from "strftime";
import { store, router, getModels } from "../../constants/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { setTruckLoadModalState } from "../../helpers/truck_load_helpers";
import { spliceFromArray, andJoin, then } from "../../util/util";
import Building from "../../models/Building";
import User from "../../models/User";
import TruckLoadModal from "./TruckLoadModal";
import Payscale from "../../models/Payscale";
import TruckLoadCountsTable from "./TruckLoadCountsTable";

const { useNonNullState, setPartialState } = store.getScopedHelpers("truck_load_detail");

const clickEdit = (truck_load: TruckLoad): void => {
  setTruckLoadModalState(truck_load);
};

const clickDestroy = (truck_load: TruckLoad): void => {
  if (window.confirm("are you sure?")) {
    setPartialState({ working: true });
    ApiRequest.perform({
      url: `/worker_api/truck_loads/${truck_load.id}.json`,
      method: "DELETE",
      onSuccess: (): void => {
        const allTruckLoads = getModels("truck_loads");
        spliceFromArray(allTruckLoads, truck_load);

        router.go("/truck_loads");
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(andJoin(json.errors));
        } else {
          alert("some funky error occurred");
        }
        setPartialState({ working: false });
      },
    });
  }
};

const TruckLoadDetail = (): JSX.Element => {
  const { truck_load, working } = useNonNullState();

  if (!truck_load) {
    throw new Error("no truck_load dudetown");
  }

  const workers = truck_load.getWorkers();

  const buildings = truck_load.getBuildings();

  const currentUser = store.getNonNullState("currentUser");

  const date = strftime("%-m/%-d/%y ~ %l:%M %p", truck_load.performed_at);

  const type = then(truck_load.type, (t) =>
    t === "unload" ? "dropoff" : t === "load" ? "pickup" : t,
  );

  const payscale = Payscale.find(type, currentUser, false);

  return (
    <div className="p-1 mt-2 mainfit">
      {(currentUser.admin || truck_load.creator_id === currentUser.id) && (
        <div className="my-2 d-flex justify-content-between align-items-center">
          <Button onClick={(): void => clickEdit(truck_load)} size="small" variant="outlined">
            Edit
          </Button>
          <Button
            onClick={(): void => {
              if (!working) {
                clickDestroy(truck_load);
              }
            }}
            size="small"
            variant="outlined"
            disabled={working}
          >
            Destroy
          </Button>
        </div>
      )}

      <div>
        <span className="fw-500 mr-2">Type:</span>
        {truck_load.type}
      </div>

      <div>
        <span className="fw-500 mr-2">Date:</span>
        {date}
      </div>

      <div>
        <span className="fw-500 mr-2">Creator:</span>
        {truck_load.creator.username}
      </div>

      <div>
        <span className="fw-500 mr-2">Driver:</span>
        {truck_load.driver ? truck_load.driver.username : "none"}
      </div>

      <div>
        <span className="fw-500 mr-2">Vehicle:</span>
        {truck_load.vehicle ? truck_load.vehicle.name : "none"}
      </div>

      <div>
        <span className="fw-500 mr-2">Warehouse:</span>
        {truck_load.warehouse ? truck_load.warehouse.name : "none"}
      </div>

      <div>
        <span className="fw-500 mr-2">School:</span>
        {truck_load.school.name}
      </div>

      {buildings.length > 0 && (
        <div>
          <span className="fw-500 me-2">Buildings:</span>
          {buildings.map((b: Building): string => b.shown_name).join(", ")}
        </div>
      )}

      {workers.length > 0 && (
        <div>
          <span className="fw-500 mr-2">Workers:</span>
          {workers.map((w: User): string => w.username).join(", ")}
        </div>
      )}

      <TruckLoadCountsTable truck_load={truck_load} payscale={payscale} />

      {truck_load.notes?.trim() ? (
        <div className="mt-3">
          <span className="fw-500 mr-2">Notes:</span>
          {truck_load.notes}
        </div>
      ) : null}

      <TruckLoadModal />
    </div>
  );
};

export default TruckLoadDetail;

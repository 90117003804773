import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { TruckLoadRespJSON, isErrorResponse } from "../../types/json_types";
import Box from "@material-ui/core/Box";
import BuildingsSelect from "../selects/BuildingsSelect";
import SchoolSelectMeh from "../job_list/SchoolSelectMeh";
import Divider from "@material-ui/core/Divider";
import toUpper from "lodash/toUpper";
import strftime from "strftime";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import UserIdSelectNull from "../selects/UserIdSelectNull";
import AppDatePicker from "../selects/AppDatePicker";
import WarehouseIdSelectNull from "../selects/WarehouseIdSelectNull";
import { TruckLoadModalState } from "../../types/state_types";
import GenericStringSelect from "../selects/GenericStringSelect";
import MultiUserIdSelect from "../selects/MultiUserIdSelect";
import { store, getModels } from "../../constants/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { findModelById } from "../../util/app_util";
import TruckLoad, { TruckLoadType } from "../../models/TruckLoad";
import CloseableErrorAlert from "../util/CloseableErrorAlert";
import { Count, counts } from "../../constants/shorts";
import { andJoin } from "../../util/util";
import VehicleIdSelectNull from "../selects/VehicleIdSelectNull";

const {
  setState,
  getNonNullState,
  setPartialState,
  useStoreState: useModalState,
} = store.getScopedHelpers("truck_load_modal");

function getModalStyle(): CSSProperties {
  return {
    width: "100%",
    top: "0",
    left: "0",
    bottom: "0",
    outline: "0",
    border: "none",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

const clickUpdate = (state: TruckLoadModalState, school_id: number | null): void => {
  if (!school_id) {
    throw new Error("no school_id");
  }

  if (state.working) {
    return;
  }

  setPartialState({ working: true });
  const data = { ...state, errors: [], school_id };

  ApiRequest.perform({
    url: `/worker_api/truck_loads/${state.id}.json`,
    method: "PATCH",
    data: {
      driver_id: data.driver_id,
      warehouse_id: data.warehouse_id,
      vehicle_id: data.vehicle_id,
      truck_load: data,
    },
    onSuccess: (resp: unknown) => {
      const json = resp as TruckLoadRespJSON;

      const allTruckLoads = getModels("truck_loads");
      const truck_load = findModelById(allTruckLoads, json.truck_load.id);

      const nextTruckLoad = new TruckLoad(json.truck_load);

      Object.assign(truck_load, nextTruckLoad);

      const appState = store.state;
      appState.truck_load_modal = undefined;

      store.forceUpdate("viewShown");
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors });
      } else {
        alert("some funky error occurred");
      }
      setPartialState({ working: false });
    },
  });
};

function clickSubmit(state: TruckLoadModalState, school_id: number | null): void {
  if (!school_id) {
    throw new Error("no school_id");
  }

  if (state.working) {
    return;
  }

  const data = { ...state, errors: [], school_id };

  setPartialState({ working: true });
  ApiRequest.perform({
    url: "/worker_api/truck_loads.json",
    method: "POST",
    data: {
      driver_id: data.driver_id,
      warehouse_id: data.warehouse_id,
      vehicle_id: data.vehicle_id,
      truck_load: data,
    },
    onSuccess: (resp: unknown) => {
      const json = resp as TruckLoadRespJSON;
      const truck_load = new TruckLoad(json.truck_load);
      getModels("truck_loads").unshift(truck_load);
      const appState = store.state;

      appState.truck_load_modal = undefined;

      store.forceUpdate("viewShown");
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors });
      } else {
        alert("some funky error occurred");
      }
      setPartialState({ working: false });
    },
  });
}

const handleClose = (): void => {
  setState(undefined);
};

const onChange = <Key extends keyof TruckLoadModalState>(
  attr: Key,
  value: TruckLoadModalState[Key],
): void => {
  setPartialState({ [attr]: value });
};

const clearErrors = (): void => {
  setPartialState({ errors: [] });
};

const handleChangeCount = (count: Count, num: number): void => {
  num = num < 0 ? 0 : num;
  setPartialState({ [count]: num });
};

const renderCount = (count: Count): JSX.Element => {
  const val = getNonNullState()[count];

  const short = toUpper(count.split("_")[0]);

  return (
    <div key={`td_${count}`} className="d-flex mt-3">
      <TextField
        id="standard-number"
        label={short}
        type="number"
        style={{ maxWidth: 100 }}
        variant="outlined"
        size="small"
        value={val || ""}
        onChange={(e): void => handleChangeCount(count, parseInt(e.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
};

const TruckLoadModal = (): JSX.Element | null => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useModalState();

  if (!state) {
    return null;
  }
  const title = state.id ? "Update load ticket" : "Create load ticket";

  let school_id: number | null = null;

  if (state.id) {
    const tl = findModelById(getModels("truck_loads"), state.id);
    school_id = tl.school_id;
  } else {
    if (window.App.filter?.school_id) {
      school_id = window.App.filter.school_id;
    }
  }

  if (!school_id) {
    return (
      <Modal open={true} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography variant="h6" className="d-flex" style={{ justifyContent: "center" }}>
            Select a school
          </Typography>
          <SchoolSelectMeh />;
        </div>
      </Modal>
    );
  }

  const allBuildings = getModels("buildings");
  const buildingNames = state.building_ids.map(
    (id: number): string => findModelById(allBuildings, id).shown_name,
  );

  const allUsers = getModels("users");
  const userNames = state.worker_ids.map(
    (id: number): string => findModelById(allUsers, id).username,
  );

  return (
    <Modal open={true} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div>
          <Typography variant="h6" className="d-flex" style={{ justifyContent: "center" }}>
            {title}
          </Typography>

          <CloseableErrorAlert errors={state.errors} closeAlert={clearErrors} />
        </div>

        <Divider />

        <Box flexGrow="1" style={{ overflow: "scroll" }} className="pl-1">
          <div className="mt-2">
            <GenericStringSelect<TruckLoadType>
              label="Type"
              options={["unload", "load"]}
              value={state.type}
              onChange={(type: TruckLoadType): void => setPartialState({ type })}
            />
          </div>

          <Box display="flex" justifyContent="between" alignItems="center">
            <BuildingsSelect
              label={"select buildings (optional)"}
              school_id={school_id}
              building_ids={state.building_ids}
              onChange={(bids): void => onChange("building_ids", bids)}
            />

            {state.building_ids.length > 0 && (
              <div style={{ maxWidth: "50%" }}>{andJoin(buildingNames)}</div>
            )}
          </Box>

          <div className="my-3">
            <UserIdSelectNull
              label="Who's driving?"
              nullLabel="no driver"
              user_id={state.driver_id}
              onChange={(user_id: number | null): void => setPartialState({ driver_id: user_id })}
            />
          </div>

          <div className="mt-4">
            <div>performed on: {strftime("%b %e %Y", state.performed_at)}</div>
            <AppDatePicker
              label="change date"
              date={state.performed_at}
              onChangeDate={(d: Date | null): void =>
                setPartialState({ performed_at: d || new Date() })
              }
            />
          </div>

          <div className="my-3">
            <WarehouseIdSelectNull
              label="Select a warehouse (optional)"
              nullLabel="no warehouse"
              warehouse_id={state.warehouse_id}
              onChange={(warehouse_id: number | null): void =>
                setPartialState({ warehouse_id: warehouse_id })
              }
            />
          </div>

          <div className="my-3">
            <VehicleIdSelectNull
              label="Select a vehicle (optional)"
              nullLabel="no vehicle"
              vehicle_id={state.vehicle_id}
              onChange={(vehicle_id: number | null): void => setPartialState({ vehicle_id })}
            />
          </div>

          <div className="my-3 d-flex align-items-center">
            <MultiUserIdSelect
              user_ids={state.worker_ids}
              label="which workers participated?"
              onChange={(user_ids: number[]): void => setPartialState({ worker_ids: user_ids })}
            />

            <div style={{ maxWidth: "50%" }}>{andJoin(userNames)}</div>
          </div>

          <div>{counts.map(renderCount)}</div>

          <TextField
            style={{ width: "90%" }}
            className="my-3"
            label="notes"
            multiline
            rows="4"
            value={state.notes}
            onChange={(e): void => onChange("notes", e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Divider className="mt-1" />

        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={state.working}>
            Cancel
          </Button>

          {state.id ? (
            <Button
              onClick={(): void => clickUpdate(state, school_id)}
              variant="outlined"
              disabled={state.working}
            >
              Update
            </Button>
          ) : (
            <Button
              onClick={(): void => clickSubmit(state, school_id)}
              variant="outlined"
              disabled={state.working}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TruckLoadModal;

import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import sortBy from "lodash/sortBy";
import Warehouse from "../../models/Warehouse";
import { getModels } from "../../constants/globals";

export const renderWarehouse = (w: Warehouse, label: string): JSX.Element => {
  const key = `warehouse-${w.id}-option-${label}`;

  return (
    <option key={key} value={w.id}>
      {w.name}
    </option>
  );
};

export interface WarehouseIdSelectNullProps {
  warehouse_id: number | null;
  onChange: (warehouse_id: number | null) => void;
  nullLabel: string;
  label: string;
}

export default function WarehouseIdSelectNull(
  props: WarehouseIdSelectNullProps
): JSX.Element {
  const { warehouse_id, onChange, nullLabel, label } = props;

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const val = e.target.value as string;
    const warehouse_id = val === "null" ? null : parseInt(val);

    onChange(warehouse_id);
  };

  const warehouses = sortBy(getModels("warehouses"), (w) => w.name);

  const val = warehouse_id || "";

  return (
    <div>
      <FormControl style={{ minWidth: 235 }}>
        <InputLabel>{label}</InputLabel>
        <Select
          native
          value={val}
          onChange={handleChange}
          inputProps={{
            name: "warehouse_id",
          }}
        >
          <option>{nullLabel}</option>

          {warehouses.map((w) => renderWarehouse(w, label))}
        </Select>
      </FormControl>
    </div>
  );
}

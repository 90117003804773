import ApplicationModel from "./ApplicationModel";
import { TableName, InstallJSON } from "../types/json_types";
import { ClassName } from "../types/types";
import Room from "./Room";
import { getModels } from "../constants/globals";
import { findModelById } from "../util/app_util";
import Upgrade from "./Upgrade";
import Product from "./Product";
import InstallAction from "./InstallAction";
import compact from "lodash/compact";
import School from "./School";

export type InstallStatus =
  | "pending_delivery"
  | "delivered"
  | "installed"
  | "pending_removal"
  | "uninstalled"
  | "missing"
  | "removed";

export const install_statuses: InstallStatus[] = [
  "pending_delivery",
  "delivered",
  "installed",
  "pending_removal",
  "uninstalled",
  "missing",
  "removed",
];

export const available_statuses: InstallStatus[] = [
  "pending_delivery",
  "delivered",
  "pending_removal",
  "uninstalled",
];

class Install extends ApplicationModel {
  product_id: number;
  product: Product;
  room_id: number;
  reservation_id: number | null;
  invoice_id: number | null;
  room: Room;
  student_id: number;
  quantity: number;
  needs_removal: boolean;
  dd3: boolean;
  dd4: boolean;
  line_item_id: number;
  status: InstallStatus;
  upgrade_ids: number[];
  delivered_action: InstallAction | null;
  installed_action: InstallAction | null;
  uninstalled_action: InstallAction | null;
  removed_action: InstallAction | null;
  set_needs_removal_action: InstallAction | null;
  set_missing_action: InstallAction | null;
  building_id: number;
  school_id: number;
  school: School;

  constructor(json: InstallJSON, product: Product, room: Room, install_actions: InstallAction[]) {
    super(json);

    this.dd3 = json.dd3;
    this.dd4 = json.dd4;

    this.room = room;
    this.school_id = room.school_id;
    this.building_id = room.building_id;
    this.school = room.getSchool();
    this.needs_removal = json.needs_removal;
    this.product = product;
    this.product_id = json.product_id;
    this.quantity = json.quantity;
    this.reservation_id = json.reservation_id;
    this.invoice_id = json.invoice_id;
    this.room_id = json.room_id;
    this.student_id = json.student_id;
    this.line_item_id = json.line_item_id;
    this.status = json.status;
    this.upgrade_ids = json.upgrade_ids;

    this.set_missing_action = install_actions.find((ia) => ia.type === "set_missing") || null;

    this.delivered_action = install_actions.find((ia) => ia.type === "delivered") || null;
    this.installed_action = install_actions.find((ia) => ia.type === "installed") || null;
    this.uninstalled_action = install_actions.find((ia) => ia.type === "uninstalled") || null;
    this.removed_action = install_actions.find((ia) => ia.type === "removed") || null;
    this.set_needs_removal_action =
      install_actions.find((ia) => ia.type === "set_needs_removal") || null;
  }

  getShort(): string {
    const prod = findModelById(getModels("products"), this.product_id);
    const upgrades = this.getUpgrades();

    const up_shorts = upgrades.map((u) => u.short.split("_")[0]).sort();

    let str = [prod.short, ...up_shorts].join("_");

    if (this.quantity > 1) {
      str = `${str} x${this.quantity}`;
    }

    return str;
  }

  getUpgrades(): Upgrade[] {
    return this.upgrade_ids.map((uid) => findModelById(getModels("upgrades"), uid));
  }

  isAvailable(): boolean {
    return available_statuses.includes(this.status);
  }

  getInstallActions(): InstallAction[] {
    return compact([
      this.delivered_action,
      this.installed_action,
      this.uninstalled_action,
      this.removed_action,
      this.set_needs_removal_action,
      this.set_missing_action,
    ]);
  }

  getClassName(): ClassName {
    const className: ClassName = "Install";
    return className;
  }

  static getTableName(): TableName {
    return "installs";
  }
}

export default Install;

import React from "react";
import Home from "./Home";
import { store } from "../constants/globals";
import AppBar from "./app_bar/AppBar";
import Box from "@material-ui/core/Box";
import { getBasicInfoFormState } from "../helpers/auth_helpers";
import LoginForm from "./auth/LoginForm";
import BadRoute from "./BadRoute";
import { View } from "../types/types";
import Drawer from "./Drawer";
import JobsView from "./job_list/JobsView";
import JobDetail from "./job_detail/JobDetail";
import FilterModal from "./filter/FilterModal";
import SignupForm from "./auth/SignupForm";
import { Typography } from "@material-ui/core";
import BasicInfoForm from "./auth/BasicInfoForm";
import TruckLoadList from "./truck/TruckLoadList";
import TruckLoadDetail from "./truck/TruckLoadDetail";
import { CheatSheet } from "./CheatSheet";
import BonusBucks from "./bonus_bucks/BonusBucks";
import PayscaleList from "./payscales/PayscaleList";
import PayscaleDetail from "./payscales/PayscaleDetail";
import UserWorkPage from "./user_work/UserWork";
import WorkersPage from "./workers/WorkersPage";
import BuildingDetail from "./BuildingDetail";
import TicketsList from "./tickets/TicketsList";
import FullProfileForm from "./profile/FullProfileForm";
import Stats from "./stats/Stats";
import { fetchData } from "../helpers/fetchData";

const getView = (view: View): JSX.Element => {
  switch (view) {
    case "Home":
      return <Home />;
    case "Jobs":
      return (
        <div>
          <JobsView viewShown={view} />
        </div>
      );
    case "JobDetail":
      return (
        <div>
          <JobsView viewShown={view} />
          <JobDetail />
        </div>
      );
    case "WorkerJobDetail":
      return <JobDetail />;
    case "UserWork":
      return <UserWorkPage />;
    case "BuildingDetail":
      return (
        <div>
          <JobsView viewShown={view} />
          <BuildingDetail />
        </div>
      );
    case "Login":
      return <LoginForm />;
    case "Profile":
      return <FullProfileForm />;
    case "Signup":
      return <SignupForm />;
    case "Workers":
      return <WorkersPage />;
    case "BasicInfoForm":
      return <BasicInfoForm />;
    case "TruckLoads":
      return <TruckLoadList />;
    case "TruckLoadDetail":
      return <TruckLoadDetail />;
    case "CheatSheet":
      return <CheatSheet />;
    case "BonusBucks":
      return <BonusBucks />;
    case "PayscaleList":
      return <PayscaleList />;
    case "PayscaleDetail":
      return <PayscaleDetail />;
    case "Stats":
      return <Stats />;
    case "TicketsList":
      return <TicketsList />;
    case "BadRoute":
      return <BadRoute />;
    default:
      return <div>dunno</div>;
  }
};

const Main = (): JSX.Element => {
  const viewShown = store.useState("viewShown");
  const currentUser = store.useState("currentUser");

  if (viewShown == null) {
    fetchData();
    return <div className="App"></div>;
  }

  if (currentUser == null) {
    return (
      <div className="App d-flex flex-column" style={{ height: "100vh" }}>
        <AppBar />
        <div id="container" className="flex-grow-1" style={{ overflow: "scroll" }}>
          <Box>{getView(viewShown)}</Box>
        </div>
        <Drawer />
      </div>
    );
  } else {
    if (currentUser.canPassGo()) {
      return (
        <div className="App d-flex flex-column" style={{ height: "100vh" }}>
          <AppBar />
          <div id="container" className="flex-grow-1" style={{ overflow: "scroll" }}>
            <Box>{getView(viewShown)}</Box>
          </div>
          <Drawer />
          <FilterModal />
        </div>
      );
    } else {
      store.state.basic_info_form = getBasicInfoFormState(currentUser);

      return (
        <div className="App">
          <Typography variant="h4">Fill-in Your Profile</Typography>
          <BasicInfoForm />
        </div>
      );
    }
  }
};

export default Main;

import React from "react";
import Button from "@material-ui/core/Button";
import { isErrorResponse } from "../../types/json_types";
import { store, getModels } from "../../constants/globals";
import { ApiRequest } from "../../libs/ApiRequest";
import { findModelById } from "../../util/app_util";
import { spliceFromArray } from "../../util/util";
import { colors } from "../../constants/colors";

const { setState, setPartialState } = store.getScopedHelpers("paycheck_modal");

const destroyPaycheck = (paycheck_id: number, working: boolean): void => {
  if (working) {
    return;
  }

  const request = new ApiRequest({
    url: `/worker_api/paychecks/${paycheck_id}.json`,
    method: "DELETE",
    onSuccess: (): void => {
      const allPaychecks = getModels("paychecks");
      const paycheck = findModelById(allPaychecks, paycheck_id);
      spliceFromArray(allPaychecks, paycheck);

      store.forceUpdate("user_work_page");
      setState(undefined);
    },
    onError: (json: unknown): void => {
      if (isErrorResponse(json)) {
        setPartialState({ errors: json.errors });
      } else {
        alert("some funky error occurred");
      }
    },
  });
  request.perform();
};

interface PaycheckModalDestroyButtonProps {
  working: boolean;
  paycheck_id: number;
}

const PaycheckModalDestroyButton = (props: PaycheckModalDestroyButtonProps): JSX.Element => {
  return (
    <Button
      style={{ color: colors.danger, borderColor: colors.danger }}
      onClick={(): void => destroyPaycheck(props.paycheck_id, props.working)}
      variant="outlined"
      size="large"
      disabled={props.working}
    >
      Destroy
    </Button>
  );
};

export default PaycheckModalDestroyButton;

import ApplicationModel from "./ApplicationModel";
import { TicketJSON, TableName } from "../types/json_types";
import { ClassName } from "../types/types";
import User from "./User";
import Room from "./Room";
import School from "./School";
import { getModels } from "../constants/globals";
import { findModelById } from "../util/app_util";

class Ticket extends ApplicationModel {
  text: string;
  creator_id: number;
  creator: User;
  room_id: number;
  room: Room;
  building_id: number;
  resolved_by_id: number | null;
  resolved_by: User | null;
  resolution_notes: string | null;
  resolved_at: Date | null;

  constructor(json: TicketJSON) {
    super(json);

    const allUsers = getModels("users");

    this.text = json.text;
    this.creator_id = json.creator_id;
    this.creator = findModelById(allUsers, this.creator_id);
    this.room_id = json.room_id;
    this.room = findModelById(getModels("rooms"), json.room_id);
    this.building_id = this.room.building_id;
    this.resolved_by_id = json.resolved_by_id;
    this.resolved_by = this.resolved_by_id
      ? findModelById(allUsers, this.resolved_by_id)
      : null;
    this.resolution_notes = json.resolution_notes;
    this.resolved_at = json.resolved_at
      ? new Date(json.resolved_at + "Z")
      : null;
  }

  getRoom(): Room {
    return findModelById(getModels("rooms"), this.room_id);
  }

  getSchool(): School {
    return findModelById(getModels("buildings"), this.building_id).school;
  }

  getClassName(): ClassName {
    const className: ClassName = "Ticket";
    return className;
  }

  static getTableName(): TableName {
    return "tickets";
  }
}

export default Ticket;

import React from "react";
import Room from "../../models/Room";
import ReactDOMServer from "react-dom/server";
import { VariableSizeList as List, ListChildComponentProps } from "react-window";
import { clearListCache, onScroll } from "../../helpers/job_helpers";
import { store } from "../../constants/globals";
import Building from "../../models/Building";
import { BuildingStats } from "./JobsView";
import BuildingRow, { renderBuildingRowEle } from "./BuildingRow";
import RoomRow from "./RoomRow";
import SearchField from "./SearchField";

let list: List | null = null;

window.resetList = (): void => {
  if (list) {
    list.resetAfterIndex(0);
  }
};

export const resetListAtIndex = (index: number): void => {
  if (list) {
    list.resetAfterIndex(index);
  }
};

type ListProps = {
  rooms_w_buildings: Array<Room | Building>;
  building_stats: BuildingStats;
};

const JobsList = ({ rooms_w_buildings, building_stats }: ListProps): JSX.Element => {
  const collapsed_buildings = store.useNonNullState("collapsed_buildings");

  const search = store.useState("search");
  const hasSearch = search && search.trim();

  React.useEffect(() => {
    return (): void => {
      clearListCache();
      list = null;
    };
  }, []);

  const len = rooms_w_buildings.length;

  return (
    <List
      className="List"
      onScroll={onScroll}
      ref={(l): void => {
        list = l;
      }}
      height={window.innerHeight - 65}
      itemCount={search && search.trim() ? len + 1 : len}
      itemSize={(i): number => {
        const obj = rooms_w_buildings[i];
        if (!obj) {
          return 50;
        }

        if (obj.isBuilding()) {
          const building = obj;
          const ele = renderBuildingRowEle({
            index: i,
            building,
            stats: building_stats[building.id],
          });
          const html = ReactDOMServer.renderToStaticMarkup(ele);
          const abs = document.getElementById("measurer");
          if (abs) {
            abs.innerHTML = html;
            const height = abs.clientHeight;
            return height < 50 ? 50 : height;
          } else {
            return 50;
          }
        } else {
          return collapsed_buildings.includes(obj.building) ? 0 : 50;
        }
      }}
      width="100%"
    >
      {({ index, style }: ListChildComponentProps): JSX.Element | null => {
        if (index === 0 && search && search.trim()) {
          return <SearchField search={search} />;
        } else {
          const obj = rooms_w_buildings[hasSearch ? index - 1 : index];

          if (obj.isBuilding()) {
            const building = obj;
            return (
              <BuildingRow
                index={index}
                building={building}
                style={style}
                stats={building_stats[building.id]}
              />
            );
          } else {
            return collapsed_buildings.includes(obj.building) ? null : RoomRow(index, style, obj);
          }
        }
      }}
    </List>
  );
};

export default JobsList;

import { Store } from "set-state-is-great";
import { AppState } from "./state_types";
import StateRouter from "../libs/StateRouter";
import { RouterState } from "../init_app/router";
import { AllModels } from "../constants/globals";
import Filter from "../models/Filter";

export type JobSearchType = "wing letter, then room number" | "room number, then wing letter";
export const jobSearchTypes: JobSearchType[] = [
  "wing letter, then room number",
  "room number, then wing letter",
];

export type View =
  | "Home"
  | "TicketsList"
  | "BadRoute"
  | "Workers"
  | "Login"
  | "Signup"
  | "Jobs"
  | "TruckLoads"
  | "UserWork"
  | "Profile"
  | "TruckLoadDetail"
  | "PayscaleDetail"
  | "WorkerJobDetail"
  | "BasicInfoForm"
  | "CheatSheet"
  | "BonusBucks"
  | "JobDetail"
  | "Stats"
  | "BuildingDetail"
  | "PayscaleList";

export type ClassName =
  | "Building"
  | "Vehicle"
  | "Paycheck"
  | "StripeCharge"
  | "Reservation"
  | "Ticket"
  | "Warehouse"
  | "Product"
  | "RoomLocking"
  | "School"
  | "Upgrade"
  | "User"
  | "Student"
  | "Install"
  | "TruckLoad"
  | "Payscale"
  | "FieldNote"
  | "Room"
  | "InstallAction"
  | "BonusBuck";

export interface App {
  store: Store<AppState>;
  router: StateRouter<RouterState>;
  filter: Filter | null;
}

export type MuiChangeEvent = { target: { name: string; value: string } };
export type ReactMouseEvent = React.MouseEvent<HTMLElement>;
export type ReactFormEvent = React.FormEvent<HTMLFormElement>;
export type ReactInputEvent = React.ChangeEvent<HTMLInputElement>;

declare global {
  interface Window {
    App: App;
    D: Partial<AllModels>;
    yar: boolean;
    resetList: () => void;
  }
}

import ApplicationModel from "./ApplicationModel";
import { TableName, VehicleJSON } from "../types/json_types";
import { ClassName } from "../types/types";

class Vehicle extends ApplicationModel {
  name: string;

  constructor(json: VehicleJSON) {
    super(json);

    this.name = json.name;
  }

  getClassName(): ClassName {
    const className: ClassName = "Vehicle";
    return className;
  }

  static getTableName(): TableName {
    return "vehicles";
  }
}

export default Vehicle;
